import React from 'react';
import { useLanguage } from './LanguageContext';


function LoadingPage() {
  let [language, languageTexts] = useLanguage();
  const translation = languageTexts;
  return (
  <div className='loadingPage' id='loadingDivId'>
    <div className='loadingPageInnerDiv'>
        <img src={'../assets/logo_black_' + language + '.svg'} alt='logo'></img>
        <span>{translation[150].text}...</span>
    </div>
  </div>
  );
}

export default LoadingPage;