import React from 'react';
import UsefulInfo from "../footer/usefulInfo";
import Location from "../footer/location";
import "../representatives.css"
import HeaderBigBlack from '../header/headerBigBlack';
import HeaderBlack from '../header/headerBlack';
import SpecialistList from './specialistList';
import {MOCK_SPECIALISTS} from './mockSpecialists';
import { useState } from "react";
import { useEffect } from 'react';
import { useLanguage } from '../LanguageContext';
import { Specialist } from './specialist';
import { specsRegions } from './specsRegions';
import SelectSearch from 'react-select-search';

function SetupSpecialists(props) {

  //stack overflow moment
  const [isLoaded, setIsLoaded] = useState(false);
  //const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [activeRegion, setActiveRegion] = useState("");
  const [activeSmallRegion, setActiveSmallRegion] = useState("");
  //const [uniqueLocs, setUniqueLocs] = useState([]);
    
    useEffect(() => {
      setIsLoaded(true);
      setItems(MOCK_SPECIALISTS);
    }, []);
    // useEffect(() => {
    //   setIsLoaded(true);
    //   setItems(MOCK_SPECIALISTS);
    // })

    
  
  useEffect(() => {
      if (isLoaded) {
        window.scrollTo(0, 0)
      }
  }, [isLoaded]);
  //
    // let list_test = items.map(test =>{
    //     return test.location;
    // })
    // let uniqueLocs =[...new Set(list_test)];


    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;

  //const { activeSpecialists } = props
  //const [ showFirstDropdown, setShowFirstDropdown ] = useState(false);
  //const [ showSecondDropdown, setShowSecondDropdown ] = useState(false);

  // const handleFirstDropdownChange = (isVisible) => {
  //   setShowFirstDropdown(isVisible)
  //   // console.log("idk")
  //   console.log(showFirstDropdown)
  // }
  // const handleSecondDropdownChange = (isVisible) => {
  //   setShowSecondDropdown(isVisible)
  // }

  const handleRegionChange = (value) => {
    setActiveRegion(value)
    setActiveSmallRegion("")
  }

  const handleSmallRegionChange = (value) => {
    setActiveSmallRegion(value)
  }

  return <> 
  
        <HeaderBigBlack/>
        <div className='smallRepHeader'><HeaderBlack/></div>
  
  <div className='representativesMain specsPage'>
    {/* <nav className="Nav" style={{position: "static", paddingBottom: "50px"}}>
                    
                    <p>
                        <span><a href="#contacts">{translation[71].text}</a></span>
                        <span style={{minWidth: "30px", borderBottom: "1px solid #a7a7a7", display: "inline-block", margin: "0px 1px 0px 1px", position: "relative", top: "6px"}}/>
                        <span><Link to={"/specialistai"}>{translation[75].text}</Link></span>
                    </p>
    </nav> */}
    <p className='randomText'>{translation[76].text}</p>
    <div className='repListTitle' style={{witdh: "100%"}}>
        <h1>{translation[77].text}</h1>
        <hr/>
    </div>
        <div className='specs-dropdown-divs'>
          <div className="specs-dropdown-div" key="dd1">
              {/* <Dropdown onMouseOver={() => handleFirstDropdownChange(true)} onMouseLeave = {() => handleFirstDropdownChange(false)}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => {setShowFirstDropdown(!showFirstDropdown)}}>
                  {activeRegion === "" ? translation[118].text : activeRegion.name} <i className="arrow down"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu show={showFirstDropdown}>
                      {specsRegions.map(region =>{
                          return <Dropdown.Item key={region.name + "10"} >{region.name}</Dropdown.Item>
                          // if(location === ""){
                          //     if(location === activeSpecialists) return <Dropdown.Item key={location + "10"} as={Link} to="../specialistai/kiti" className="active-link">{translation[99].text}</Dropdown.Item>
                          //         else return <Dropdown.Item key={location + "10"} as={Link} to="../specialistai/kiti">Kiti</Dropdown.Item>
                          // }
                          // if(location === activeSpecialists) return <Dropdown.Item key={location + "10"} as={Link} to={"../atstovai/" + location} className="active-link">{location}</Dropdown.Item>
                          // else return <Dropdown.Item key={location + "10"} as={Link} to={"../specialistai/" + location}>{location}</Dropdown.Item>
                      })}
                  </Dropdown.Menu>
              </Dropdown> */}
              <SelectSearch options={specsRegions} value={activeRegion} name="region" placeholder={translation[113].text} onChange={handleRegionChange} />
              <i className="arrow down"></i>
          </div>
          <div className="specs-dropdown-div" key="dd2">
              {/* {activeRegion === "" ? null : <SelectSearch options={specsRegions} value={activeRegion} name="region" placeholder={translation[113].text} onChange={handleRegionChange} />} */}
              {specsRegions.map(region => (
                region.value === activeRegion ? 
                  <>
                    <SelectSearch options={region.smallerRegions} value={activeSmallRegion} name="smallRegion" placeholder={translation[120].text} onChange={handleSmallRegionChange} /> 
                    <i className="arrow down"></i>
                  </>
                  : <></>
              ))}
              {/* <Dropdown onMouseOver={() => handleSecondDropdownChange(true)} onMouseLeave = {() => handleSecondDropdownChange(false)}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => {setShowSecondDropdown(!showSecondDropdown)}}>
                  {translation[74].text} <i className="arrow down"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu show={showSecondDropdown}>
                      {uniqueLocs.map(location =>{
                          if(location === ""){
                              if(location === activeSpecialists) return <Dropdown.Item key={location + "10"} as={Link} to="../specialistai/kiti" className="active-link">{translation[99].text}</Dropdown.Item>
                                  else return <Dropdown.Item key={location + "10"} as={Link} to="../specialistai/kiti">Kiti</Dropdown.Item>
                          }
                          if(location === activeSpecialists) return <Dropdown.Item key={location + "10"} as={Link} to={"../atstovai/" + location} className="active-link">{location}</Dropdown.Item>
                          else return <Dropdown.Item key={location + "10"} as={Link} to={"../specialistai/" + location}>{location}</Dropdown.Item>
                      })}
                  </Dropdown.Menu>
              </Dropdown> */}
          </div>
    </div>
    <SpecialistList specs={items} activeRegion={activeRegion} activeSmallRegion={activeSmallRegion}></SpecialistList>
  </div>
  <footer>
        <div className="infoFooter"><UsefulInfo/></div>
        <div className="locationFooter"><Location/></div>
    </footer>
  </>;
}

export default SetupSpecialists;