import React from 'react';
import UsefulInfo from "../footer/usefulInfo";
import Location from "../footer/location";
import "../aboutUs.css"
import HeaderBigBlack from '../header/headerBigBlack';
import HeaderBlack from '../header/headerBlack';
import { useLanguage } from '../LanguageContext';

function AboutUs() {
    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;

  return <>

    <HeaderBigBlack activeTab={"Apie mus"}/>
    <div className='smallAboutHeader'><HeaderBlack activeTab={"Apie mus"}/></div>

  <div className='mainAbout'>
        <div className='about1'>
            <div className='aboutText1'>
            {/* <nav className="Nav" style={{position: "static", paddingBottom: "50px"}}>
                        
                        <p>
                            <span><Link to={"/apiemus"}>{translation[7].text}</Link></span>
                        </p>
            </nav> */}
                <div className='excludeNav'>
                    <h1 style={{fontWeight: "600"}}>{translation[59].text}</h1>
                    <p className='biggerAboutText'>{translation[60].text}</p>
                    <hr/>
                    <div className='aboutStatistic'>
                        <img className='statisticImage' src='../assets/velevyte_orandz.svg' alt='bookmark'></img>
                        <div className='aboutStatisticTexts'>
                            <p >{translation[61].text}</p>
                            <p >{translation[62].text}</p>
                        </div>
                    </div>
                    <p className='smallerAboutText'>{translation[63].text}</p>
                </div>
            </div>
            <div className='aboutImages1'>
                <img className='biggerRandomAboutImage' src='../assets/landing_02.png' alt='ph1'></img>
                {/*<img className='smallerRandomAboutImage' src='../assets/placeholder2.jpg' alt='ph2'></img>*/}
            </div>
        </div>
        <div className='about2'>
            <div className='aboutImages2'>
                <img className='schematicsImage' src='../assets/brezinys_01.png' alt='schematics'></img>
            </div>
            <div className='aboutText2'>
                <h1 style={{fontWeight: "600"}}>{translation[64].text}</h1>
                <p className='smallerAboutText2'>{translation[65].text}</p>
            </div>
        </div>
        <div className='weAre'>
            <div className='excludeGreyLogo'>
                <h1>{translation[70].text}</h1>
                <div className='aboutPeopleList'>
                    <div className='aboutPerson'>
                        <img className='aboutPersonImage' src='../assets/man_icon.svg' alt="headshot"></img>
                        <div className='aboutPersonText'>
                            <h2 className='aboutPersonName'>Darius Brasiūnas</h2>
                            <h4 className='aboutPersonRole'>{translation[66].text}</h4>
                            <div className='aboutPersonContacts'>
                                <p>+370 683 33388</p>
                                <p>darius.brasiunas@akatilai.lt</p>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='aboutPerson'>
                        <img className='aboutPersonImage' src='../assets/man_icon.svg' alt="headshot"></img>
                        <div className='aboutPersonText'>
                            <h2 className='aboutPersonName'>Simonas Gutauskas</h2>
                            <h4 className='aboutPersonRole'>{translation[67].text}</h4>
                            <div className='aboutPersonContacts'>
                                <p>+370 630 89318</p>
                                <p>simonas.gutauskas@akatilai.lt</p>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='aboutPerson'>
                        <img className='aboutPersonImage' src='../assets/woman_icon.svg' alt="headshot"></img>
                        <div className='aboutPersonText'>
                            <h2 className='aboutPersonName'>Indrė Brasiūnienė</h2>
                            <h4 className='aboutPersonRole'>{translation[68].text}</h4>
                            <div className='aboutPersonContacts'>
                                <p>+370 615 21992</p>
                                <p>indre.brasiuniene@akatilai.lt</p>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='aboutPerson'>
                        <img className='aboutPersonImage' src='../assets/woman_icon.svg' alt="headshot"></img>
                        <div className='aboutPersonText'>
                            <h2 className='aboutPersonName'>Jolanta Arsabienė</h2>
                            <h4 className='aboutPersonRole'>{translation[69].text}</h4>
                            <div className='aboutPersonContacts'>
                                <p>+370 683 33399</p>
                                <p>jolanta.arsabiene@akatilai.lt</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img className='greyLogo' src='../assets/grey_logo.png' alt="grey_logo"></img>
        </div>
  </div>
    
 
  <footer>
        <div className="infoFooter"><UsefulInfo/></div>
        <div className="locationFooter"><Location/></div>
    </footer>
  </>;
}

export default AboutUs;