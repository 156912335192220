    import React, {useState, useEffect} from 'react';
    import '../smallHeader.css';
    import { Link } from 'react-router-dom';
    import { useLanguage } from './../LanguageContext';

    function HeaderBlack(props) {
        const {activeTab} = props
        let [language, languageTexts, languageKatilai, toggleLanguage]= useLanguage();
        const translation = languageTexts;
        const changeLanguage = (event, changeTo) => {
            event.preventDefault();
            toggleLanguage(changeTo);
        }
        const [fade, setFade] = useState(false);
        const triggerFade = () => {
            setFade(prevState => {
                
            return !prevState
            })
        };

       //console.log(activeTab);
        

        useEffect(() => {
            if(fade) document.body.classList.add("stop-scrolling");
                else document.body.classList.remove("stop-scrolling");
        }, [fade]);
        useEffect(() => {
            const htmlElement = document.querySelector("html");
            htmlElement.style.scrollBehavior = 'auto';
            window.scrollTo(0, 0);
            htmlElement.style.scrollBehavior = 'smooth';
        }, [])




    return <>
    <div className='topNav'>
        <div className='blackHeaderSmall' id="myLinks">
            <Link to='/'><img src={'../assets/logo_black_' + language + '.svg'} alt='logo' className='blackHeaderLogoImage'></img></Link>
        </div>
        <div id='small_tree_links'
            onAnimationEnd={triggerFade}
            className={fade ? 'visibleClass' : 'fadedClass'}>
            <div className={fade ? 'displayedClass' : 'noneClass'}>
                <Link to="/katilai_granulinio"  onClick={() => {
                
                triggerFade();
    }}  className={activeTab === "Granulinio kuro" ? "headerActive" : null}>{translation[2].text} {translation[0].text.toLowerCase()}</Link>
                <Link to="/katilai_kieto"  onClick={() => {
                
                triggerFade();
    }}  className={activeTab === "Kieto kuro" ? "headerActive" : null}>{translation[3].text} {translation[0].text.toLowerCase()}</Link>
                <Link to="/katilai_zemes_ukio"  onClick={() => {
                
                triggerFade();
    }}  className={activeTab === "Žemės ūkio atliekų" ? "headerActive" : null}>{translation[4].text} {translation[0].text.toLowerCase()}</Link>
                <Link to="/katilai_pramoniniai"  onClick={() => {
                
                triggerFade();
    }}  className={activeTab === "Pramoniniai" ? "headerActive" : null}>{translation[5].text} {translation[0].text.toLowerCase()}</Link> 
                <Link to='/informacija' className={activeTab === "Informacija" ? "headerActive" : null}>{translation[6].text}</Link>
                <Link to='/apiemus' className={activeTab === "Apie mus" ? "headerActive" : null}>{translation[7].text}</Link>
                <a href='#contacts' onClick={triggerFade}>{translation[8].text}</a>
                <Link to='/atstovai' className={activeTab === "Pardavimo atstovai" ? "headerActive" : null}>{translation[72].text}</Link>
                {/* <Link to='/sheets'>{translation[108].text}</Link> */}
                {/* <Link to='/sheets'><img src='../assets/klientams.svg' className='klientamsIcon filer-orange' alt='clientIcon'></img>&nbsp;&nbsp;&nbsp;{translation[108].text}</Link> */}
                <div className='small_language_header'>
                    <Link to="/" className={language === "lt" ? "active-link" : null} onClick={event => changeLanguage(event, "lt")}>Lt</Link> 
                    <Link to="/" className={language === "en" ? "active-link" : null} onClick={event => changeLanguage(event, "en")}>En</Link>
                    <Link to="/" className={language === "lv" ? "active-link" : null} onClick={event => changeLanguage(event, "lv")}>Lv</Link> 
                    <Link to="/" className={language === "ee" ? "active-link" : null} onClick={event => changeLanguage(event, "ee")}>Ee</Link>
                    <Link to="/" className={language === "pl" ? "active-link" : null} onClick={event => changeLanguage(event, "pl")}>Pl</Link>
                </div>
            </div>
        </div>
            {fade ?
            <div className='closeMenuButton'>
                <button className="CloseOrderButton" onClick={triggerFade}>
                <img style={{"height": "54px", "width": "32px"}}alt="X" src="../assets/iksiukasB.png"></img>
                </button>
            </div>
            :
            <div className='hambutton'>
                <span className='zodisMeniu' onClick={triggerFade}>{translation[101].text}</span>
                <div className="container" onClick={triggerFade}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div> 
            </div>
            }
        </div>
    </>;
    }

    export default HeaderBlack;

            