import React from "react";
import PropTypes from 'prop-types';
import { Katilas } from "./Katilas";
import KatilasCard from "./KatilasCard";
import { useLanguage } from '../LanguageContext';

function KatilasList(props){
    let { katilai, activeKatilai } = props
    //sita palikt kad paimtu teisinga daikta
    let [language, languageTexts]= useLanguage();
    let katilaiFilter = "";
    if(activeKatilai === "Granulinio kuro" ) katilaiFilter = languageTexts[2].text;
    if(activeKatilai === "Kieto kuro" ) katilaiFilter = languageTexts[3].text;
    if(activeKatilai === "Žemės ūkio atliekų" ) katilaiFilter = languageTexts[4].text;
    //visos isskyrus anglu kitaip galune raso
    if(activeKatilai === "Pramoniniai" ) katilaiFilter = languageTexts[151].text;

    let items;
    if(katilaiFilter === ''){
        items = katilai.map(katilas => (
            <KatilasCard key={katilas.id} katilas={katilas}></KatilasCard>
        ));
    }else{
        let filtered = katilai.filter((item) => {return item.type.includes(katilaiFilter)})
        items = filtered.map(katilas => (
            <KatilasCard key={katilas.id} katilas={katilas}></KatilasCard>
        ));
    }
    return <div className="cards">{items}</div>;
}

KatilasList.propTypes = {
    katilai: PropTypes.arrayOf(PropTypes.instanceOf(Katilas)).isRequired
};

export default KatilasList;