import React from 'react';
import UsefulInfo from "../footer/usefulInfo";
import Location from "../footer/location";
import "../representatives.css"
import HeaderBigBlack from '../header/headerBigBlack';
import HeaderBlack from '../header/headerBlack';
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from 'react';
import { MOCK_REPRESENTATIVES } from './mockRepresentatives';
import RepresentativeList from './representativeList';
import { useLanguage } from '../LanguageContext';
import { Representative } from './representative';
import SelectSearch from 'react-select-search';
import { specsRegions } from './specsRegions';
import "./regions.css"

function trimLink(link){
    var ind = link.indexOf(".lt/")
    var indEu = link.indexOf(".eu/")
    if(ind !== -1){
        return link.substring(0, ind+3)
    }else if(indEu !== -1){
        return link.substring(0, indEu+3)
    }else return link
}

function SellingRepresentatives(props) {

    //stack overflow moment
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [results, setResults] = useState(12);
    const [totalResults, setTotalResults] = useState(12);
    const [totalBigResults, setTotalBigResults] = useState(12);
    const [nothingSelected, setNothingSelected] = useState(true);
    
    const [activePrekCentras, setActivePrekCentras] = useState("");
    
    
    useEffect(() => {
        setIsLoaded(true);
        setItems(MOCK_REPRESENTATIVES);
    }, []);
    
    useEffect(() => {
        if (isLoaded) {
            window.scrollTo(0, 0)
        }
    }, [isLoaded]);

    const correctSmallRegions = () =>{
        let small;
        let bigRegion = specsRegions.find(region => region.value === activeRegion);
        small = bigRegion.smallerRegions;
        return small;
    }
    //
    // let list_test = items.map(test =>{
    //     return test.location;
    // })
    //let uniqueLocs =[...new Set(list_test)];

    
    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;

    const { activeReps } = props
    var dropdownDisplay = "";
    
    var typeSelected = true;
    if (activeReps === "Prekybos centras"){
        dropdownDisplay = translation[109].text
    }else if (activeReps === "Parduotuvė"){
        dropdownDisplay = translation[110].text
    }else if (activeReps === "Elektroninė parduotuvė"){
        dropdownDisplay = translation[111].text
    }else if (activeReps === "Atstovas"){
        dropdownDisplay = translation[112].text
    }else{
        typeSelected = false
    }

    // const [ showDropdown, setShowDropdown ] = useState(false);

    // const handleDropdownChange = (isVisible) => {
        
    //     setShowDropdown(isVisible)
    // }

    // const handleRegionChange = (value) => {
    //     setRegion(value)
    //     setResults(12)
    // }

    const handleRegionChange = (value) => {
        setTotalBigResults(12);
        setTotalResults(12);
        setResults(12);
        setActiveSmallRegion("")
        setNothingSelected(false);
        setActiveRegion(value)
    }

    const handleSmallRegionChange = (value) => {
        setTotalBigResults(12);
        setTotalResults(12);
        setResults(12);
        setActiveSmallRegion(value)
      }

    const [activeRegion, setActiveRegion] = useState("");
    const [activeSmallRegion, setActiveSmallRegion] = useState("");
    let filteredprek = [];
    let filteredpard = [];
    let filteredel = [];
    let filteredkt = [];
    //if(!typeSelected){
        if(activeRegion !== ""){
            if(activeRegion !== "all"){
                specsRegions.forEach(region =>{
                    if(region.value === activeRegion){
                        if(activeSmallRegion){
                            filteredprek = items.filter((item) => {return (item.location === activeSmallRegion /*|| !item.location*/) && item.type === "Prekybos centras"});
                            filteredpard = items.filter((item) => {return (item.location === activeSmallRegion /*|| !item.location*/) && item.type === "Parduotuvė"});
                            filteredel = items.filter((item) => {return (item.location === activeSmallRegion /*|| !item.location*/) && item.type === "Elektroninė parduotuvė"});
                            filteredkt = items.filter((item) => {return (item.location === activeSmallRegion /*|| !item.location*/) && item.type === "Atstovas"});    
                        }else{
                            filteredprek = items.filter((item) => {return (region.smallerRegions.some(e => e.value === item.location) /*|| !item.location*/) && item.type === "Prekybos centras"});
                            filteredpard = items.filter((item) => {return (region.smallerRegions.some(e => e.value === item.location) /*|| !item.location*/) && item.type === "Parduotuvė"});
                            filteredel = items.filter((item) => {return (region.smallerRegions.some(e => e.value === item.location) /*|| !item.location*/) && item.type === "Elektroninė parduotuvė"});
                            filteredkt = items.filter((item) => {return (region.smallerRegions.some(e => e.value === item.location) /*|| !item.location*/) && item.type === "Atstovas"});    
                        }
                    }
                })
            }
        }
    //console.log(filteredprek.length, filteredpard.length, filteredel.length, filteredkt.length, "filtered", nothingSelected )
    // console.log(JSON.stringify(MOCK_REPRESENTATIVES))
    let prekCentrasLink = "";
    if(activeReps === "Prekybos centras"){
        switch(activePrekCentras){
            case "Vilpra":
                prekCentrasLink = "www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai";
                break;
            case "Makveža":
                prekCentrasLink = "www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI";
                break;

           // case "Depo":
             //   prekCentrasLink = "online.depo-diy.lt";
              //  break;

            case "Euroliux":
                prekCentrasLink = "www.euroliux.lt";
                break;
            default:
                prekCentrasLink = "";
        }
    }else prekCentrasLink = "";
    

  return <> 

        <HeaderBigBlack activeTab={"Pardavimo atstovai"}/>
        <div className='smallRepHeader'><HeaderBlack activeTab={"Pardavimo atstovai"}/></div>

  <div className='representativesMain'>
    {/* <nav className="Nav" style={{position: "static", paddingBottom: "50px"}}>
                    
                    <p>
                        <span><a href="#contacts">{translation[71].text}</a></span>
                        <span style={{minWidth: "30px", borderBottom: "1px solid #a7a7a7", display: "inline-block", margin: "0px 1px 0px 1px", position: "relative", top: "6px"}}/>
                        <span><Link to={"/atstovai"}>{translation[72].text}</Link></span>
                    </p>
    </nav> */}
    
    <div className='repListTitle'>
        <div style={{display: "block", paddingTop: "50px"}}>
            <div className='nameAndSelect'>
                <h1>{translation[72].text}</h1>
                <div className='specs-dropdown-divs'>
                    <div className="specs-dropdown-div" key="dd1">
                        <SelectSearch options={specsRegions} value={activeRegion} name="region" placeholder={translation[113].text} onChange={handleRegionChange} />
                        <i className="arrow down"></i>
                    </div>
                    <div className="specs-dropdown-div" key="dd2">
                        {activeRegion ?
                        <SelectSearch options={correctSmallRegions()} value={activeSmallRegion} name="smallRegion" placeholder={translation[120].text} onChange={handleSmallRegionChange} />
                        : null}
                    </div>
                </div>
            </div>
        
        <hr/>
        <div className='categoryBoxes'>
        {(activeRegion && (filteredprek.length  > 0 || filteredpard.length > 0)) || nothingSelected ? 
            <div className='twoBoxes'>
                {(activeRegion && filteredprek.length  > 0) || nothingSelected ? 
                <div className='categoryBox'>
                    <div className='categoryImageBox'>
                        <img className='categoryImage' alt="kategorija" src='/assets/pc-icon.svg'></img>
                    </div>
                    <h2 className='categoryName'>{translation[109].text}</h2>
                    <Link to={"../atstovai/prekybos_centrai"} onClick={() => setResults(12)}>
                        {activeReps === "Prekybos centras" ? 
                            <>
                                <div className='categoryRadioDiv'>
                                    <input className='categoryRadioButton' key={"radiogaga"} type="radio" checked onChange={() => {}}></input>
                                    <label>{translation[109].text}</label>
                                </div>
                                <button className='categoryButton active'>{translation[114].text}</button>
                            </> :
                            <>
                                <div className='categoryRadioDiv'>
                                    <input className='categoryRadioButton' key={"notradiogaga"} type="radio"></input>
                                    <label>{translation[109].text}</label>
                                </div>
                                <button className='categoryButton'>{translation[115].text}</button>
                            </>
                        }   
                    </Link>
                </div>
                : null}
                {(activeRegion && filteredpard.length > 0) || nothingSelected ? 
                <div className='categoryBox'>
                    <div className='categoryImageBox'>
                        <img className='categoryImage' alt="kategorija" src='/assets/pard-icon.svg'></img>
                    </div>
                    <h2 className='categoryName'>{translation[110].text}</h2>
                    <Link to={"../atstovai/parduotuves"} onClick={() => setResults(12)}>
                        {activeReps === "Parduotuvė" ? 
                            <>
                                <div className='categoryRadioDiv'>
                                    <input className='categoryRadioButton' key={"radiogaga"} type="radio" checked onChange={() => {}}></input>
                                    <label>{translation[110].text}</label>
                                </div>
                                <button className='categoryButton active'>{translation[114].text}</button>
                            </> :
                            <>
                                <div className='categoryRadioDiv'>
                                    <input className='categoryRadioButton' key={"notradiogaga"} type="radio" ></input>
                                    <label>{translation[110].text}</label>
                                </div>
                                <button className='categoryButton'>{translation[115].text}</button>
                            </>
                        }   
                    </Link>
                </div>
                : null}
            </div>
            : null}
            {(activeRegion && (filteredel.length > 0 || filteredkt.length > 0)) || nothingSelected ? 
            <div className='twoBoxes'>
                {(activeRegion && filteredel.length) > 0 || nothingSelected ? 
                <div className='categoryBox'>
                    <div className='categoryImageBox'>
                        <img className='categoryImage' alt="kategorija" src='/assets/el-icon.svg'></img>
                    </div>
                    <h2 className='categoryName'>{translation[111].text}</h2>
                    <Link to={"../atstovai/el_parduotuves"} onClick={() => setResults(12)}>
                        {activeReps === "Elektroninė parduotuvė" ? 
                            <>
                                <div className='categoryRadioDiv'>
                                    <input className='categoryRadioButton' key={"radiogaga"} type="radio" checked onChange={() => {}}></input>
                                    <label>{translation[111].text}</label>
                                </div>
                                <button className='categoryButton active'>{translation[114].text}</button>
                            </> :
                            <>
                                <div className='categoryRadioDiv'>
                                    <input className='categoryRadioButton' key={"notradiogaga"} type="radio" ></input>
                                    <label>{translation[111].text}</label>
                                </div>
                                <button className='categoryButton'>{translation[115].text}</button>
                            </>
                        }   
                    </Link>
                </div>
                : null}
                {(activeRegion && filteredkt.length > 0) || nothingSelected ? 
                <div className='categoryBox'>
                    <div className='categoryImageBox'>
                        <img className='categoryImage' alt="kategorija" src='/assets/kt-icon.svg'></img>
                    </div>
                    <h2 className='categoryName'>{translation[112].text}</h2>
                    <Link to={"../atstovai/kiti"} onClick={() => setResults(12)}>
                        {activeReps === "Atstovas" ? 
                            <>
                                <div className='categoryRadioDiv'>
                                    <input className='categoryRadioButton' key={"radiogaga"} type="radio" checked onChange={() => {}}></input>
                                    <label>{translation[112].text}</label>
                                </div>
                                <button className='categoryButton active'>{translation[114].text}</button>
                            </> :
                            <>
                                <div className='categoryRadioDiv'>
                                    <input className='categoryRadioButton' key={"notradiogaga"} type="radio"></input>
                                    <label>{translation[112].text}</label>
                                </div>
                                <button className='categoryButton'>{translation[115].text}</button>
                            </>
                        }
                    </Link>
                </div>
                : null}
            </div>
            : null}
        </div>
        {activeReps === "Prekybos centras" ?
            <div className='prekCentraiRadioButtons'>
                <div className='prekCentrasDiv'>
                    <input className='categoryRadioButton' key={"vilpre"} type="radio" 
                        checked={activePrekCentras === "Vilpra" ? true : false}
                        onClick={(e) => setActivePrekCentras("Vilpra")}
                        onChange={() => {}}
                    ></input>
                    <img className={activePrekCentras === "Vilpra" ? "" : "opacity50"} alt="Vilpra" src='/assets/vilpra.png'></img>
                </div>
                <div className='prekCentrasDiv'>
                    <input className='categoryRadioButton' key={"mokiveze"} type="radio" 
                        checked={activePrekCentras === "Makveža" ? true : false}
                        onClick={(e) => setActivePrekCentras("Makveža")}
                        onChange={() => {}}
                    ></input>
                    <img className={activePrekCentras === "Makveža" ? "" : "opacity50"} alt="Moki Veži" src='/assets/mokivezi.png'></img>
                </div>
                {/* 
                <div className='prekCentrasDiv'>
                    <input className='categoryRadioButton' key={"depe"} type="radio" 
                        checked={activePrekCentras === "Depo" ? true : false}
                        onClick={(e) => setActivePrekCentras("Depo")}
                        onChange={() => {}}
                    ></input>
                    <img className={activePrekCentras === "Depo" ? "" : "opacity50"} alt="Depo" src='/assets/depo.png'></img>
                </div>
                */}
                <div className='prekCentrasDiv'>
                    <input className='categoryRadioButton' key={"euroliuxe"} type="radio" 
                        checked={activePrekCentras === "Euroliux" ? true : false}
                        onClick={(e) => setActivePrekCentras("Euroliux")}
                        onChange={() => {}}
                    ></input>
                    <img className={activePrekCentras === "Euroliux" ? "" : "opacity50"} alt="Euroliux" src='/assets/euroliux.png'></img>
                </div>
            </div>
        : null}
    </div>

    </div>
    {typeSelected ? 
        <RepresentativeList 
            reps={items} 
            activeReps={activeReps} 
            activeRegion={activeRegion} 
            activeSmallRegion={activeSmallRegion} 
            results={results} 
            setResults={setResults} 
            totalResults={totalResults} 
            setTotalResults={setTotalBigResults}
            activePrekCentras={activePrekCentras}
        />
     : null}

    {prekCentrasLink ? 
    <div className='additionalPrekCentrasLink'>
        <a href={`//${prekCentrasLink}`}>{trimLink(prekCentrasLink)}</a>
    </div>
    : null}

    {(results < totalResults && !typeSelected) || (typeSelected && results < totalBigResults) ? 
        <div className='resultsDiv' style={{display: "flex"}}>
            <p className="ImgNumber">{translation[116].text} {results} {translation[107].text} {typeSelected ? totalBigResults :totalResults}</p>
            <button className='categoryButton' onClick={() => setResults(results + 12)}>{translation[117].text} <i className="arrow down"></i></button>
        </div> :
        <></>
    }
    
    
  </div>
  <footer>
        <div className="infoFooter"><UsefulInfo/></div>
        <div className="locationFooter"><Location/></div>
    </footer>
  </>;
}

export default SellingRepresentatives;