import React from "react";
import PropTypes from 'prop-types';
import { Specialist } from "./specialist";


function SpecialistList(props){
    var { specs, activeRegion, activeSmallRegion } = props;
    var items
    var filtered = []
    var added = 0
    if(activeRegion === ""){
        items = specs.map((rep, index) => (
        <div className='rep' key={rep.id} style={{borderBottom: specs.length > 4 && index+1 <= (specs.length - (specs.length % 4 === 0 ? 4 : specs.length % 4)) ? "1px solid #a7a7a7" : "none"}}>
            <h1 key={rep.id + "10"} className='repName'>{rep.name}</h1>
            <div key={rep.id + "110"}  className='sellingTextContent'>
                <span key={rep.id + "11"} className='repLocationOrange'>{rep.location}</span>
                <div key={rep.id + "12"}  className='repContacts'>
                        {rep.contacts.map(contact => (
                            <p key={rep.id + contact} >{contact}</p>
                        ))}
                </div>
            </div>
        </div>
        ));
    }else if(activeSmallRegion === ""){
        // let filtered = specs.filter((item) => {return item.regions.find(activeRegion.name) === activeRegion.name})
        filtered = []
        added = 0
        specs.forEach(spec => {
            added = 0
            spec.regions.forEach(region => {
                if(region === activeRegion){
                    if(added === 0){
                        filtered.push(spec)
                        added = 1
                    }
                }
            })
        });
        // console.log(filtered)

        items = filtered.map((rep, index) => (
            <div className='rep' key={rep.id} style={{borderBottom: filtered.length > 4 && index+1 <= (filtered.length - (filtered.length % 4 === 0 ? 4 : filtered.length % 4)) ? "1px solid #a7a7a7" : "none"}}>
                <h1 key={rep.id + "10"} className='repName'>{rep.name}</h1>
                <div key={rep.id + "110"}  className='sellingTextContent'>
                    <span key={rep.id + "11"} className='repLocationOrange'>{rep.location}</span>
                    <div key={rep.id + "12"} className='repContacts'>
                        {rep.contacts.map(contact => (
                            <p key={rep.id + contact}>{contact}</p>
                        ))}
                    </div>
                </div>
            </div>
        ));
    }else{
        // let filtered = specs.filter((item) => {return item.regions.find(activeRegion.name) === activeRegion.name})
        filtered = []
        added = 0
        specs.forEach(spec => {
            added = 0
            spec.locations.forEach(location => {
                if(location === activeSmallRegion){
                    if(added === 0){
                        filtered.push(spec)
                        added = 1
                    }
                }
            })
        });
        // console.log(filtered)

        items = filtered.map((rep, index) => (
            <div className='rep' key={rep.id} style={{borderBottom: filtered.length > 4 && index+1 <= (filtered.length - (filtered.length % 4 === 0 ? 4 : filtered.length % 4)) ? "1px solid #a7a7a7" : "none"}}>
                <h1 key={rep.id + "10"} className='repName'>{rep.name}</h1>
                <div key={rep.id + "110"}  className='sellingTextContent'>
                    <span key={rep.id + "11"} className='repLocationOrange'>{rep.location}</span>
                    <div key={rep.id + "12"} className='repContacts'>
                        {rep.contacts.map(contact => (
                            <p key={rep.id + contact}>{contact}</p>
                        ))}
                    </div>
                </div>
            </div>
        ));
    }
    return <div className="repList" id='specialistList'>{items}</div>;
}

SpecialistList.propTypes = {
    specs: PropTypes.arrayOf(PropTypes.instanceOf(Specialist)).isRequired
};

export default SpecialistList;