export const specsRegions = [
    {name: 'Vilniaus apskritis', value: 'Vilnius', smallerRegions: [
        {name: "Švenčionių rajonas", value: "Švenčionių raj."},
        {name: "Širvintų rajonas", value: "Širvintų raj."},
        {name: "Vilniaus rajonas", value: "Vilniaus raj."},
        {name: "Trakų rajonas", value: "Trakų raj."},
        {name: "Šalčininkų rajonas", value: "Šalčininkų raj."},
        {name: "Elektrėnų rajonas", value: "Elektrėnų raj."},
        {name: "Ukmergės rajonas", value: "Ukmergės raj."},
    ]},
    {name: 'Kauno apskritis', value: 'Kaunas', smallerRegions: [
        {name: "Kauno rajonas", value: "Kauno raj."},
        {name: "Kaišiadorių rajonas", value: "Kaišiadorių raj."},
        {name: "Kėdainių rajonas", value: "Kėdainių raj."},
        {name: "Prienų rajonas", value: "Prienų raj."},
        {name: "Jonavos rajonas", value: "Jonavos raj."},
    ]},
    {name: 'Alytaus apskritis', value: 'Alytus', smallerRegions: [
        {name: "Alytaus rajonas", value: "Alytaus raj."},
        {name: "Varėnos rajonas", value: "Varėnos raj."},
        {name: "Lazdijų rajonas", value: "Lazdijų raj."},
        {name: "Druskininkų rajonas", value: "Druskininkų raj."},
    ]},
    {name: 'Marijampolės apskritis', value: 'Marijampolė', smallerRegions: [
        {name: "Marijampolės rajonas", value: "Marijampolės raj."},
        {name: "Vilkaviškio rajonas", value: "Vilkaviškio raj."},
        {name: "Šakių rajonas", value: "Šakių raj."},
        {name: "Kalvarijos rajonas", value: "Kalvarijos raj."},
        {name: "Kazlų rūdos rajonas", value: "Kazlų rūdos raj."},
    ]},
    {name: 'Utenos apskritis', value: 'Utena', smallerRegions: [
        {name: "Utenos rajonas", value: "Utenos raj."},
        {name: "Anykščių rajonas", value: "Anykščių raj."},
        {name: "Molėtų rajonas", value: "Molėtų raj."},
        {name: "Ignalinos rajonas", value: "Ignalinos raj."},
        {name: "Visaginas", value: "Visaginas"},
        {name: "Zarasų rajonas", value: "Zarasų raj."},
    ]},
    {name: 'Panevėžio apskritis', value: 'Panevėžys', smallerRegions: [
        {name: "Panevėžio rajonas", value: "Panevėžio raj."},
        {name: "Kupiškio rajonas", value: "Kupiškio raj."},
        {name: "Rokiškio rajonas", value: "Rokiškio raj."},
        {name: "Biržų rajonas", value: "Biržų raj."},
        {name: "Pasvalio rajonas", value: "Pasvalio raj."},
    ]},
    {name: 'Šiaulių apskritis', value: 'Šiauliai', smallerRegions: [
        {name: "Šiaulių rajonas", value: "Šiaulių raj."},
        {name: "Radviliškio rajonas", value: "Radviliškio raj."},
        {name: "Kelmės rajonas", value: "Kelmės raj."},
        {name: "Pakruojo rajonas", value: "Pakruojo raj."},
        {name: "Joniškio rajonas", value: "Joniškio raj."},
        {name: "Akmenės rajonas", value: "Akmenės raj."},
    ]},
    {name: 'Tauragės apskritis', value: 'Tauragė', smallerRegions: [
        {name: "Tauragės rajonas", value: "Tauragės raj."},
        {name: "Šilalės rajonas", value: "Šilalės raj."},
        {name: "Jurbarko rajonas", value: "Jurbarko raj."},
        {name: "Pagėgių rajonas", value: "Pagėgių raj."},
    ]},
    {name: 'Telšių apskritis', value: 'Telšiai', smallerRegions: [
        {name: "Telšių rajonas", value: "Telšių raj."},
        {name: "Plungės rajonas", value: "Plungės raj."},
        {name: "Mažeikių rajonas", value: "Mažeikių raj."},
        {name: "Rietavo rajonas", value: "Rietavo raj."},
    ]},
    {name: 'Klaipėdos apskritis', value: 'Klaipėda', smallerRegions: [
        {name: "Klaipėdos rajonas", value: "Klaipėdos raj."},
        {name: "Šilutės rajonas", value: "Šilutės raj."},
        {name: "Skuodo rajonas", value: "Skuodo raj."},
        {name: "Kretingos rajonas", value: "Kretingos raj."},
        {name: "Palangos miestas", value: "Palangos m."},
    ]},
]