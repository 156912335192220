import { Katilas } from "./Katilas";

export const MOCK_KATILAI_LV = [
    new Katilas({
        id: 1,
        name: "Pellet Uni",
        type: "Granulu apkures",
        imageUrl: "assets/pellet_uni.png",
        pjuvis: "assets/pellet_uni_pjuvis.png",
        feature: "Augstākā klase",
        price: 3400,
        pricing: [
            [12, 3400, 0, [0, 30, 80, 110]],
            [16, 3530, 0, [0, 30, 80, 110]],
            [20, 3650, 120, [0, 30, 80, 110]],
            [26, 4070, 160, [0, 30, 80, 110]],
            [36, 4330, 160, [0, 30, 80, 110]],
        ],
        minPower: 12,
        maxPower: 36,
        power: [12, 16, 20, 26, 36],
        minArea: 120,
        maxArea: 360,
        efficiencyClass: "A++",
        fuelTypes: ["Granulas", "Malka", "Briketes", "Ogles"],
        heatExchangerWarranty: 6,
        certificate: 5,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 120, 160, 200, 260, 360],
            ["Kurtuves dziļums", "mm", 455, 455, 455, 505, 505],
            ["Kurināmā iekraušana", "l/dm\u00b3", 55, 55, 65, 84, 93],
            ["Kurināmā tvertne", "l/dm\u00b3", 230, 230, 250, 270, 270],
            ["Rotējošais granulu deglis", "kW", "4-16", "4-16", "5-20", "6-26", "8-36"],
            ["Siltummaiņa laukums", "m\u00b2", 2.2, 2.6, 2.9, 3.4, 3.7],
            ["Kurināmā iekraušanas atveres lielums", "cm", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Horizontālo siltummaiņu skaits", "gab", 3, 4, 4, 4, 4],
            ["Ūdens tilpums katlā", "l", 59, 63, 68, 80, 85],
            ["Masa", "kg", 260, 280, 310, 360, 380],
            ["Nepieciešamā velkme dūmenī", "Pa", 12, 13, 14, 15, 15],
        ],
        additionalData: [
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "90%"],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "150/160 mm"],
            ["Hidraulisko savienojumu izmēri", "G 1 \u00b9\u{141F}\u2074 collu"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
        ],
        selections: [
            ["Kuro talpa", "230 l", "260 l", "320 l", "350 l"],
            [
                ["Degiklis"],
                ["12", "4-16 kW"],
                ["16", "4-16 kW"],
                ["20", "4-16 kW", "5-20 kW"],
                ["26", "5-20 kW", "6-26 kW"],
                ["36", "6-26 kW", "8-36 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1150, 1260, 1260, 1260, 1260],
            ["B", "mm", 440, 440, 490, 540, 590],
            ["C", "mm", 640, 640, 640, 690, 690],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Jauda", "10-36 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["pu1lv", "pu2lv", "pu3lv", "pu4lv", "pu5lv", "pu6lv", "pu7lv"],
        iconLocations: [[245, 150], [208, 350], [320, 380], [245, 150], [242, 330], [315, 70], [355, 170]],
        description: "Visaugstākās kategorijas un tehniskā līmeņa granulu kurināmā katls ar četru pagriezienu siltummaini un keramikas deflektoru. Čuguna ārdi un keramikas deflektors veicina un nodrošina vienmērīgu degšanu. Tas ļauj sasniegt par 90 % lielāku katla lietderības koeficientu. Pilnībā automātiskais rotācijas deglis paredzēts zemākas kvalitātes un lielākas pelnainības granulu dedzināšanai. Rotējošā degšanas kamera palīdz pilnībā sadedzināt kurināmo un neļauj veidoties sārņiem. Kontrolieris kontrolē visus katlu mājas tīrīšanas un katla degšanas procesus.",
        burnerDescription: "Pilnībā automātisks granulu deglis labas un vidējas kvalitātes granulu dedzināšanai. Deglis ir aprīkots ar automātisko tīrīšanas mehānismu - rotējošu degšanas un gaisa padeves kameru, kas ļauj deglim pašam attīrīties no pelniem un sārņiem.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/Pellet_Burner_Instruction_EN.pdf",
        dimensionsImage: "../assets/pelletUniDimensions.svg",
        certificatePdf: "../assets/Bandymu sertifikatas Pellet Uni.pdf",
        instructionsPdf: "../assets/pellet_uni_instrukcija_lv.pdf",
        techSpecPdf: "../assets/pellet_uni_lv.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        connectionSchemePdf: "../assets/pelletUniPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 2,
        name: "Bio Kompakt",
        type: "Granulu apkures",
        imageUrl: "assets/bio_kompakt.png",
        pjuvis: "assets/bio_kompakt_pjuvis.png",
        feature: "Kompakts",
        price: 3400,
        pricing:[
            [12, 3400, 0, [0]],
            [16, 3550, 0, [0]],
            [20, 3700, 0, [0]],
        ],
        minPower: 12,
        maxPower: 20,
        power: [12, 16, 20],
        minArea: 120,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Granulas"],
        heatExchangerWarranty: 6,
        burnerWarranty: 3,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 120, 160, 200],
            ["Kurināmā tvertne", "l/dm\u00b3", 120, 160, 160],
            ["Rotējošais granulu deglis", "kW", "4-12", "4-16", "4-18"],
            ["Siltummaiņa laukums", "m\u00b2", 1.7, 2.0 , 2.4],
            ["Horizontālo siltummaiņu skaits", "gab", 2, 2, 2],
            ["Ūdens tilpums katlā", "l", 44, 50, 58],
            ["Masa", "kg", 155, 175, 195],
        ],
        additionalData: [
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "90%"],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "130/140 mm"],
            ["Hidraulisko savienojumu izmēri", "G 1 \u00b9\u{141F}\u2074 collu"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 470, 520, 570],
            ["B", "mm", 645, 695, 754],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Jauda", "10-20 kW"],
        ],
        icons: ["bk1lv", "bk2lv", "bk3lv", "bk4lv", "bk5lv", "bk6lv", "bk7lv"],
        iconLocations: [[290, 386], [230, 360], [375, 380], [298, 325], [298, 325], [0, 0], [414, 345]],
        description: "Kompaktas klases granulu katls ar keramikas degšanas kameru, keramikas deflektoru un lielu siltummaini. Ilgmūžīgā augstas temperatūras keramika uztur degšanu un nodrošina vienmērīgu degšanas procesu. Tas ļauj sasniegt par 90 % lielāku katla lietderības koeficientu. Pilnībā automātiskais rotācijas deglis paredzēts zemākas kvalitātes un lielākas pelnainības granulu dedzināšanai. Rotējošā degšanas kamera palīdz pilnībā sadedzināt kurināmo un neļauj veidoties sārņiem. Kontrolieris kontrolē visus katlu mājas tīrīšanas un katla degšanas procesus.",
        burnerDescription: "Pilnībā automātisks granulu deglis labas un vidējas kvalitātes granulu dedzināšanai. Deglis ir aprīkots ar automātisko tīrīšanas mehānismu - rotējošu degšanas un gaisa padeves kameru, kas ļauj deglim pašam attīrīties no pelniem un sārņiem.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/Pellet_Burner_Instruction_EN.pdf",
        dimensionsImage: "../assets/bioKompaktDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos BIO KOMPAKT.pdf",
        instructionsPdf: "../assets/bio_kompakt_instrukcija_lv.pdf",
        techSpecPdf: "../assets/bio_kompakt_lv.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        connectionSchemePdf: "../assets/bioKompaktPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 3,
        name: "Ekon Max",
        type: "Cietā kurināmā",
        imageUrl: "assets/ekon_max.png",
        pjuvis: "assets/ekon_max_pjuvis.png",
        feature: "Efektīvs",
        price: 1950,
        pricing:[
            [16, 1950, 0, [0]],
            [20, 2150, 0, [0]],
            [25, 2350, 0, [0]],
            [30, 2550, 0, [0]],
            [40, 2850, 0, [0]],
        ],
        minPower: 16,
        maxPower: 40,
        power: [16, 20, 25, 30, 40],
        minArea: 160,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Malka", "Briketes", "Zāģu skaidas", "Ogles"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 160, 200, 250, 300, 400],
            ["Kurināmā iekraušana", "l/dm\u00b3", 105, 125, 140, 160, 192],
            ["Kurtuves dziļums", "mm", 500, 500, 500, 500, 550],
            ["Kurināmā iekraušanas atveres lielums", "cm", "29x23", "34x23", "39x23", "44x23", "44x23"],
            ["Siltummaiņa laukums", "m\u00b2", 3.0, 3.4, 3.8, 4.2, 4.8],
            ["Horizontālo siltummaiņu skaits", "gab", 3, 3,3,3,3],
            ["Ūdens tilpums katlā", "l", 66,72,78,85,115],
            ["Masa", "kg", 230,260,290,320,350],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "150/160", "185/195", "185/195", "185/195", "185/195"],
        ],
        additionalData: [
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "85%"],
            ["Hidraulisko savienojumu izmēri", "G 1 \u00b9\u{141F}\u00b2 collu"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", ">20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1200, 1200, 1200, 1200, 1300],
            ["B", "mm", 420, 470, 520, 570, 570],
            ["C", "mm", 810, 810, 810, 810, 860],
        ],
        icons: ["em1lv", "em2lv", "em3lv", "em4lv", "em5lv", "em6lv", "em7lv", "em8lv"],
        iconLocations: [[224, 380], [218, 328], [254, 320], [302, 154], [360, 183], [308, 254], [408, 435], [430, 240]],
        description: "Ilgus gadus lietojamā konstrukcija ar dubultu sadegšanas kameru un trim horizontāliem siltummaiņiem nodrošina vislabākās katla darbības īpašības, vieglu apkopi un ilgu kalpošanas laiku. Katls ļoti piemērots malkas un ogļu dedzināšanai. Maksimāls siltummaiņa laukums un uzpildes tilpums nodrošina augstu lietderības koeficientu un ļoti ilgu degšanas laiku. Tas ir katls, kas piedāvā izcilu cenas un kvalitātes attiecību.",
        dimensionsImage: "../assets/ekonMaxDimensions.svg",
        techSpecPdf: "../assets/ekon_max_lv.pdf",
        connectionSchemePdf: "../assets/ekonMaxPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON MAX.pdf",
        instructionsPdf: "../assets/ekon_max_instrukcija_lv.pdf",
    }),
    new Katilas({
        id: 4,
        name: "Ekon",
        type: "Cietā kurināmā",
        imageUrl: "assets/ekon.png",
        pjuvis: "assets/ekon_pjuvis.png",
        feature: "Ekonomisks",
        price: 1300,
        pricing:[
            [10, 1300, 0, [0]],
            [13, 1500, 0, [0]],
            [16, 1650, 0, [0]],
            [20, 1850, 0, [0]],
        ],
        minPower: 10,
        maxPower: 20,
        power: [10, 13, 16, 20],
        minArea: 100,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Malka", "Briketes", "Zāģu skaidas", "Ogles"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 100, 130, 160, 200],
            ["Kurināmā iekraušana", "l/dm\u00b3", 65, 85, 100, 115],
            ["Kurtuves dziļums", "mm", 400, 400, 400, 400],
            ["Kurināmā iekraušanas atveres lielums", "cm", "29x23", "29x23", "34x23", "39x23"],
            ["Siltummaiņa laukums", "m\u00b2", 1.9, 2.2, 2.6, 2.8],
            ["Horizontālo siltummaiņu skaits", "gab", 2,2,2,2],
            ["Ūdens tilpums katlā", "l", 41, 52, 56, 68],
            ["Masa", "kg", 160, 190, 210, 250],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "150/160", "150/160", "150/160", "185/195"],
        ],
        additionalData: [
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "83%"],
            ["Hidraulisko savienojumu izmēri", "G 1 \u00b9\u{141F}\u2074 collu"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 910, 1100, 1100, 1150],
            ["B", "mm", 420, 420, 470, 520],
        ],
        icons: ["e1lv", "e2lv", "e3lv", "e4lv", "e5lv", "e6lv", "e7lv", "e8lv"],
        iconLocations: [[224, 360], [230, 316], [258, 318], [304, 126], [360, 150], [320, 210], [405, 435], [420, 220]],
        description: "Ilgus gadus lietojamā konstrukcija ar dubultu sadegšanas kameru un horizontāliem siltummaiņiem nodrošina vislabākās katla darbības īpašības, vieglu apkopi un ilgu kalpošanas laiku. Katls ļoti piemērots malkas, brikešu un ogļu dedzināšanai. Liels siltummaiņa laukums un katla uzpildes tilpums nodrošina augstu lietderības koeficientu, ekonomisku degšanu un ļoti ilgu degšanas laiku. Tas ir katls, kas piedāvā izcilu cenas un kvalitātes attiecību.",
        dimensionsImage: "../assets/ekonDimensions.svg",
        techSpecPdf: "../assets/ekon_lv.pdf",
        connectionSchemePdf: "../assets/ekonPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON.pdf",
        instructionsPdf: "../assets/ekon_instrukcija_lv.pdf",
    }),
    new Katilas({
        id: 5,
        name: "Klasika",
        type: "Cietā kurināmā",
        imageUrl: "assets/klasika.png",
        pjuvis: "assets/klasika_pjuvis.png",
        feature: "Viegli izmantot",
        price: 1050,
        pricing:[
            [8, 1050, 0, [0]],
            [10, 1150, 0, [0]],
            [13, 1300, 0, [0]],
            [16, 1500, 0, [0]],
            [20, 1650, 0, [0]],
            [25, 1800, 0, [0]],
            [30, 1950, 0, [0]],
        ],
        minPower: 8,
        maxPower: 30,
        power: [8, 10, 13, 16, 20, 25, 30],
        minArea: 80,
        maxArea: 300,
        efficiencyClass: "A+",
        fuelTypes: ["Malka", "Briketes", "Ogles"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 80, 100, 130, 160, 200, 250, 300],
            ["Kurināmā iekraušana", "l/dm\u00b3", 60, 72, 90, 105, 125, 140, 160],
            ["Kurtuves dziļums", "mm", 400, 400, 500, 500, 500, 500, 500],
            ["Kurināmā iekraušanas atveres lielums", "cm", "24x23", "29x23", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Siltummaiņa laukums", "m\u00b2", 1.3, 1.5, 1.8, 2.3, 2.5, 2.8, 3.1],
            ["Horizontālo siltummaiņu skaits", "gab", 2,2,2,3,3,3,3],
            ["Ūdens tilpums katlā", "l", 38,42,50,59,63,58,72],
            ["Masa", "kg", 135,150,170,200,225,250,275],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "150/160", "150/160", "150/160", "150/160", "185/195", "185/195", "185/195"],
            ["Hidraulisko savienojumu izmēri", "collu", "G 1 \u00b9\u{141F}\u2074", "G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2"]
        ],
        additionalData: [
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "83%"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000, 1000, 1000, 1200, 1200, 1200, 1200],
            ["B", "mm", 370, 420, 420, 420, 470, 520, 570],
            ["C", "mm", 570, 570, 670, 670, 670, 670, 670],
        ],
        icons: ["kl1lv", "kl2lv", "kl3lv", "kl4lv", "kl5lv"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Visilgāk izmantotā klasiskā katla konstrukcija, ar lielu katla kurināmā uzpildes tilpumu un lielu siltummaini. Tas nodrošina ļoti ilgu degšanas laiku. Katls ir ļoti piemērots kurināšanai ar malku un mitru cieto kurināmo. Tas nav dārgs, bet uzticams, vienkāršs un laika gaitā pārbaudīts katls.",
        dimensionsImage: "../assets/klasikaDimensions.svg",
        techSpecPdf: "../assets/klasika_lv.pdf",
        connectionSchemePdf: "../assets/klasikaPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KLASIKA.pdf",
        instructionsPdf: "../assets/klasika_instrukcija_lv.pdf",
    }),
    new Katilas({
        id: 6,
        name: "Kompakt",
        type: "Cietā kurināmā",
        imageUrl: "assets/kompakt.png",
        pjuvis: "assets/kompakt_pjuvis.png",
        feature: "Laba cena",
        price: 1100,
        pricing:[
            [12, 1100, 0, [0]],
            [16, 1250, 0, [0]],
            [20, 1400, 0, [0]],
        ],
        minPower: 12,
        maxPower: 20,
        power: [12, 16, 20],
        minArea: 120,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Malka", "Briketes", "Ogles"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 120, 160, 200],
            ["Kurināmā iekraušana", "l/dm\u00b3", 72, 90, 105],
            ["Kurtuves dziļums", "mm", 400, 500, 500],
            ["Kurināmā iekraušanas atveres lielums", "cm", "29x23", "29x23", "29x23"],
            ["Horizontālo siltummaiņu skaits", "gab", 2,2,3],
            ["Ūdens tilpums katlā", "l", 42, 50, 59],
            ["Masa", "kg", 150, 170, 200],
            ["Hidraulisko savienojumu izmēri", "coliai", "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u00b2"],
        ],
        additionalData: [
            ["Dūmeņa diametrs iekšpuse / ārpuse", "150/160 mm"],
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "83%"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", ">15 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000,1000,1200],
            ["B", "mm", 570,670,670],
        ],
        icons: ["ko1lv", "ko2lv", "ko3lv", "ko4lv", "ko5lv"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Ilgmūžīgas un uzticamas konstrukcijas cietā kurināmā katls ar lielu siltummaini un lielu kurināmā uzpildes tilpumu. Tam raksturīga viegla apkope un ekspluatācija, ilgs degšanas laiks. Katla tīrīšanu atvieglo atsevišķas durtiņas. Kompaktā konstrukcija ļauj uzstādīt katlu mazās telpās. Dūmu nosūkšanas aizbīdnis paredzēts vieglākai iekurināšanai, tas samazina dūmu iekļūšanas iespēju telpā kurināmā ielikšanas laikā kurtuvē. Katls tiek komplektēts ar dubultu velkmes regulēšanas aizbīdni, izturīgām, viegli atveramām durtiņām, ilgmūžīgiem čuguna ārdiem, kas nodrošina labāku kurināmā sadegšanu.",
        dimensionsImage: "../assets/kompaktDimensions.svg",
        techSpecPdf: "../assets/kompakt_lv.pdf",
        connectionSchemePdf: "../assets/kompaktPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KOMPAKT.pdf",
        instructionsPdf: "../assets/kompakt_instrukcija_lv.pdf",
    }),
    new Katilas({
        id: 7,
        name: "Ignis",
        type: "Cietā kurināmā",
        imageUrl: "assets/ignis.png",
        pjuvis: "assets/ignis_pjuvis.png",
        feature: "Praktisks",
        price: 1050,
        pricing:[
            [10, 1050, 0, [0]],
            [15, 1350, 0, [0]],
        ],
        minPower: 10,
        maxPower: 15,
        power: ["M 10", "D 15"],
        minArea: 100,
        maxArea: 150,
        efficiencyClass: "A+",
        fuelTypes: ["Malka", "Briketes", "Ogles"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 100, 150],
            ["Kurināmā iekraušana", "l/dm\u00b3", 70, 90],
            ["Kurtuves dziļums", "mm", 300, 400],
            ["Kurināmā iekraušanas atveres lielums", "cm", "34x26", "34x26"],
            ["Siltummaiņa laukums", "m\u00b2", 1.2, 1.8],
            ["Vertikālo siltummaiņu daudzums", "gab", 1, 2],
            ["Ūdens tilpums katlā", "l", 32, 42],
            ["Masa", "kg", 130, 190],
            ["Apkures efektivitāte", "%", 76, 78],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "130/140", "150/160"]
        ],
        additionalData: [
            ["Hidraulisko savienojumu izmēri", "G 1 \u00b9\u{141F}\u2074 collu"],
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", "15-20 Pa"], 
        ],
        selections: [
            [""],
            [""],
        ],
        icons: ["i1lv", "i2lv", "i3lv", "i4lv", "i5lv"],
        iconLocations: [[233, 190], [350, 220], [434, 260], [290, 240], [430, 204]],
        description: "Katls-plīts, tradicionāls, laika pārbaudīts izstrādājums. Katlam ir ļoti liels malkas uzpildes tilpums un liels siltummainis. Konstrukcija nodrošina augstu katla efektivitāti un ilgu degšanas laiku. Izmantotā augstas kvalitātes sertificēta 5 mm bieza tērauda loksne nodrošina ilgu kalpošanas laiku. Nevainojamā kvalitāte un uzticamā konstrukcija garantē izstrādājumam 4 gadu garantiju. Katlu var izmantot vasaras un ziemas režīmā, ir iespējams uzstādīt automātisko velkmes regulatoru. Katls tiek komplektēts ar kvalitatīvu čuguna plīts virsmu, maināma atvēršanas virziena durtiņām, ērtu mehānisko velkmes regulatoru.",
        dimensionsImage: "../assets/ignisDimensions.svg",
        techSpecPdf: "../assets/ignis_lv.pdf",
        connectionSchemePdf: "../assets/ignisPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos IGNIS.pdf",
        instructionsPdf: "../assets/ignis_instrukcija_lv.pdf",
    }),
    new Katilas({
        id: 8,
        name: "Agro Uni",
        type: "Zemkopības atkritumu",
        imageUrl: "assets/agro_uni.png",
        pjuvis: "assets/agro_uni_pjuvis.png",
        feature: "Ekoloģisks",
        price: 2750,
        pricing:[
            [15, 2750, 0, [0,250]],
            [20, 3100, 0, [0,250]],
            [30, 3500, 0, [0,350]],
            [40, 4000, 0, [0,350]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15, 20, 30, 40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Graudi", "Atsijas", "Granulas", "Šķelda", "Ogles 0-50", "Malka"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 150, 200, 300, 400],
            ["Kurtuves dziļums", "mm", 450, 450, 500, 600],
            ["Kurināmā iekraušana", "l/dm\u00b3", 35, 40, 65, 104],
            ["„Zenono“ deglis", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Siltummaiņa laukums", "m\u00b2", 1.9, 2.5, 3.3, 4.2],
            ["Kurināmā iekraušanas atveres lielums", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Horizontālo siltummaiņu skaits", "gab", 3, 4, 4, 4],
            ["Ūdens tilpums katlā", "l", 48, 62, 69, 100],
            ["Masa", "kg", 180, 230, 270, 310],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "150/160", "150/160", "150/160", "185/195"],
        ],
        additionalData: [
            ["Kurināmā tvertne su maišykle", "400/600 l/dm\u00b3"],
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "90%"],
            ["Hidraulisko savienojumu izmēri", "G 1 \u00b9\u{141F}\u2074 collu"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "400 l", "600 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Jauda", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["au1lv", "au2lv", "au3lv", "au4lv", "au5lv", "au6lv", "au7lv"],
        iconLocations: [[240, 350], [240, 350], [410, 316], [235, 320], [240, 190], [240, 190], [320, 210]],
        description: "Ļoti universāls zemkopības atkritumu katls, paredzēts automātiskā režīmā dedzināt dažādas graudaugu kultūras, zemkopības atliekas, zemas kvalitātes granulas, šķeldu, zāģu skaidas un ogles, malku un citu cieto kurināmo, kas tiek ielikts pa vidējām durtiņām. Speciāls kurināmā tvertnes sajaukšanas mehānisms, pašattīrošs deglis, liela pelnu kaste un citi risinājumi nodrošina vienmērīgu dažādu kurināmā veidu dedzināšanu un katla darbību. Liels horizontāls četru pagriezienu siltummainis nodrošina ērtu tīrīšanu un augstu katla lietderības koeficientu. Katls tiek komplektēts ar “Zenon” degli. Neiesakām katlu komplektēt ar nerūsējoša tērauda dūmeni.",
        burnerDescription: "Specializēts deglis, kas spēj automātiskā režīmā dedzināt dažādas graudaugu kultūras, zemkopības produkcijas atliekas, zemas kvalitātes granulas un dažādas ogles. Deglim ir nerūsējošā tērauda tīrīšanas mehānisms, kas lieliski noņem degšanas procesā radušos sārņus.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroUniDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO UNI.pdf",
        instructionsPdf: "../assets/agro_uni_instrukcija_lv.pdf",
        techSpecPdf: "../assets/agro_uni_lv.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroUniPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 9,
        name: "Universa",
        type: ["Granulu apkures", "Zemkopības atkritumu"],
        imageUrl: "assets/universa.png",
        pjuvis: "assets/universa_pjuvis.png",
        feature: "Populārs",
        price: 2550,
        pricing:[
            [15, 2550, 0, [0, 50]],
            [20, 2900, 0, [0, 50]],
            [30, 3300, 0, [0, 50]],
            [40, 3850, 0, [0, 50]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15,20,30,40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Granulas", "Graudi", "Kūdra", "Salmu granulas", "Saulespuķu granulas", "Ogles 0-50"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 150,200,300,400],
            ["Kurtuves dziļums", "mm", 450,450,500,600],
            ["Kurināmā iekraušana", "l/dm\u00b3", 35,40,65,104],
            ["„Zenono“ deglis", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Siltummaiņa laukums", "m\u00b2", 1.9,2.5,3.3,4.2],
            ["Kurināmā iekraušanas atveres lielums", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Horizontālo siltummaiņu skaits", "gab", 3, 4, 4,4],
            ["Ūdens tilpums katlā", "l", 48,62,69,100],
            ["Masa", "kg", 180,230,270,320],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "150/160","150/160","150/160","185/195"],
        ],
        additionalData: [
            ["Kurināmā tvertne", "230/300 l/dm\u00b3"],
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "90%"],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "150/160 mm"],
            ["Hidraulisko savienojumu izmēri", "G 1 \u00b9\u{141F}\u2074 collu"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", "15-20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "200 l", "300 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Jauda", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["u1lv", "u2lv", "u3lv", "u4lv", "u5lv", "u6lv"],
        iconLocations: [[240, 350], [240, 350], [234, 320], [246, 192], [246, 192], [330, 200]],
        description: "Ļoti universāls granulu kurināmā katls , kas darbojas automātiskā režīmā un ir paredzēts gan labas, gan sliktas kvalitātes granulu sadedzināšanai, kā arī visu graudaugu kultūru, beramu zemkopības atkritumu, kūdras un ogļu, kā arī malkas un cita cietā kurināmā dedzināšanai, ieliekot to pa vidējām durtiņām. Tas nav dārgs, bet uzticams un universāls granulu kurināmā katls, kura konstrukcija īpaši izstrādāta dažādu kurināmo veidu dedzināšanai. Liels horizontāls, četru pagriezienu siltummainis nodrošina ērtu tīrīšanu un augstu lietderības koeficientu. Katls ir komplektēts ar “Zenon” degli.",
        burnerDescription: "Specializēts deglis, kas spēj automātiskā režīmā dedzināt dažādas graudaugu kultūras, zemkopības produkcijas atliekas, zemas kvalitātes granulas un dažādas ogles. Deglim ir nerūsējošā tērauda tīrīšanas mehānisms, kas lieliski noņem degšanas procesā radušos sārņus.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/universaDimensions.svg",
        talpa: ['UT 200', 'UT 300'],
        talpaData:[
            ["A1", "mm", 1200, 1300],
            ["B1", "mm", 600, 750]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos UNIVERSA.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        techSpecPdf: "../assets/universa_lv.pdf",
        connectionSchemePdf: "../assets/universaPaleidimoSchema.pdf",
        instructionsPdf: "../assets/universa_instrukcija_lv.pdf",
    }),
    new Katilas({
        id: 10,
        name: "Pele Max",
        type: "Rūpnieciskais",
        imageUrl: "assets/pelle_max.png",
        pjuvis: "assets/pelle_max_pjuvis.png",
        feature: "Ekonomisks",
        price: 6140,
        pricing:[
            [50, 6140, 460, [0, 100, 300]],
            [70, 7600, 900, [0, 100, 300]],
            [100, 10000, 700, [0, 100, 300]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50,70,100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Malka", "Briketes", "Zāģu skaidas", "Ogles"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 500,700,1000],
            ["Kurtuves dziļums", "mm", 680,680,980],
            ["Kurināmā iekraušana", "l/dm\u00b3", 105,145,280],
            ["Rotējošais granulu deglis", "kW", "10-50", "15-70", "20-100"],
            ["Siltummaiņa laukums", "m\u00b2", 6, 7.8, 11.8],
            ["Kurināmā iekraušanas atveres lielums", "cm", "50x26", "60x26", "70x26"],
            ["Horizontālo siltummaiņu skaits", "gab", 5,5,5],
            ["Ūdens tilpums katlā", "l", 142,180,280],
            ["Masa", "kg", 500,650,890],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "185/198", "185/195", "200/210"],
        ],
        additionalData: [
            ["Kurināmā tvertne", "500/700/1000 l/dm\u00b3"],
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "90%"],
            ["Hidraulisko savienojumu izmēri", "G 2 collu"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", ">20 Pa"]
        ],
        selections: [
            ["Kuro talpa", "500 l", "700 l", "1000 l"],
            [
                ["Degiklis"],
                ["50", "8-36 kW", "10-50 kW"],
                ["70", "10-50 kW", "15-70 kW"],
                ["100", "15-70 kW", "20-100 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1585, 1585, 1735],
            ["A1", "mm", 1275, 1355, 1430],
            ["B", "mm", 630, 730, 830],
            ["B1", "mm", 650, 750, 850],
            ["C", "mm", 890, 890, 1195],
            ["C1", "mm", 265, 340, 340],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Jauda", "36-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["pm1lv", "pm2lv", "pm3lv", "pm4lv", "pm5lv"],
        iconLocations: [[242, 162], [252, 320], [310, 380], [235, 286], [370, 190]],
        description: "Rūpnieciskas lietošanas universāls granulu katls ar modernu un augstas kvalitātes rotācijas degli, kas spēj sadedzināt zemākas kvalitātes granulas. Tas ir universāls katls, ar kuru var kurināt gan granulas automātiskā režīmā, gan dažādu cieto kurināmo, ko var iekraut ar rokām. Katlam ir viens no lielākajiem siltummaiņa laukumiem, kas pieejamas tirgū. Pieci horizontāli siltummaiņi, daudz pagriezienu un garš dūmgāzu ceļš nodrošina ļoti augstu katla lietderības koeficientu.",
        burnerDescription: "Pilnībā automātisks granulu deglis labas un vidējas kvalitātes granulu dedzināšanai. Deglis ir aprīkots ar automātisko tīrīšanas mehānismu - rotējošu degšanas un gaisa padeves kameru, kas ļauj deglim pašam attīrīties no pelniem un sārņiem.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/pele_max_degiklio_instrukcija.pdf",
        dimensionsImage: "../assets/peleMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos PELE MAX.pdf",
        instructionsPdf: "../assets/pele_max_instrukcija_lv.pdf",
        controllerInstructionsPdf: "../assets/Burner_Controller_Instruction_EN.pdf",
        techSpecPdf: "../assets/pele_max_lv.pdf",
        connectionSchemePdf: "../assets/peleMaxPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 11,
        name: "Agro Max",
        type: ["Zemkopības atkritumu", "Rūpnieciskais"],
        imageUrl: "assets/agro_max.png",
        pjuvis: "assets/agro_max_pjuvis.png",
        feature: "Universāls",
        price: 5700,
        pricing:[
            [50, 5700, 0, [0,200,400]],
            [70, 6900, 0, [0,200,400]],
            [100, 8500, 300, [0,200,400]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A",
        fuelTypes: ["Graudi", "Atsijas", "Granulas", "Šķelda", "Ogles 0-50", "Malka"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 500, 700, 1000],
            ["Kurtuves dziļums", "mm", 680, 680, 980],
            ["Kurināmā iekraušana", "l/dm\u00b3", 140, 170, 290],
            ["„Zenono“ deglis", "kw", "15-70", "15-70", "20-100"],
            ["Siltummaiņa laukums", "m\u00b2", 5.9, 7.1, 11],
            ["Kurināmā iekraušanas atveres lielums", "cm", "50x34", "60x34", "70x34"],
            ["Horizontālo siltummaiņu skaits", "gab", 5, 5, 5],
            ["Ūdens tilpums katlā", "l", 145, 165, 280],
            ["Masa", "kg", 550, 650, 930],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "185/195", "185/195", "200/210"],
            ["Kurināmā tvertne", "l/dm\u00b3", 400, 600, 800]
        ],
        additionalData: [
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "90%"],
            ["Hidraulisko savienojumu izmēri", "G2 coliai"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", ">20 Pa"],
        ],
        selections: [
            ["Kuro talpa", "400 l", "600 l", "800 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1590, 1590, 1740],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 890, 890, 1190],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Jauda", "50-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["am1lv", "am2lv", "am3lv", "am4lv", "am5lv", "am6lv"],
        iconLocations: [[270, 350], [270, 350], [450, 306], [274, 306], [280, 162], [380, 274]],
        description: "Īpaši universāls rūpnieciska pielietojuma katls, paredzēts automātiskā režīmā dedzināt dažādas graudaugu kultūras, zemkopības atliekas, zemas kvalitātes granulas un dažādas ogles. Tas ir augstākās kategorijas un tehniskā līmeņa produkts, kas īpaši izstrādāts šāda kurināmā dedzināšanai. Lielā tvertne ar maisīšanas mehānismu, pašattīrošs deglis, liela pelnu kaste un citas priekšrocības padara katlu viegli lietojamu. Liels horizontāls piecu pagriezienu siltummainis nodrošina ērtu tīrīšanu un augstu lietderības koeficientu. Katls ir paredzēts arī malkas un cita cietā kurināmā dedzināšanai, kas tiek ielikts pa vidējām durtiņām. Katls ir komplektēts ar “Zenon” degli.",
        burnerDescription: "Specializēts deglis, kas spēj automātiskā režīmā dedzināt dažādas graudaugu kultūras, zemkopības produkcijas atliekas, zemas kvalitātes granulas un dažādas ogles. Deglim ir nerūsējošā tērauda tīrīšanas mehānisms, kas lieliski noņem degšanas procesā radušos sārņus.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroMaxDimensions.svg",
        talpa: ['AT 400', 'AT 600', 'AT 800'],
        talpaData:[
            ["A1", "mm", 1500, 1700, 1700],
            ["B1", "mm", 1080, 1080, 1190]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO MAX.pdf",
        instructionsPdf: "../assets/agro_max_instrukcija_lv.pdf",
        techSpecPdf: "../assets/agro_max_lv.pdf",
        controllerInstructionsPdf: "../assets/IE-70 naudojimo instrukcija N.pdf",
        connectionSchemePdf: "../assets/agroMaxPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 12,
        name: "Bio Max",
        type: "Rūpnieciskais",
        imageUrl: "assets/bio_max.png",
        pjuvis: "assets/bio_max_pjuvis.png",
        feature: "Praktisks",
        price: 3500,
        pricing:[
            [50, 3500, 0, [0]],
            [70, 4500, 0, [0]],
            [100, 5800, 0, [0]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Malka", "Briketes", "Ogles"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Apsildāmā platība", "līdz m\u00b2", 500, 700, 1000],
            ["Kurināmā iekraušana", "l/dm\u00b3", 240, 380, 550],
            ["Kurtuves dziļums", "mm", 600, 800, 1000], 
            ["Kurināmā iekraušanas atveres lielums", "cm", "40x50", "40x60", "40x70"],
            ["Siltummaiņa laukums", "m\u00b2", 5, 7.1, 10],
            ["Horizontālo siltummaiņu skaits", "gab", 4, 4, 4],
            ["Ūdens tilpums katlā", "l", 130, 180, 235],
            ["Masa", "kg", 420, 570, 800],
            ["Dūmeņa diametrs iekšpuse / ārpuse", "mm", "200/210", "220/230", "250/260"],
        ],
        additionalData: [
            ["Hidraulisko savienojumu izmēri", "G 2 coliai"],
            ["Minimālā katla darba temperatūra", "60 \u2070C"],
            ["Maksimālā katla darba temperatūra", "90 \u2070C"],
            ["Apkures efektivitāte", "83%"],
            ["Maksimālais ekspluatācijas spiediens", "1.5 bar"],
            ["Nepieciešamā velkme dūmenī", ">20 Pa"],
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1600, 1600, 1600],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 800, 1000, 1200],
        ],
        icons: ["bm1lv", "bm2lv", "bm3lv", "bm4lv", "bm5lv"],
        iconLocations: [[312, 152], [300, 280], [414, 440], [370, 150], [440, 280]],
        description: "Klasiskas konstrukcijas, uzticams un vienkārši lietojams rūpnieciskais katls. Tas atšķiras ar to, ka tajā var ielikt lielu daudzumu kurināmā, lielu siltummaini, ilgu kalpošanas laiku un zemu cenu. Katlā ieliekamā kurināmā tilpums un siltummaiņa laukums ir vieni no lielākajiem tirgū. Tas nodrošina ļoti ilgu degšanas laiku, katls ir ļoti piemērots malkas un mitra kurināmā dedzināšanai.",
        dimensionsImage: "../assets/bioMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos BIO MAX.pdf",
        techSpecPdf: "../assets/bio_max_lv.pdf",
        connectionSchemePdf: "../assets/bioMaxPaleidimoSchema.pdf",
        instructionsPdf: "../assets/bio_max_instrukcija_lv.pdf",
    }),
];