import React, {useEffect, useState} from 'react';
import { useLanguage } from './../LanguageContext';

function CartWindow(props) {
    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;
    const {cartItems, setCartItems, changeValue, changeValueUnfinished, items} = props
    const [cartList, setCartList] = useState([]);
    const deleteCartItem = (item) =>{
        let testobject = {...cartItems};
        if(!item.buildingPeriod) testobject.generalKatilaiUniques[item.index].count = 0;
        else testobject.countsBuilding[item.index].count = 0;
        setCartItems(testobject);
    }
    useEffect(() =>{
        let nonZeroItems = [];
        cartItems.generalKatilaiUniques.forEach((katilas, index) => {
                if(katilas.count > 0) 
                nonZeroItems.push({
                    name: katilas.name,
                    power: katilas.power,
                    degiklioPower: katilas.degiklioPower,
                    fuelCapacity: katilas.fuelCapacity,
                    count: katilas.count,
                    index: index
                });
        });
        cartItems.countsBuilding.forEach((katilas, index) =>{
            if(katilas.count > 0) 
                nonZeroItems.push({
                    name: katilas.name,
                    power: katilas.power,
                    degiklioPower: katilas.degiklioPower,
                    fuelCapacity: katilas.fuelCapacity,
                    buildingPeriod: katilas.buildingPeriod,
                    count: katilas.count,  
                    index: index
                });
        })
        setCartList(nonZeroItems.map((katilas, index) =>{
            // return <li key={item.katilasName + "li" + item.katilasCount + "he" + item.katiloIndex}>
            //         <div className='katiloUzsakymoName'>{item.buildingPeriod ? `${item.katilasName} ${item.buildingPeriod}` : `${item.katilasGroupName} ${item.katilasName}`}</div>
            //         <div> Kiekis: {item.katilasCount}</div>
            //         <button className='orderDeleteButton' onClick={() => deleteCartItem(item)}><img src='../assets/trash-alt-solid.svg' alt='trashIcon'></img></button>
            //     </li>
            let change = katilas.buildingPeriod ? changeValueUnfinished : changeValue;
            let correctArray = katilas.buildingPeriod ? cartItems.countsBuilding : cartItems.generalKatilaiUniques;
            let correctItems = katilas.buildingPeriod ? items.countsBuilding : items.generalKatilaiUniques;
            return <tr className='katilaiSandelyjeTableBody' key={katilas.name + "tr" + index + "cart"}>
            <td key={katilas.name + "h1" + index + "cart"}>{katilas.name}</td>
            <td key={katilas.name + "galia" + index + "cart"}>{katilas.power ? katilas.power+" kW": null}</td>
            <td key={katilas.name + "kurotalpa" + index + "cart"}
                style={(katilas.fuelCapacity && !katilas.buildingPeriod) ? {color:"#000000"}:{color:"#a7a7a7"}}>
                    {katilas.fuelCapacity ? katilas.fuelCapacity+" l": (katilas.buildingPeriod ? translation[149].text : null)}
            </td>
            <td key={katilas.name + "degikliogalia" + index + "cart"} 
                style={(katilas.degiklioPower && !katilas.buildingPeriod) ? {color:"#000000"}:{color:"#a7a7a7"}}>
                {katilas.degiklioPower ? katilas.degiklioPower+" kW": (katilas.buildingPeriod ? translation[149].text : null)}
            </td>
            <td key={katilas.name + "pagaminimoData" + index  + "cart"} className='orangeText'>{katilas.buildingPeriod ? katilas.buildingPeriod : " - "}</td>
            <td key={katilas.name + "inputas" + index  + "cart"} className='uzsakomasKiekisTd'>
              <button 
                className='uzsakymasUp'
                onClick={() => {change(correctArray[katilas.index].count+1, katilas.index, correctItems[katilas.index].count)}}
                >
                <i className='arrow up'></i>
              </button>
              <button 
                className='uzsakymasDown'
                onClick={() => {change(correctArray[katilas.index].count-1, katilas.index, correctItems[katilas.index].count)}}
                >
                <i className='arrow down'></i>
              </button>
              {/*reik kazkaip kad keiteliotu stuff carte*/}
              <input
                  style={correctArray[katilas.index].count > 0 ? {color:"#000000"}:{color:"#666666"}} 
                  id={`inputNumberColor${index}cart`} 
                  key={katilas.name + "input" + index + "cart"} 
                  type="number" 
                  min={0} 
                  max={correctItems[katilas.index].count}
                  value={correctArray[katilas.index].count} 
                  onChange={e => {change(e.target.value, katilas.index, katilas.count)}}
                  >
              </input>
            </td>
            <td key={katilas.name + "delete" + index  + "cart"}>
                <button className='orderDeleteButton' onClick={() => deleteCartItem(katilas)}><img src='../assets/trash-alt-solid.svg' alt='trashIcon'></img></button>
            </td>
          </tr>
        }))
    }, [cartItems])
    
    return <>
        {cartList.length > 0 ? 
            <div className='cartBox'>
                <h3 className='thirdKatilaiOrderGroupTitle'>{translation[124].text}</h3>
                <table className='katilaiSandelyjeTable'>
                  <thead>
                    <tr className='katilaiSandelyjeTableHead'>
                      <th>{translation[26].text}</th>
                      <th>{translation[42].text}</th>
                      <th>{translation[106].text}</th>
                      <th>{translation[125].text}</th>
                      <th className='orangeText'>{translation[126].text}</th>
                      <th>{translation[127].text}</th>
                    </tr>
                  </thead>
                  <tbody>{cartList}</tbody>
                </table>
            </div>
        :null}
    </>;
}

export default CartWindow;

        