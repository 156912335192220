export class Representative {
    constructor(initializer) {
        this.id = undefined;
        this.name = '';
        this.contacts = [];
        this.link = '';
        this.location = '';
        this.type = '';

        if(!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.name) this.name = initializer.name;
        if (initializer.contacts) this.contacts = initializer.contacts;
        if (initializer.link) this.link = initializer.link;
        if (initializer.location) this.location = initializer.location;
        if (initializer.type) this.type = initializer.type;

    }

    isNew(){
        return this.id === undefined;
    }

}