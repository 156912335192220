import React from "react";
import PropTypes from 'prop-types';
import { Representative } from "./representative";
import { specsRegions } from './specsRegions';

function trimLink(link){
    var ind = link.indexOf(".lt/")
    var indEu = link.indexOf(".eu/")
    if(ind !== -1){
        return link.substring(0, ind+3)
    }else if(indEu !== -1){
        return link.substring(0, indEu+3)
    }else return link
}
function RepresentativeList(props){
    var { reps, activeReps, activeRegion, activeSmallRegion, results, setTotalResults, activePrekCentras } = props;
    var items;
    var filtered;
    if(activeReps === undefined){
        //i dont think it ever goes here
        filtered = reps
        if(activeRegion !== ""){
            if(activeRegion !== "all"){
                if(activeSmallRegion !== ""){
                    //console.log(filtered)
                    filtered = filtered.filter((item) => {return item.location === activeSmallRegion})
                    //console.log(activeRegion, activeSmallRegion, filtered)
                }else{
                    specsRegions.forEach(region =>{
                        filtered = filtered.filter((item) => {return region.smallerRegions.some(e => e.value === item.location)})
                    })
                    //console.log(filtered)
                }
            }
        }
        items = filtered.map((rep, index) => (
            <div className='rep' key={rep.id + "rep"} style={{borderBottom: filtered.length > 4 && index+1 <= (filtered.length - (filtered.length % 4 === 0 ? 4 : filtered.length % 4)) ? "1px solid #a7a7a7" : "none"}}>
                <h1 key={rep.id + "10"} className='repName'>{rep.name}</h1>
                <div key={rep.id + "11"}  className='sellingTextContent'>
                    <div key={rep.id + "12"}  className='repContacts'>
                        {rep.contacts.map(contact => (
                            <p key={`${rep.id}${contact}`} >{contact}</p>
                        ))}
                    </div>
                    <a key={rep.id + "13"} href={`//${rep.link}`}>{rep.link}</a>
                </div>
            </div>
        ));
    }else{
        filtered = reps.filter((item) => {return item.type === activeReps})
        //console.log(filtered, "gal jau tuscias")
        const ifPrekCentrasBool = activePrekCentras && activeReps === "Prekybos centras";
        if(ifPrekCentrasBool) filtered = filtered.filter((item) => {return item.name.includes(activePrekCentras)})
        var total = 0
        if(activeRegion !== ""){
            if(activeRegion !== "all"){
                if(activeSmallRegion !== ""){
                    //console.log(filtered)
                    filtered = filtered.filter((item) => {return item.location === activeSmallRegion})
                    //console.log(activeRegion, activeSmallRegion, filtered)
                }else{
                    specsRegions.forEach(region =>{
                        if(region.value === activeRegion)
                        filtered = filtered.filter((item) => {return region.smallerRegions.some(e => {
                            //console.log(e.value === item.location)
                            return e.value === item.location
                        })})
                    })
                    //console.log(filtered)
                }
            }
        }
        //console.log(filtered)
        items = filtered.map((rep, index) => (
            results > index ?
            <div className='rep' key={rep.id + "rep"} style={{borderBottom: filtered.length > 4 && index+1 <= (filtered.length - (filtered.length % 4 === 0 ? 4 : filtered.length % 4)) ? "1px solid #a7a7a7" : "none"}}>
                <h1 key={rep.id + "10"} className='repName'>{ifPrekCentrasBool ? activePrekCentras : rep.name}</h1>
                <div key={rep.id + "11"} className='sellingTextContent'>
                    <div key={rep.id + "12"} className='repContacts'>
                        {rep.contacts.map(contact => (
                            <p key={`${rep.id}${contact}`}>{contact}</p>
                        ))}
                    </div>
                    {
                        Array.isArray(rep.link) 
                        ? 
                        rep.link.map((element, index) => 
                         ifPrekCentrasBool ? null : <a key={rep.id + "13" + index} href={`//${element}`}>{trimLink(element)}</a>
                        )
                        :
                        ifPrekCentrasBool ? null : <a key={rep.id + "13"} href={`//${rep.link}`}>{trimLink(rep.link)}</a>
                    }
                    {/* {ifPrekCentrasBool ? null : <a key={rep.id + "13"} href={`//${rep.link}`}>{trimLink(rep.link)}</a>
                    } */}
                    <div key={rep.id + "fake"} style={{display:"none"}}>
                        {total = index+1}
                    </div>
                </div>
            </div> :
            <div key={rep.id + "fake"} style={{display:"none"}}>
                {total = index+1}
            </div>
        ));
        //hehehehehehehe
        setTimeout(() => setTotalResults(total), 0);
        
        //console.log(total, "totalResults", activeReps)
    }
    return <div className="repList">{items}</div>;
}

RepresentativeList.propTypes = {
    reps: PropTypes.arrayOf(PropTypes.instanceOf(Representative)).isRequired
};

export default RepresentativeList;