import { Specialist } from "./specialist";

export const MOCK_SPECIALISTS = [
    // new Specialist({
    //     id:2001,
    //     name: "UAB Vilpra",
    //     contacts:["Perkūnkiemio g. 4, Vilnius", "8 5 219 08 12", "+370 655 76019", "vilnius@vilpra.lt"],
    //     location: "Vilnius"
    // }),
    // new Specialist({
    //     id:2002,
    //     name: "MB Santechnika plius",
    //     contacts:["Kalvarijų g. 151, Vilnius", "+370 655 75 111", "info@katiluturgus.lt"],
    //     location: "Vilnius"
    // }),
    // new Specialist({
    //     id:2003,
    //     name: "UAB Internetinė prekyba",
    //     contacts:["+370 699 28 337", "info@katilai.lt"],
    //     location: "Panevežys"
    // }),
    // new Specialist({
    //     id:2004,
    //     name: "UAB Mikludava",
    //     contacts:["+370 676 21 001", "sarunas.bazys@mikludava.lt"],
    //     location: "Kaunas"
    // })
    new Specialist({
        id:2005,
        name: "Egidijus Šlepetys",
        contacts:["+370 630 79000", "egidijus@katiluekspertai.lt"],
        locations: ["Švenčionių raj.", "Širvintų raj.", "Vilniaus raj.", "Trakų raj.", "Šalčininkų raj.", "Elektrėnų raj."],
        regions: ["Vilnius"]
    }),
    new Specialist({
        id:2006,
        name: "Arvydas Petkus",
        contacts:["+370 606 66600", "info@gkatilai.lt"],
        locations: ["Širvintų raj.", "Vilniaus raj.", "Trakų raj.", "Elektrėnų raj.", "Ukmergės raj.", "Kauno raj.", "Kaišiadorių raj.", "Kėdainių raj.", "Prienų raj.", "Jonavos raj.", "Alytaus raj.", "Lazdijų raj.", "Marijampolės raj.", "Vilkaviškio raj.", "Šakių raj.", "Kalvarijos raj.", "Kazlų rūdos raj.", "Anykščių raj.", "Tauragės raj.", "Šilalės raj.", "Jurbarko raj.", "Pagėgių raj."],
        regions: ["Vilnius", "Kaunas", "Alytus", "Marijampolė", "Utena", "Tauragė"]
    }),
    new Specialist({
        id:2007,
        name: "Algimantas Siaurukas",
        contacts:["+370 614 70979", "algimantas@gmail.com"],
        locations: ["Trakų raj.", "Šalčininkų raj.", "Kaišiadorių raj.", "Kėdainių raj.", "Prienų raj.", "Alytaus raj.", "Varėnos raj.", "Lazdijų raj.", "Druskininkų raj.", "Marijampolės raj.", "Vilkaviškio raj.", "Šakių raj.", "Kalvarijos raj.", "Kazlų rūdos raj."],
        regions: ["Vilnius", "Kaunas", "Alytus", "Marijampolė"]
    }),
    new Specialist({
        id:2008,
        name: "Nerijus Urbonavičius",
        contacts:["+370 602 07868", "info@protingasiluma.lt"],
        locations: ["Kauno raj.", "Kaišiadorių raj.", "Kėdainių raj.", "Prienų raj.", "Jonavos raj.", "Alytaus raj.", "Lazdijų raj.", "Druskininkų raj.", "Marijampolės raj.", "Vilkaviškio raj.", "Šakių raj.", "Kalvarijos raj.", "Kazlų rūdos raj.", "Panevėžio raj.", "Biržų raj.", "Pasvalio raj.", "Kelmės raj.", "Pakruojo raj.", "Tauragės raj.", "Šilalės raj.", "Jurbarko raj.", "Pagėgių raj.", "Telšių raj.", "Plungės raj.", "Rietavo raj.", "Klaipėdos raj.", "Šilutės raj.", "Skuodo raj.", "Kretingos raj.", "Palangos m."],
        regions: ["Kaunas", "Alytus", "Marijampolė", "Panevėžys", "Šiauliai", "Tauragė", "Telšiai", "Klaipėda"]
    }),
    new Specialist({
        id:2009,
        name: "Augustinas Nasevičius",
        contacts:["+370 686 15954", "termista.sildymas@gmail.com"],
        locations: ["Utenos raj.", "Molėtų raj.", "Ignalinos raj.", "Visaginas", "Zarasų raj."],
        regions: ["Utena"]
    }),
    new Specialist({
        id:2010,
        name: "Egidijus Jomantas",
        contacts:["+370 612 48272", "siauressiluma@gmail.com"],
        locations: ["Anykščių raj.", "Panevėžio raj.", "Kupiškio raj.", "Rokiškio raj.", "Biržų raj.", "Pasvalio raj.", "Radviliškio raj.", "Pakruojo raj.", "Joniškio raj."],
        regions: ["Utena", "Panevėžys", "Šiauliai"]
    }),
    new Specialist({
        id:2011,
        name: "Zenonas Tamošauskas",
        contacts:["+370 618 63071"],
        locations: ["Šiaulių raj.", "Joniškio raj.", "Akmenės raj.", "Mažeikių raj."],
        regions: ["Šiauliai", "Telšiai"]
    }),
    new Specialist({
        id:2012,
        name: "Arūnas Mažrimas",
        contacts:["+370 698 00793", "arunasgranitas@gmail.com"],
        locations: ["Šiaulių raj.", "Radviliškio raj.", "Kelmės raj.", "Pakruojo raj.", "Joniškio raj.", "Akmenės raj.", "Telšių raj.", "Plungės raj.", "Mažeikių raj.", "Rietavo raj.", "Klaipėdos raj.", "Šilutės raj.", "Skuodo raj.", "Kretingos raj.", "Palangos m."],
        regions: ["Šiauliai", "Telšiai", "Klaipėda"]
    }),
    new Specialist({
        id:2013,
        name: "Konstantinas Pakulis",
        contacts:["+370 655 50900", "konstantinas.pakulis@gmail.com"],
        locations: ["Širvintų raj.", "Ukmergės raj.", "Utenos raj.", "Molėtų raj.", "Kėdainių raj."],
        regions: ["Vilnius", "Utena", "Kaunas"]
    })
];