
export class Katilas {
    constructor(initializer) {
        this.id = undefined;
        this.name = '';
        this.type = [];
        this.imageUrl = '';
        this.pjuvis = '';
        this.feature = '';
        this.price = 0;
        this.pricing = [];
        this.minPower = 0;
        this.maxPower = 0;
        this.power = [];
        this.minArea = 0;
        this.maxArea = 0;
        this.efficiencyClass = '';
        this.fuelTypes = [];
        this.heatExchangerWarranty = 0;
        this.certificate = 0;
        this.burnerWarranty = 0;
        this.tableData = [];
        this.additionalData = [];
        this.selections = [];
        this.dimensions = [];
        this.dimensionsImage = '';
        this.degiklis = [];
        this.degiklisData = [];
        this.icons = [];
        this.iconLocations = [];

        this.description = '';
        this.burnerDescription = '';
        this.burnerImage = '';
        this.burnerInstructionsPdf = '';
        this.connectionDescription = [];
        this.connectionSchemaFile = '';
        this.instructionsPdf = '';
        this.certificatePdf = '';
        this.controllerInstructionsPdf = '';
        this.techSpecPdf = '';
        this.connectionSchemePdf = '';

        this.talpa = [];
        this.talpaData = [];

        if(!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.name) this.name = initializer.name;
        if (initializer.type) this.type = initializer.type;
        if (initializer.imageUrl) this.imageUrl = initializer.imageUrl;
        if (initializer.feature) this.feature = initializer.feature;
        if (initializer.price) this.price = initializer.price;
        if (initializer.pricing) this.pricing = initializer.pricing;
        if (initializer.minPower) this.minPower = initializer.minPower;
        if (initializer.maxPower) this.maxPower = initializer.maxPower;
        if (initializer.minArea) this.minArea = initializer.minArea;
        if (initializer.maxArea) this.maxArea = initializer.maxArea;
        if (initializer.efficiencyClass) this.efficiencyClass = initializer.efficiencyClass;
        if (initializer.fuelTypes) this.fuelTypes = initializer.fuelTypes;
        if (initializer.heatExchangerWarranty) this.heatExchangerWarranty = initializer.heatExchangerWarranty;
        if (initializer.certificate) this.certificate = initializer.certificate;
        if (initializer.burnerWarranty) this.burnerWarranty = initializer.burnerWarranty;
        if (initializer.pjuvis) this.pjuvis = initializer.pjuvis;
        if (initializer.power) this.power = initializer.power;
        if (initializer.tableData) this.tableData = initializer.tableData;
        if (initializer.additionalData) this.additionalData = initializer.additionalData;
        if (initializer.selections) this.selections = initializer.selections;
        if (initializer.dimensions) this.dimensions = initializer.dimensions;
        if (initializer.dimensionsImage) this.dimensionsImage = initializer.dimensionsImage;
        if (initializer.degiklis) this.degiklis = initializer.degiklis;
        if (initializer.degiklisData) this.degiklisData = initializer.degiklisData;
        if (initializer.icons) this.icons = initializer.icons;
        if (initializer.description) this.description = initializer.description;
        if (initializer.burnerDescription) this.burnerDescription = initializer.burnerDescription;
        if (initializer.burnerImage) this.burnerImage = initializer.burnerImage;
        if (initializer.burnerInstructionsPdf) this.burnerInstructionsPdf = initializer.burnerInstructionsPdf;
        if (initializer.connectionDescription) this.connectionDescription = initializer.connectionDescription;
        if (initializer.connectionSchemaFile) this.connectionSchemaFile = initializer.connectionSchemaFile;
        if (initializer.talpa) this.talpa = initializer.talpa;
        if (initializer.talpaData) this.talpaData = initializer.talpaData;
        if (initializer.certificatePdf) this.certificatePdf = initializer.certificatePdf;
        if (initializer.instructionsPdf) this.instructionsPdf = initializer.instructionsPdf;
        if (initializer.techSpecPdf) this.techSpecPdf = initializer.techSpecPdf;
        if (initializer.controllerInstructionsPdf) this.controllerInstructionsPdf = initializer.controllerInstructionsPdf;
        if (initializer.connectionSchemePdf) this.connectionSchemePdf = initializer.connectionSchemePdf;
        if (initializer.iconLocations) this.iconLocations = initializer.iconLocations;
    }

    isNew(){
        return this.id === undefined;
    }

}