import { Representative } from "./representative";

export const MOCK_REPRESENTATIVES = [
    // new Representative({
    //     id:1001,
    //     name: "UAB Vilpra",
    //     contacts:["Perkūnkiemio g. 4, Vilnius", "8 5 219 08 12", "+370 655 76019", "vilnius@vilpra.lt"],
    //     link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
    //     location: "Vilnius",
    //     type: "Prekybos centras"
    // }),
    new Representative({
        id:1043,
        name: "UAB Šiaurės šiluma",
        contacts:["Kęstučio g. 53-1, Biržai", "+370 612 48272", "siauressiluma@gmail.com"],
        link:"",
        location: "Biržų raj.",
        type: "Parduotuvė"
    }),
    new Representative({
        id:1044,
        name: "UAB Šiaurės šiluma",
        contacts:["P.Avižonio g. 23/8, Pasvalys", "+370 612 48272", "siauressiluma@gmail.com"],
        link:"",
        location: "Pasvalio raj.",
        type: "Parduotuvė"
    }),
    new Representative({
        id:1005,
        name: "UAB Euroliuksas",
        contacts:["Savanorių pr. 214A, Kaunas", "+370 657 99430", "jonas@euroliux.lt"],
        link:"www.euroliux.lt",
        location: "Kauno raj.",
        type: "Parduotuvė"
    }),
    new Representative({
        id:1006,
        name: "UAB Euroliuksas",
        contacts:["Radvilų g. 53, Kėdainiai", "+370 657 99405", "jonas@euroliux.lt"],
        link:"www.euroliux.lt",
        location: "Kėdainių raj.",
        type: "Parduotuvė",

    }),
    new Representative({
        id:1007,
        name: "UAB Euroliuksas",
        contacts:["Kniaudiškių g. 2A, Panevėžys", "+370 657 99446", "jonas@euroliux.lt"],
        link:"www.euroliux.lt",
        location: "Panevėžio raj.",
        type: "Parduotuvė"
    }),
    new Representative({
        id:1008,
        name: "UAB Euroliuksas",
        contacts:["Žaltakalnio g. 20A, Plungė", "+370 657 99419", "jonas@euroliux.lt"],
        link:"www.euroliux.lt",
        location: "Plungės raj.",
        type: "Parduotuvė"
    }),
    new Representative({
        id:1009,
        name: "UAB Euroliuksas",
        contacts:["Daukanto g. 4C, Šiauliai", "+370 657 99404", "jonas@euroliux.lt"],
        link:"www.euroliux.lt",
        location: "Šiaulių raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1040,
        name: "UAB Semerita",
        contacts:["Ukmergės g. 285, Vilnius", "+370 685 22119", "tomas@muresta.lt"],
        link:"",
        location: "Vilniaus raj.",
        type: "Parduotuvė"
    }),



    new Representative({
        id:1058,
        name: "MB Smart Heating",
        contacts:["Vytauto Didžiojo g. 64, Kelmė","+370 624 55458", " info@smartheating.lt"],
        link:"",
        location: "Kelmės raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1002,
        name: "MB Santechnika plius",
        contacts:["Kalvarijų g. 151, Vilnius", "+370 655 75111", "info@katiluturgus.lt"],
        link:"www.katiluturgus.lt/aukstaitijos-katilai-3",
        location: "Vilniaus raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1038,
        name: "Prano Račinsko įmonė",
        contacts:["Lauko g. 1, Kupiškis", "+370 459 52246", "santechnikos.salonas@gmail.com"],
        link:"",
        location: "Kupiškio raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1041,
        name: "UAB Vilprava",
        contacts:["Liepų al. 3, Kėdainiai", "+370 698 22308", "vilprava@gmail.com"],
        link:"",
        location: "Kėdainių raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1047,
        name: "Valdo Ramoškos įmonė",
        contacts:["Putvinskio g. 1B, Kelmė", "+370 650 14840"],
        link:"www.valdosiluma.lt",
        location: "Kelmės raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1036,
        name: "IĮ Cezis",
        contacts:["Taikos g. 18, Rokiškis", "+370 645 85509", "cezis.rokiskis@gmail.com"],
        link:"",
        location: "Rokiškio raj.",
        type: "Parduotuvė"
    }),
    new Representative({
        id:1037,
        name: "IĮ Cezis",
        contacts:["Kauno g. 51, Ukmergė", "+370 645 85586", "cezis.ukmerge@gmail.com"],
        link:"",
        location: "Ukmergės raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1042,
        name: "UAB Būsto inžinieriniai sprendimai",
        contacts:["Širvintų g. 6, Ukmergė", "+370 671 14881", "kristina.bustoin@gmail.com"],
        link:"",
        location: "Ukmergės raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1065,
        name: "UAB Verslo stimulas",
        contacts:["Lentpjūvės g. 7, Plungė", "+370 448 56557", "verslostimulas@gmail.com"],
        link:"",
        location: "Plungės raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1035,
        name: "MB Namų centras",
        contacts:["Kudirkos g. 7, Šilalė", "+370 618 36846", "info@namucentras.lt"],
        link:"",
        location: "Šilalės raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1039,
        name: "IĮ Termista",
        contacts:["Geležinkelio g. 35, Ignalina", "+370 686 15954", "termista.sildymas@gmail.com"],
        link:"",
        location: "Ignalinos raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1045,
        name: "A.Ridiko IĮ MUKAS",
        contacts:["Statybininkų g. 6, Pakruojis", "+370 699 88844", "prekyba@mukas.lt"],
        link:"",
        location: "Pakruojo raj.",
        type: "Parduotuvė"
    }),

    new Representative({
        id:1046,
        name: "UAB Ulpas",
        contacts:["Kalniškės g. 40, Alytus", "+370 616 97533", "ulpas@ulpas.lt"],
        link:"",
        location: "Alytaus raj.",
        type: "Parduotuvė"
    }),

   



    new Representative({
        id:1003,
        name: "UAB Internetinė prekyba",
        // contacts:["+370 699 28337", "info@katilai.lt"],
        contacts:[""],
        link:"www.katilai.lt/katilai/kieto-kuro-katilai#attr%5B150%5D%5B%5D=111&a=150",
        location: "",
        type: "Elektroninė parduotuvė"
    }),

   
    new Representative({
        id:1010,
        name: "UAB Makveža",
        contacts:["Kauno g. 160, Marijampolės raj."],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Marijampolės raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1011,
        name: "UAB Makveža",
        contacts:["Santaikos g. 26B, Alytus"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Alytaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1012,
        name: "UAB Makveža",
        contacts:["Ateities g. 15, Vilnius"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Vilniaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1013,
        name: "UAB Makveža",
        contacts:["Raudondvario pl. 205, Kaunas"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Kauno raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1014,
        name: "UAB Makveža",
        contacts:["Vilkpėdės g. 12, Vilnius"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Vilniaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1015,
        name: "UAB Makveža",
        contacts:["Novočėbės k. 3, LT-57344, Kėdainių raj."],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Kėdainių raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1016,
        name: "UAB Makveža",
        contacts:["Skuodo g.41, Mažeikiai"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Mažeikių raj.",
        type: "Prekybos centras"
    }),

    new Representative({
        id:1017,
        name: "UAB Makveža",
        contacts:["Pilkalnio g.3, Vilnius"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Vilniaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1018,
        name: "UAB Makveža",
        contacts:["Šilutės pl. 83A, Klaipėda"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Klaipėdos raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1019,
        name: "UAB Makveža",
        contacts:["J. Basanavičiaus g. 6, Utena"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Utenos raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1020,
        name: "UAB Makveža",
        contacts:["Klaipėdos g. 170R, Panevėžys"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Panevėžio raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1021,
        name: "UAB Makveža",
        contacts:["Pergalės g. 36, Vilnius"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Vilniaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1022,
        name: "UAB Makveža",
        contacts:["Narsiečių km, Vytauto g. 183, Kauno raj."],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Kauno raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1023,
        name: "UAB Makveža",
        contacts:["Pramonės g. 7, Šiauliai"],
        link:"www.mokivezi.lt/sildymo-iranga/katilai?attributes[attribute_brand][]=AUK%C5%A0TAITIJOS%20KATILAI",
        location: "Šiaulių raj.",
        type: "Prekybos centras"
    }),

    new Representative({
        id:1024,
        name: "UAB Vilpra",
        contacts:["Perkūnkiemio g. 4, Vilnius, LT-12128", "+370 655 68311", "vilnius@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Vilniaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1025,
        name: "UAB Vilpra",
        contacts:["Kalvarijų g. 131, Vilnius, LT-08221", "+370 690 90614", "vilnius@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Vilniaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1026,
        name: "UAB Vilpra",
        contacts:["Savanorių pr. 176B, Vilnius, LT-03154", "+370 636 85953", "vilnius@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Vilniaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1027,
        name: "UAB Vilpra",
        contacts:["Pramonės pr. 8e, Kaunas, LT-51223", "+370 618 49698", "kaunas@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Kauno raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1028,
        name: "UAB Vilpra",
        contacts:["Raudondvario pl. 150, Kaunas, LT-47174", "+370 618 49698", "kaunas@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Kauno raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1029,
        name: "UAB Vilpra",
        contacts:["Minijos g. 42, Klaipėda, LT-91197", "+370 656 96121", "klaipeda@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Klaipėdos raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1030,
        name: "UAB Vilpra",
        contacts:["Šilutės pl. 27, Klaipėda, LT-91107", "+370 685 15625", "klaipeda@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Klaipėdos raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1031,
        name: "UAB Vilpra",
        contacts:["Pramonės g. 17b, Šiauliai, LT-78136", "+370 616 81279", "siauliai@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Šiaulių raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1032,
        name: "UAB Vilpra",
        contacts:["Elektronikos g. 1, Panevėžys, LT-35116", "+370 698 79357", "panevezys@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Panevėžio raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1033,
        name: "UAB Vilpra",
        contacts:["Santaikos g. 26D, Alytus, LT-62123", "+370 699 69513", "alytus@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Alytaus raj.",
        type: "Prekybos centras"
    }),
    new Representative({
        id:1034,
        name: "UAB Vilpra",
        contacts:["Kupiškio g. 19, Utena, LT-28136", "+370 656 95693", "utena@vilpra.lt"],
        link:"www.vilpra.lt/c/c?attributes%5Battribute_producerlabel%5D%5B%5D=Auk%C5%A1taitijos%20katilai&title=Auk%C5%A1taitijos%20katilai",
        location: "Utenos raj.",
        type: "Prekybos centras"
    }),



   
   
    
    
    
    
    

    new Representative({
        id:1048,
        name: "UAB Visi katilai",
        // contacts:["Raisteniškių g. 13-1, Vilniaus r.", "+370 614 33996"],
        contacts:[""],
        link:"www.visikatilai.lt/kieto-kuro-katilai/aukstaitijos-katilai",
        location: "Vilniaus raj.",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1049,
        name: "UAB Komeksimas",
        // contacts:["+370 655 42407"],
        contacts:[""],
        link:"www.b-a.eu/paieska.php?search_word=auk%C5%A1taitijos+katilai",
        location: "",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1050,
        name: "UAB Gedaleksas",
        contacts:[""],
        link:["www.22c.lt/?utm_source=aukstaitijoskatilai.lt&utm_medium=eshops+page&utm_campaign=supplier+source", "www.gerikatilai.lt/173-aukstaitijos-katilai-lietuva"],
        location: "",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1051,
        name: "UAB Tai ko reikia",
        contacts:[""],
        link:"www.taikoreikia.lt/sildymo-iranga/sildymo-katilai/kieto-kuro-katilai#/auk%C5%A1taitijos-katilai-lietuva-m60/sort=p.sort_order/order=ASC/limit=40",
        location: "",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1052,
        name: "UAB Technika Jums",
        // contacts:["+370 687 83178"],
        contacts:[""],
        link:"www.technikajums.lt/produktai/sildymo-sistemos/kieto-kuro-katilai-5-ta-klase/aukstaitijos-katilai/",
        location: "",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1053,
        name: "UAB Vandens ratas",
        contacts:[""],
        link:"www.tvaruskatilas.lt/manufacturer/aukstaitijos-katilai/",
        location: "",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1054,
        name: "Viktoro Adomaičio įmonė",
        // contacts:["+370 668 45150"],
        contacts:["info@sildymokatilas.lt"],
        link:"",
        location: "",
        type: "Atstovas"
    }),
    new Representative({
        id:1055,
        name: "MB Termeta",
        contacts:[""],
        link:"www.termeta.lt",
        location: "",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1056,
        name: "MB Margretos projektai",
        contacts:[""],
        link:"www.silumosbroliai.lt/gamintojas/aukstaitijos-katilai/",
        location: "",
        type: "Elektroninė parduotuvė"
    }),

    new Representative({
        id:1057,
        name: "MB Eurica",
        contacts:["+370 614 70979", "algimantassiaurukas@gmail.com"],
        link:"",
        location: "",
        type: "Atstovas"
    }),
  
    new Representative({
        id:1059,
        name: "UAB DEGUS",
        contacts:["+370 602 07868", "info@protingasiluma.lt"],
        link:"www.protingasiluma.lt/produkto-kategorija/granuliniai-katilai/aukstaitijos-katilai/",
        location: "",
        type: "Atstovas"
    }),
    new Representative({
        id:1060,
        name: "MB Thermitas",
        contacts:["+370 606 66600", "info@gkatilai.lt"],
        link:"",
        location: "",
        type: "Atstovas"
    }),
    new Representative({
        id:1061,
        name: "IĮ Utenos montuotojai",
        contacts:[""],
        link:"www.utenosmontuotojai.lt",
        location: "",
        type: "Atstovas"
    }),
    new Representative({
        id:1062,
        name: "UAB Santechnikos prekybos centras",
        // contacts:["+370 680 25842"],
        contacts:["remigijus@santechnikos-centras.lt"],
        link:"",
        location: "",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1063,
        name: "UAB REGEJUS",
        // contacts:["+370 602 25664"],
        contacts:[""],
        link:"www.regejus.lt",
        location: "",
        type: "Elektroninė parduotuvė"
    }),
    new Representative({
        id:1064,
        name: "IĮ Sanliva",
        contacts:[""],
        link:"www.sanliva.lt",
        location: "",
        type: "Elektroninė parduotuvė"
    }),

    // new Representative({
    //     id:10,
    //     name: "",
    //     contacts:[""],
    //     link:"",
    //     location: "",
    //     type: ""
    // }),
];