import { Katilas } from "./Katilas";
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { useLanguage } from './../LanguageContext';

function KatilasCard(props) {
    const { katilas } = props
    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;
    var tableColumns = 2
    var rows = []
    const linkDest = "/katilai/"+katilas.id
    const navigate = useNavigate()
    var key = 111

    if(katilas.certificate !== 0){
        tableColumns += 1
    }
    if(katilas.burnerWarranty !== 0){
        tableColumns += 1
    }
    var types1 = []
    var types2 = []
    if(katilas.fuelTypes.length >= 4){
        tableColumns += 1
        for(let i = 0; i < katilas.fuelTypes.length; i++){
            if(i < katilas.fuelTypes.length / 2){
                types1.push(katilas.fuelTypes[i])
            }else{
                types2.push(katilas.fuelTypes[i])
            }
        }
    }else{
        types1 = katilas.fuelTypes
    }

    var index = 0
    types1.forEach(element => {
        rows.push(
            <>
                <td className="Fuel1">{element}</td>
                {types2.length > index ? <td className="Fuel2">{types2[index]}</td> : ''}
                {types2.length <= index && types2.length > 0 ? <td className="Fuel2"></td> : ''}
                {index === 0 ? <>
                    {katilas.heatExchangerWarranty === 6 ? <td rowSpan={3} className="BigData"><img src={'../assets/6mgrantijasilum_' + language + '.svg'} alt='6msilum' style={{height: "50px"}}></img></td> : <></> }
                    {katilas.heatExchangerWarranty === 5 ? <td rowSpan={3} className="BigData"><img src={'../assets/5m_gar_silum_' + language + '.svg'} alt='5msilum' style={{height: "50px"}}></img></td> : <></> }
                    {katilas.heatExchangerWarranty === 4 ? <td rowSpan={3} className="BigData"><img src={'../assets/4m_gar_silum_' + language + '.svg'} alt='4msilum' style={{height: "50px"}}></img></td> : <></> }
                    {katilas.certificate === 5 ? <td rowSpan={3} className="BigData"><img src={'../assets/5klase_' + language + '.svg'} alt='5klase' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                    {katilas.burnerWarranty === 3 ? <td rowSpan={3} className="BigData"><img src={'../assets/3m_gar_degikliui_' + language + '.svg'} alt='3mgar' style={{height: "50px"}}></img></td> : <></> }
                    {katilas.burnerWarranty === 2 ? <td rowSpan={3} className="BigData"><img src={'../assets/2m_gar_degikliui_' + language + '.svg'} alt='2mgar' style={{height: "50px"}}></img></td> : <></> }
                </> : <></> }
            </>
        )
        index += 1
    });

    if(rows.length <= 2){
        rows.push(
            <>
                <td className="Fuel1">{"\u00A0"}</td>
                {types2.length > 0 ? <td className="Fuel2">{"\u00A0"}</td> : ""}
            </>
        )
    }

    return (
        <div className="card" key={katilas.id + "card"}>
            <img src={katilas.imageUrl} alt={katilas.name} onClick={() => navigate(linkDest)}/>
            <h5 className="feature">
                {katilas.feature}
            </h5>
            <h3 className="katilasName">
                {katilas.name}
            </h3>
            <p className="dataTop">
                <span className="dataTopType">{
                    Array.isArray(katilas.type) ? 
                        katilas.type.map((item, index) =>{
                            if(katilas.type[index+1]) return <span key={item + "spanextra"}><span key={item + "span"}>{item} </span><span>  /  </span></span>
                            return <span key={item + "span"}>{item} </span>
                        })
                    : katilas.type
                } {translation[12].text.toLowerCase()} <br/></span>
                <span className="dataTopSpecs">
                    {katilas.minPower}-{katilas.maxPower} kW&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{katilas.minArea}-{katilas.maxArea} m
                    <sup>2</sup>
                    {katilas.efficiencyClass === 'A++' || katilas.efficiencyClass === 'A+'? "\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0": ""}</span>
                
                {katilas.efficiencyClass === "A++" && 
                    <span className="dataTopClass" style={{color: "#096600"}}>{katilas.efficiencyClass} {translation[11].text}</span>
                }
                {katilas.efficiencyClass === "A+" && 
                    <span className="dataTopClass" style={{color: "#66A600"}}>{katilas.efficiencyClass} {translation[11].text}</span>
                }


                <span className="dataTopClass"></span>
            </p>
            <table className="mainFeaturesTable">
                <thead>
                    <tr>
                        <th className="Kuras">{translation[13].text}</th>
                        {katilas.fuelTypes.length >= 4 && <th></th>}
                        <th rowSpan={3}></th>
                        {tableColumns >= 4 && <th rowSpan={3}></th>}
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row => {
                        key += 1
                        return  <tr key={key}>{row}</tr>
                    })}
                </tbody>
            </table>
            <div className="cardDetailsButton"><button className="DetailsButton" onClick={() => navigate(linkDest)}>{translation[14].text}</button></div>
            
        </div>
    );
}

KatilasCard.propTypes = {
    katilas: PropTypes.instanceOf(Katilas).isRequired,
};

export default KatilasCard;