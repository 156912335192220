import { Katilas } from "./Katilas";

export const MOCK_KATILAI_PL = [
    new Katilas({
        id: 1,
        name: "Pellet Uni",
        type: "Kotły na pellet",
        imageUrl: "assets/pellet_uni.png",
        pjuvis: "assets/pellet_uni_pjuvis.png",
        feature: "Najwyższa klasa",
        price: 3400,
        pricing: [
            [12, 3400, 0, [0, 30, 80, 110]],
            [16, 3530, 0, [0, 30, 80, 110]],
            [20, 3650, 120, [0, 30, 80, 110]],
            [26, 4070, 160, [0, 30, 80, 110]],
            [36, 4330, 160, [0, 30, 80, 110]],
        ],
        minPower: 12,
        maxPower: 36,
        power: [12, 16, 20, 26, 36],
        minArea: 120,
        maxArea: 360,
        efficiencyClass: "A++",
        fuelTypes: ["Pellet", "Drewno", "Brykiety", "Węgiel"],
        heatExchangerWarranty: 6,
        certificate: 5,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 120, 160, 200, 260, 360],
            ["Głębokość paleniska", "mm", 455, 455, 455, 505, 505],
            ["Ładowanie paliwa", "l/dm\u00b3", 55, 55, 65, 84, 93],
            ["Pojemność zasobnika paliwa", "l/dm\u00b3", 230, 230, 250, 270, 270],
            ["Palnik obrotowy na pellet", "kW", "4-16", "4-16", "5-20", "6-26", "8-36"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 2.2, 2.6, 2.9, 3.4, 3.7],
            ["Wielkość otworu załadunku paliwa", "cm", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Liczba poziomych wymienników ciepła", "szt", 3, 4, 4, 4, 4],
            ["Objętość wody w kotle", "l", 59, 63, 68, 80, 85],
            ["Masa", "kg", 260, 280, 310, 360, 380],
            ["Wymagany ciąg w kominie", "Pa", 12, 13, 14, 15, 15],
        ],
        additionalData: [
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "90%"],
            ["Średnica komina wewnętrzna/zewnętrzna", "150/160 mm"],
            ["Wymiary przyłączy hydraulicznych", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
        ],
        selections: [
            ["Pojemność zasobnika paliwa", "230 l", "260 l", "320 l", "350 l"],
            [
                ["Palnik"],
                ["12", "4-16 kW"],
                ["16", "4-16 kW"],
                ["20", "4-16 kW", "5-20 kW"],
                ["26", "5-20 kW", "6-26 kW"],
                ["36", "6-26 kW", "8-36 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1150, 1260, 1260, 1260, 1260],
            ["B", "mm", 440, 440, 490, 540, 590],
            ["C", "mm", 640, 640, 640, 690, 690],
        ], 
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Moc", "10-36 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["pu1pl", "pu2pl", "pu3pl", "pu4pl", "pu5pl", "pu6pl", "pu7pl"],
        iconLocations: [[245, 150], [208, 350], [320, 380], [245, 150], [242, 330], [315, 70], [355, 170]],
        description: "Najwyższej klasy i najbardziej zaawansowany technicznie kocioł na pellet z czterodrożnym wymiennikiem ciepła i ceramicznym deflektorem. Żeliwny ruszt i ceramiczny deflektor wspomagają i zapewniają płynne spalanie. Dzięki temu kocioł osiąga sprawność przekraczającą 90%. Całkowicie automatyczny palnik obrotowy jest przeznaczony do spalania pelletu o niższej jakości i wyższej zawartości popiołu. Obrotowa komora spalania wspomaga całkowite spalanie paliwa i zapobiega osadzaniu się żużla. Sterownik kontroluje wszystkie procesy czyszczenia kotłowni i spalania w kotle.",
        burnerDescription: "Całkowicie automatyczny palnik do spalania pelletu dobrej i średniej jakości. Palnik jest wyposażony w automatyczny mechanizm czyszczący - obrotową komorę spalania i dopływu powietrza, która umożliwia samodzielne oczyszczanie się palnika z popiołu i żużla.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/kipi_palnik_pl.pdf",
        dimensionsImage: "../assets/pelletUniDimensions.svg",
        certificatePdf: "../assets/Bandymu sertifikatas Pellet Uni.pdf",
        instructionsPdf: "../assets/pellet_uni_instrukcija_pl.pdf", 
        techSpecPdf: "../assets/pellet_uni_pl.pdf",
        controllerInstructionsPdf: "../assets/Valdiklio Plum 920 instrukcija.pdf",
       
        connectionSchemePdf: "../assets/pelletUniPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 2,
        name: "Bio Kompakt",
        type: "Kotły na pellet",
        imageUrl: "assets/bio_kompakt.png",
        pjuvis: "assets/bio_kompakt_pjuvis.png",
        feature: "Kompaktowy",
        price: 3400,
        pricing:[
            [12, 3400, 0, [0]],
            [16, 3550, 0, [0]],
            [20, 3700, 0, [0]],
        ],
        minPower: 12,
        maxPower: 20,
        power: [12, 16, 20],
        minArea: 120,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Pellet"],
        heatExchangerWarranty: 6,
        burnerWarranty: 3,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 120, 160, 200],
            ["Pojemność zasobnika paliwa", "l/dm\u00b3", 120, 160, 160],
            ["Palnik obrotowy na pellet", "kW", "4-12", "4-16", "4-18"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 1.7, 2.0 , 2.4],
            ["Liczba poziomych wymienników ciepła", "szt", 2, 2, 2],
            ["Objętość wody w kotle", "l", 44, 50, 58],
            ["Masa", "kg", 155, 175, 195],
        ],
        additionalData: [
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "90%"],
            ["Średnica komina wewnętrzna/zewnętrzna", "130/140 mm"],
            ["Wymiary przyłączy hydraulicznych", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 470, 520, 570],
            ["B", "mm", 645, 695, 754],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Moc", "10-20 kW"],
        ],
        icons: ["bk1pl", "bk2pl", "bk3pl", "bk4pl", "bk5pl", "bk6pl", "bk7pl"],
        iconLocations: [[290, 386], [230, 360], [375, 380], [298, 325], [298, 325], [0, 0], [414, 345]],
        description: "Kompaktowy kocioł na pellet z ceramiczną komorą spalania, ceramicznym deflektorem i dużym wymiennikiem ciepła. Trwała ceramika wysokotemperaturowa zapewnia i wspomaga sprawny proces spalania. Dzięki temu kocioł osiąga sprawność przekraczającą 90%.",
        burnerDescription: "Całkowicie automatyczny palnik obrotowy jest przeznaczony do spalania pelletu o niższej jakości i wyższej zawartości popiołu. Obrotowa komora spalania wspomaga całkowite spalanie paliwa i zapobiega osadzaniu się żużla. Sterownik kontroluje wszystkie procesy czyszczenia kotłowni i spalania w kotle.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/kipi_palnik_pl.pdf",
        dimensionsImage: "../assets/bioKompaktDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos BIO KOMPAKT.pdf",
        instructionsPdf: "../assets/bio_kompakt_instrukcija_pl.pdf.pdf",
        techSpecPdf: "../assets/bio_kompakt_pl.pdf",
        controllerInstructionsPdf: "../assets/Valdiklio Plum 920 instrukcija.pdf",
        connectionSchemePdf: "../assets/bioKompaktPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 3,
        name: "Ekon Max",
        type: "Kotły na paliwo stałe",
        imageUrl: "assets/ekon_max.png",
        pjuvis: "assets/ekon_max_pjuvis.png",
        feature: "Wydajny",
        price: 1950,
        pricing:[
            [16, 1950, 0, [0]],
            [20, 2150, 0, [0]],
            [25, 2350, 0, [0]],
            [30, 2550, 0, [0]],
            [40, 2850, 0, [0]],
        ],
        minPower: 16,
        maxPower: 40,
        power: [16, 20, 25, 30, 40],
        minArea: 160,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Drewno", "Brykiety", "Trociny", "Węgiel"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 160, 200, 250, 300, 400],
            ["Ładowanie paliwa", "l/dm\u00b3", 105, 125, 140, 160, 192],
            ["Głębokość paleniska", "mm", 500, 500, 500, 500, 550],
            ["Wielkość otworu załadunku paliwa", "cm", "29x23", "34x23", "39x23", "44x23", "44x23"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 3.0, 3.4, 3.8, 4.2, 4.8],
            ["Liczba poziomych wymienników ciepła", "szt", 3, 3,3,3,3],
            ["Objętość wody w kotle", "l", 66,72,78,85,115],
            ["Masa", "kg", 230,260,290,320,350],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "150/160", "185/195", "185/195", "185/195", "185/195"],
        ],
        additionalData: [
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "85%"],
            ["Wymiary przyłączy hydraulicznych", "G 1 \u00b9\u{141F}\u00b2 colių"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", ">20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1200, 1200, 1200, 1200, 1300],
            ["B", "mm", 420, 470, 520, 570, 570],
            ["C", "mm", 810, 810, 810, 810, 860],
        ],
        icons: ["em1pl", "em2pl", "em3pl", "em4pl", "em5pl", "em6pl", "em7pl", "em8pl"],
        iconLocations: [[224, 380], [218, 328], [254, 320], [302, 154], [360, 183], [308, 254], [408, 435], [430, 240]],
        description: "Sprawdzona konstrukcja z podwójną komorą spalania i trzema poziomymi wymiennikami ciepła zapewnia najlepszą wydajność, łatwość konserwacji i długą żywotność. Kocioł doskonale nadaje się do spalania drewna i węgla. Maksymalna powierzchnia wymiennika ciepła i objętość wsadu zapewniają wysoką sprawność i bardzo długi czas spalania. Jest to kocioł o doskonałym stosunku ceny do wydajności.",
        dimensionsImage: "../assets/ekonMaxDimensions.svg",
        techSpecPdf: "../assets/ekon_max_pl.pdf",
        connectionSchemePdf: "../assets/ekonMaxPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON MAX.pdf",
        instructionsPdf: "../assets/ekon_max_instrukcija_pl.pdf",
    }),
    new Katilas({
        id: 4,
        name: "Ekon",
        type: "Kotły na paliwo stałe",
        imageUrl: "assets/ekon.png",
        pjuvis: "assets/ekon_pjuvis.png",
        feature: "Ekonomiczny",
        price: 1300,
        pricing:[
            [10, 1300, 0, [0]],
            [13, 1500, 0, [0]],
            [16, 1650, 0, [0]],
            [20, 1850, 0, [0]],
        ],
        minPower: 10,
        maxPower: 20,
        power: [10, 13, 16, 20],
        minArea: 100,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Drewno", "Brykiety", "Trociny", "Węgiel"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 100, 130, 160, 200],
            ["Ładowanie paliwa", "l/dm\u00b3", 65, 85, 100, 115],
            ["Głębokość paleniska", "mm", 400, 400, 400, 400],
            ["Wielkość otworu załadunku paliwa", "cm", "29x23", "29x23", "34x23", "39x23"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 1.9, 2.2, 2.6, 2.8],
            ["Liczba poziomych wymienników ciepła", "szt", 2,2,2,2],
            ["Objętość wody w kotle", "l", 41, 52, 56, 68],
            ["Masa", "kg", 160, 190, 210, 250],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "150/160", "150/160", "150/160", "185/195"],
        ],
        additionalData: [
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "83%"],
            ["Wymiary przyłączy hydraulicznych", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 910, 1100, 1100, 1150],
            ["B", "mm", 420, 420, 470, 520],
        ],
        icons: ["e1pl", "e2pl", "e3pl", "e4pl", "e5pl", "e6pl", "e7pl", "e8pl"],
        iconLocations: [[224, 360], [230, 316], [258, 318], [304, 126], [360, 150], [320, 210], [405, 435], [420, 220]],
        description: "Sprawdzona konstrukcja z podwójną komorą spalania i poziomymi wymiennikami ciepła zapewnia najlepszą wydajność, łatwość konserwacji i długą żywotność. Kocioł doskonale nadaje się do spalania drewna, brykietów i węgla. Duża powierzchnia wymiennika ciepła i objętość wsadu zapewniają wysoką sprawność, ekonomiczne spalanie i bardzo długi czas spalania. Jest to kocioł o doskonałym stosunku ceny do wydajności.",
        dimensionsImage: "../assets/ekonDimensions.svg",
        techSpecPdf: "../assets/ekon_pl.pdf",
        connectionSchemePdf: "../assets/ekonPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos EKON.pdf",
        instructionsPdf: "../assets/ekon_instrukcija_pl.pdf",
    }),
    new Katilas({
        id: 5,
        name: "Klasika",
        type: "Kotły na paliwo stałe",
        imageUrl: "assets/klasika.png",
        pjuvis: "assets/klasika_pjuvis.png",
        feature: "Prosta obsługa",
        price: 1050,
        pricing:[
            [8, 1050, 0, [0]],
            [10, 1150, 0, [0]],
            [13, 1300, 0, [0]],
            [16, 1500, 0, [0]],
            [20, 1650, 0, [0]],
            [25, 1800, 0, [0]],
            [30, 1950, 0, [0]],
        ],
        minPower: 8,
        maxPower: 30,
        power: [8, 10, 13, 16, 20, 25, 30],
        minArea: 80,
        maxArea: 300,
        efficiencyClass: "A+",
        fuelTypes: ["Drewno", "Brykiety", "Węgiel"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 80, 100, 130, 160, 200, 250, 300],
            ["Ładowanie paliwa", "l/dm\u00b3", 60, 72, 90, 105, 125, 140, 160],
            ["Głębokość paleniska", "mm", 400, 400, 500, 500, 500, 500, 500],
            ["Wielkość otworu załadunku paliwa", "cm", "24x23", "29x23", "29x23", "29x23", "34x23", "39x23", "44x23"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 1.3, 1.5, 1.8, 2.3, 2.5, 2.8, 3.1],
            ["Liczba poziomych wymienników ciepła", "szt", 2,2,2,3,3,3,3],
            ["Objętość wody w kotle", "l", 38,42,50,59,63,58,72],
            ["Masa", "kg", 135,150,170,200,225,250,275],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "150/160", "150/160", "150/160", "150/160", "185/195", "185/195", "185/195"],
            ["Wymiary przyłączy hydraulicznych", "colių", "G 1 \u00b9\u{141F}\u2074", "G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u2074","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2","G 1 \u00b9\u{141F}\u00b2"]
        ],
        additionalData: [
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "83%"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Reikalaujama trauka dūmtrukyje", "15-20 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000, 1000, 1000, 1200, 1200, 1200, 1200],
            ["B", "mm", 370, 420, 420, 420, 470, 520, 570],
            ["C", "mm", 570, 570, 670, 670, 670, 670, 670],
        ],
        icons: ["kl1pl", "kl2pl", "kl3pl", "kl4pl", "kl5pl"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Sprawdzona konstrukcja kotła - duża objętość wsadu i duży wymiennik ciepła. Zapewnia bardzo długi czas spalania. Kocioł doskonale nadaje się do spalania drewna i bardziej wilgotnych paliw stałych. Jest to niedrogi, niezawodny, prosty i sprawdzony kocioł.",
        dimensionsImage: "../assets/klasikaDimensions.svg",
        techSpecPdf: "../assets/klasika_pl.pdf",
        connectionSchemePdf: "../assets/klasikaPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KLASIKA.pdf",
        instructionsPdf: "../assets/klasika_instrukcija_pl.pdf",
    }),
    new Katilas({
        id: 6,
        name: "Kompakt",
        type: "Kotły na paliwo stałe",
        imageUrl: "assets/kompakt.png",
        pjuvis: "assets/kompakt_pjuvis.png",
        feature: "Niedrogi",
        price: 1100,
        pricing:[
            [12, 1100, 0, [0]],
            [16, 1250, 0, [0]],
            [20, 1400, 0, [0]],
        ],
        minPower: 12,
        maxPower: 20,
        power: [12, 16, 20],
        minArea: 120,
        maxArea: 200,
        efficiencyClass: "A+",
        fuelTypes: ["Drewno", "Brykiety", "Węgiel"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 120, 160, 200],
            ["Ładowanie paliwa", "l/dm\u00b3", 72, 90, 105],
            ["Głębokość paleniska", "mm", 400, 500, 500],
            ["Wielkość otworu załadunku paliwa", "cm", "29x23", "29x23", "29x23"],
            ["Liczba poziomych wymienników ciepła", "szt", 2,2,3],
            ["Objętość wody w kotle", "l", 42, 50, 59],
            ["Masa", "kg", 150, 170, 200],
            ["Wymiary przyłączy hydraulicznych", "coliai", "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u2074" , "G 1 \u00b9\u{141F}\u00b2"],
        ],
        additionalData: [
            ["Średnica komina wewnętrzna/zewnętrzna", "150/160 mm"],
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "83%"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Reikalaujama trauka dūmtrukyje", ">15 Pa"]
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1000,1000,1200],
            ["B", "mm", 570,670,670],
        ],
        icons: ["ko1pl", "ko2pl", "ko3pl", "ko4pl", "ko5pl"],
        iconLocations: [[296, 146], [350, 150], [296, 250], [390, 440], [400, 148]],
        description: "Kocioł na paliwo stałe o trwałej i niezawodnej konstrukcji, z dużym wymiennikiem ciepła i dużym wsadem paliwa. Charakteryzuje się prostą obsługą oraz długim czasem spalania. Czyszczenie kotła ułatwiają oddzielne drzwi. Kompaktowa konstrukcja umożliwia instalację kotła w ograniczonych przestrzeniach. Klapa odcinająca dopływ spalin została zaprojektowana z myślą o łatwym rozruchu i ograniczeniu możliwości przedostawania się dymu do pomieszczenia podczas załadunku paliwa. Kocioł jest wyposażony w podwójną klapę regulacyjną ciągu, solidne, łatwo otwierane drzwiczki oraz wytrzymałe żeliwne ruszty zapewniające lepsze spalanie paliwa.",
        dimensionsImage: "../assets/kompaktDimensions.svg",
        techSpecPdf: "../assets/kompakt_pl.pdf",
        connectionSchemePdf: "../assets/kompaktPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos KOMPAKT.pdf",
        instructionsPdf: "../assets/kompakt_instrukcija_pl.pdf",
    }),
    new Katilas({
        id: 7,
        name: "Ignis",
        type: "Kotły na paliwo stałe",
        imageUrl: "assets/ignis.png",
        pjuvis: "assets/ignis_pjuvis.png",
        feature: "Praktyczny",
        price: 1050,
        pricing:[
            [10, 1050, 0, [0]],
            [15, 1350, 0, [0]],
        ],
        minPower: 10,
        maxPower: 15,
        power: ["M 10", "D 15"],    
        minArea: 100,
        maxArea: 150,
        efficiencyClass: "A+",
        fuelTypes: ["Drewno", "Brykiety", "Węgiel"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 100, 150],
            ["Ładowanie paliwa", "l/dm\u00b3", 70, 90],
            ["Głębokość paleniska", "mm", 300, 400],
            ["Wielkość otworu załadunku paliwa", "cm", "34x26", "34x26"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 1.2, 1.8],
            ["Vertikalių šilumokaičių kiekis", "szt", 1, 2],
            ["Objętość wody w kotle", "l", 32, 42],
            ["Masa", "kg", 130, 190],
            ["Sprawność grzewcza", "%", 76, 78],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "130/140", "150/160"]
        ],
        additionalData: [
            ["Wymiary przyłączy hydraulicznych", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", "15-20 Pa"], 
        ],
        selections: [
            [""],
            [""],
        ],
        icons: ["i1pl", "i2pl", "i3pl", "i4pl", "i5pl"],
        iconLocations: [[233, 190], [434, 260], [350, 220], [290, 240], [430, 204]],
        description: "Tradycyjny piec kotłowy. Sprawdzony produkt. Kocioł ma bardzo dużą objętość wsadu drewna i duży wymiennik ciepła. Konstrukcja zapewnia wysoką wydajność i długi czas spalania. Zastosowanie wysokiej jakości, certyfikowanej blachy stalowej o grubości 5 milimetrów zapewnia długą żywotność. Doskonała jakość wykonania i sprawdzona konstrukcja jest objęta 4-letnią gwarancją. Kocioł może być używany w trybie letnim i zimowym oraz może być wyposażony w automatyczny regulator ciągu. Kocioł jest wyposażony w wysokiej jakości żeliwną płytę grzewczą, odwracalne drzwiczki i wygodny mechaniczny regulator ciągu.",
        dimensionsImage: "../assets/ignisDimensions.svg",
        techSpecPdf: "../assets/ignis_pl.pdf",
        connectionSchemePdf: "../assets/ignisPaleidimoSchema.pdf",
        certificatePdf: "../assets/EU atitikties deklaracijos IGNIS.pdf",
        instructionsPdf: "../assets/ignis_instrukcija_pl.pdf",
    }),
    new Katilas({
        id: 8,
        name: "Agro Uni",
        type: "Kotły na odpady rolnicze",
        imageUrl: "assets/agro_uni.png",
        pjuvis: "assets/agro_uni_pjuvis.png",
        feature: "Ekologiczne",
        price: 2750,
        pricing:[
            [15, 2750, 0, [0,250]],
            [20, 3100, 0, [0,250]],
            [30, 3500, 0, [0,350]],
            [40, 4000, 0, [0,350]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15, 20, 30, 40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Zboża", "Śruta", "Pellet", "Zrębki", "Węgiel 0-50", "Drewno"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 150, 200, 300, 400],
            ["Głębokość paleniska", "mm", 450, 450, 500, 600],
            ["Ładowanie paliwa", "l/dm\u00b3", 35, 40, 65, 104],
            ["Palnik „Zenono“", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 1.9, 2.5, 3.3, 4.2],
            ["Wielkość otworu załadunku paliwa", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Liczba poziomych wymienników ciepła", "szt", 3, 4, 4, 4],
            ["Objętość wody w kotle", "l", 48, 62, 69, 100],
            ["Masa", "kg", 180, 230, 270, 310],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "150/160", "150/160", "150/160", "185/195"],
        ],
        additionalData: [
            ["Zasobnik paliwa z mieszadłem", "400/600 l/dm\u00b3"],
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "90%"],
            ["Wymiary przyłączy hydraulicznych", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", "15-20 Pa"]
        ],
        selections: [
            ["Pojemność zasobnika paliwa", "400 l", "600 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Moc", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["au1pl", "au2pl", "au3pl", "au4pl", "au5pl", "au6pl", "au7pl"],
        iconLocations: [[240, 350], [240, 350], [240, 190], [240, 190], [235, 320], [410, 316], [320, 210]],
        description: "Niezwykle wszechstronny kocioł na odpady rolnicze zdolny do automatycznego spalania szerokiej gamy zbóż, odpadów rolniczych, niskiej jakości peletów, zrębków, trocin oraz węgla, drewna i innych paliw stałych, które są ładowane przez środkowe drzwi. Specjalny mechanizm mieszania w zasobniku paliwa, samoczyszczący palnik, duży popielnik i inne rozwiązania zapewniają sprawne spalanie różnych paliw i bezproblemową pracę kotła. Duży poziomy czterodrożny wymiennik ciepła zapewnia proste czyszczenie i wysoką sprawność kotła. Kocioł jest wyposażony w palnik Zenono. Nie zalecamy wyposażania kotła w przewód spalin ze stali nierdzewnej.",
        burnerDescription: "Specjalistyczny palnik zdolny do automatycznego spalania szerokiej gamy zbóż, odpadów rolniczych, niskiej jakości pelletu i różnego węgla. Palnik jest wyposażony w mechanizm czyszczący ze stali nierdzewnej, który doskonale usuwa żużel powstający podczas spalania.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroUniDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO UNI.pdf",
        instructionsPdf: "../assets/agro_uni_instrukcija_pl.pdf",
        techSpecPdf: "../assets/agro_uni_pl.pdf",
        controllerInstructionsPdf: "../assets/IE-70_pl.pdf",
        connectionSchemePdf: "../assets/agroUniPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 9,
        name: "Universa",
        type: ["Kotły na pellet", "Kotły na odpady rolnicze"],
        imageUrl: "assets/universa.png",
        pjuvis: "assets/universa_pjuvis.png",
        feature: "Populiarus",
        price: 2550,
        pricing:[
            [15, 2550, 0, [0, 50]],
            [20, 2900, 0, [0, 50]],
            [30, 3300, 0, [0, 50]],
            [40, 3850, 0, [0, 50]],
        ],
        minPower: 15,
        maxPower: 40,
        power: [15,20,30,40],
        minArea: 150,
        maxArea: 400,
        efficiencyClass: "A+",
        fuelTypes: ["Pellet", "Zboża", "Torf", "Granulat ze słomy", "Granulat słonecznikowy", "Węgiel 0-50"],
        heatExchangerWarranty: 5,
        burnerWarranty: 3,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 150,200,300,400],
            ["Głębokość paleniska", "mm", 450,450,500,600],
            ["Ładowanie paliwa", "l/dm\u00b3", 35,40,65,104],
            ["Palnik „Zenono“", "kW", "3-15", "3-20", "4-30", "8-50"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 1.9,2.5,3.3,4.2],
            ["Wielkość otworu załadunku paliwa", "cm", "29x23", "34x23", "44x23", "49x23"],
            ["Liczba poziomych wymienników ciepła", "szt", 3, 4, 4,4],
            ["Objętość wody w kotle", "l", 48,62,69,100],
            ["Masa", "kg", 180,230,270,320],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "150/160","150/160","150/160","185/195"],
        ],
        additionalData: [
            ["Pojemność zasobnika paliwa", "230/300 l/dm\u00b3"],
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "90%"],
            ["Średnica komina wewnętrzna/zewnętrzna", "150/160 mm"],
            ["Wymiary przyłączy hydraulicznych", "G 1 \u00b9\u{141F}\u2074 colių"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", "15-20 Pa"]
        ],
        selections: [
            ["Pojemność zasobnika paliwa", "200 l", "300 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1100, 1200, 1200, 1280],
            ["B", "mm", 420, 470, 570, 620],
            ["C", "mm", 670, 670, 700, 800],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Moc", "15-50 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["u1pl", "u2pl", "u3pl", "u4pl", "u5pl", "u6pl"],
        iconLocations: [[240, 350], [240, 350], [246, 192], [246, 192], [234, 320], [330, 200]],
        description: "Niezwykle wszechstronny kocioł na pellet z automatyczną obsługą do spalania pelletu dobrej, jak i niskiej jakości, a także wszystkich zbóż, odpadów rolniczych, torfu i węgla, drewna lub innych paliw stałych, które można załadować przez środkowe drzwi. Niedrogi, niezawodny i wszechstronny kocioł na pellet, specjalnie zaprojektowany do spalania różnych paliw. Duży poziomy, czterodrożny wymiennik ciepła zapewnia proste czyszczenie i wysoką wydajność. Kocioł jest wyposażony w palnik „Zenono“.",
        burnerDescription: "Specjalistyczny palnik zdolny do automatycznego spalania szerokiej gamy zbóż, odpadów rolniczych, niskiej jakości pelletu i różnego węgla. Palnik jest wyposażony w mechanizm czyszczący ze stali nierdzewnej, który doskonale usuwa żużel powstający podczas spalania.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/universaDimensions.svg",
        talpa: ['UT 200', 'UT 300'],
        talpaData:[
            ["A1", "mm", 1200, 1300],
            ["B1", "mm", 600, 750]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos UNIVERSA.pdf",
        controllerInstructionsPdf: "../assets/IE-70_pl.pdf",
        techSpecPdf: "../assets/universa_pl.pdf",
        connectionSchemePdf: "../assets/universaPaleidimoSchema.pdf",
        instructionsPdf: "../assets/universa_instrukcija_pl.pdf",
    }),
    new Katilas({
        id: 10,
        name: "Pele Max",
        type: "Kocioł przemysłowy",
        imageUrl: "assets/pelle_max.png",
        pjuvis: "assets/pelle_max_pjuvis.png",
        feature: "Ekonomiczny",
        price: 6140,
        pricing:[
            [50, 6140, 460, [0, 100, 300]],
            [70, 7600, 900, [0, 100, 300]],
            [100, 10000, 700, [0, 100, 300]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50,70,100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Pellet, Drewno", "Brykiety", "Trociny", "Węgiel"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 500,700,1000],
            ["Głębokość paleniska", "mm", 680,680,980],
            ["Ładowanie paliwa", "l/dm\u00b3", 105,145,280],
            ["Palnik obrotowy na pellet", "kW", "10-50", "15-70", "20-100"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 6, 7.8, 11.8],
            ["Wielkość otworu załadunku paliwa", "cm", "50x26", "60x26", "70x26"],
            ["Liczba poziomych wymienników ciepła", "szt", 5,5,5],
            ["Objętość wody w kotle", "l", 142,180,280],
            ["Masa", "kg", 500,650,890],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "185/198", "185/195", "200/210"],
        ],
        additionalData: [
            ["Pojemność zasobnika paliwa", "500/700/1000 l/dm\u00b3"],
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "90%"],
            ["Wymiary przyłączy hydraulicznych", "G 2 colių"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", ">20 Pa"]
        ],
        selections: [
            ["Pojemność zasobnika paliwa", "500 l", "700 l", "1000 l"],
            [
                ["Palnik"],
                ["50", "8-36 kW", "10-50 kW"],
                ["70", "10-50 kW", "15-70 kW"],
                ["100", "15-70 kW", "20-100 kW"],
            ]
        ],
        dimensions: [
            ["A", "mm", 1585, 1585, 1735],
            ["A1", "mm", 1275, 1355, 1430],
            ["B", "mm", 630, 730, 830],
            ["B1", "mm", 650, 750, 850],
            ["C", "mm", 890, 890, 1195],
            ["C1", "mm", 265, 340, 340],
        ],
        degiklis: "„Kipi Rot Power“",
        degiklisData: [
            ["Moc", "36-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["pm1pl", "pm2pl", "pm3pl", "pm4pl", "pm5pl"],
        iconLocations: [[242, 162], [252, 320], [310, 380], [235, 286], [370, 190]],
        description: "Wielofunkcyjny przemysłowy kocioł na pellet, wyposażony w nowoczesny i wysokiej jakości palnikiem obrotowy, przystosowany do spalania pelletu niższej jakości. Jest to wszechstronny kocioł, który może spalać zarówno pellet w trybie w pełni automatycznym, jak i szeroką gamę paliw stałych ładowanych ręcznie. Kocioł posiada jeden z największych na rynku wymienników ciepła. Pięć poziomych wymienników ciepła, wiele przełożeń i długi ciąg spalin zapewniają bardzo wysoką sprawność kotła.",
        burnerDescription: "Całkowicie automatyczny palnik do spalania pelletu dobrej i średniej jakości. Palnik jest wyposażony w automatyczny mechanizm czyszczący - obrotową komorę spalania i dopływu powietrza, która umożliwia samodzielne oczyszczanie się palnika z popiołu i żużla.",
        burnerImage: "../assets/kipiRotPower.png",
        burnerInstructionsPdf: "../assets/kipi_palnik_pl.pdf",
        dimensionsImage: "../assets/peleMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos PELE MAX.pdf",
        instructionsPdf: "../assets/pele_max_instrukcija_pl.pdf",
        controllerInstructionsPdf: "../assets/Valdiklio Plum 920 instrukcija.pdf",
        techSpecPdf: "../assets/pele_max_pl.pdf",
        connectionSchemePdf: "../assets/peleMaxPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 11,
        name: "Agro Max",
        type: ["Kotły na odpady rolnicze", "Kocioł przemysłowy"],
        imageUrl: "assets/agro_max.png",
        pjuvis: "assets/agro_max_pjuvis.png",
        feature: "Wszechstronny",
        price: 5700,
        pricing:[
            [50, 5700, 0, [0,200,400]],
            [70, 6900, 0, [0,200,400]],
            [100, 8500, 300, [0,200,400]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A",
        fuelTypes: ["Zboża", "Śruta", "Pellet", "Zrębki", "Węgiel 0-50", "Drewno"],
        heatExchangerWarranty: 5,
        burnerWarranty: 2,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 500, 700, 1000],
            ["Głębokość paleniska", "mm", 680, 680, 980],
            ["Ładowanie paliwa", "l/dm\u00b3", 140, 170, 290],
            ["Palnik „Zenono“", "kw", "15-70", "15-70", "20-100"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 5.9, 7.1, 11],
            ["Wielkość otworu załadunku paliwa", "cm", "50x34", "60x34", "70x34"],
            ["Liczba poziomych wymienników ciepła", "szt", 5, 5, 5],
            ["Objętość wody w kotle", "l", 145, 165, 280],
            ["Masa", "kg", 550, 650, 930],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "185/195", "185/195", "200/210"],
            ["Pojemność zasobnika paliwa", "l/dm\u00b3", 400, 600, 800]
        ],
        additionalData: [
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "90%"],
            ["Wymiary przyłączy hydraulicznych", "G2 coliai"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", ">20 Pa"],
        ],
        selections: [
            ["Pojemność zasobnika paliwa", "400 l", "600 l", "800 l"],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1590, 1590, 1740],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 890, 890, 1190],
        ],
        degiklis: "„Zenono“",
        degiklisData: [
            ["Moc", "50-100 kW"],
            // ["Degimo temperatūra", "850 \u2070C"],
            // ["Dydis", "890 x 380 x 450 mm"],
            // ["Jungtis", "D90 mm"],
        ],
        icons: ["am1pl", "am2pl", "am3pl", "am4pl", "am5pl", "am6pl"],
        iconLocations: [[270, 350], [270, 350], [450, 306], [274, 306], [280, 162], [380, 274]],
        description: "Niezwykle wszechstronny kocioł do użytku przemysłowego, przeznaczony do automatycznego spalania szerokiej gamy zbóż, odpadów rolniczych, peletów niskiej jakości i różnych rodzajów węgla. Jest to produkt najwyższej kategorii i zaawansowania technicznego, specjalnie zaprojektowany do spalania takich paliw. Duży zasobnik z mechanizmem mieszającym, samoczyszczący się palnik, duży popielnik i inne zalety sprawiają, że kocioł jest łatwy w obsłudze. Duży poziomy, pięciodrożny wymiennik ciepła zapewnia proste czyszczenie i wysoką sprawność. Kocioł jest również przystosowany do spalania drewna i innych paliw stałych, które są ładowane przez środkowe drzwi. Kocioł jest wyposażony w palnik „Zenono“.",
        burnerDescription: "Specjalistyczny palnik zdolny do automatycznego spalania szerokiej gamy zbóż, odpadów rolniczych, niskiej jakości pelletu i różnego węgla. Palnik jest wyposażony w mechanizm czyszczący ze stali nierdzewnej, który doskonale usuwa żużel powstający podczas spalania.",
        burnerImage: "../assets/zenonoDegiklis.png",
        dimensionsImage: "../assets/agroMaxDimensions.svg",
        talpa: ['AT 400', 'AT 600', 'AT 800'],
        talpaData:[
            ["A1", "mm", 1500, 1700, 1700],
            ["B1", "mm", 1080, 1080, 1190]
        ],
        certificatePdf: "../assets/EU atitikties deklaracijos AGRO MAX.pdf",
        instructionsPdf: "../assets/agro_max_instrukcija_pl.pdf",
        techSpecPdf: "../assets/agro_max_pl.pdf",
        controllerInstructionsPdf: "../assets/IE-70_pl.pdf",
        connectionSchemePdf: "../assets/agroMaxPaleidimoSchema.pdf",
    }),
    new Katilas({
        id: 12,
        name: "Bio Max",
        type: "Kocioł przemysłowy",
        imageUrl: "assets/bio_max.png",
        pjuvis: "assets/bio_max_pjuvis.png",
        feature: "Praktyczny",
        price: 3500,
        pricing:[
            [50, 3500, 0, [0]],
            [70, 4500, 0, [0]],
            [100, 5800, 0, [0]],
        ],
        minPower: 50,
        maxPower: 100,
        power: [50, 70, 100],
        minArea: 500,
        maxArea: 1000,
        efficiencyClass: "A+",
        fuelTypes: ["Drewno", "Brykiety", "Węgiel"],
        heatExchangerWarranty: 4,
        tableData: [
            ["Ogrzewana powierzchnia", "do m\u00b2", 500, 700, 1000],
            ["Ładowanie paliwa", "l/dm\u00b3", 240, 380, 550],
            ["Głębokość paleniska", "mm", 600, 800, 1000], 
            ["Wielkość otworu załadunku paliwa", "cm", "40x50", "40x60", "40x70"],
            ["Powierzchnia wymiennika ciepła", "m\u00b2", 5, 7.1, 10],
            ["Liczba poziomych wymienników ciepła", "szt", 4, 4, 4],
            ["Objętość wody w kotle", "l", 130, 180, 235],
            ["Masa", "kg", 420, 570, 800],
            ["Średnica komina wewnętrzna/zewnętrzna", "mm", "200/210", "220/230", "250/260"],
        ],
        additionalData: [
            ["Wymiary przyłączy hydraulicznych", "G 2 coliai"],
            ["Minimalna temperatura robocza kotła", "60 \u2070C"],
            ["Maksymalna temperatura robocza kotła", "90 \u2070C"],
            ["Sprawność grzewcza", "83%"],
            ["Maksymalna ciśnienie robocze", "1.5 bar"],
            ["Wymagany ciąg w kominie", ">20 Pa"],
        ],
        selections: [
            [""],
            [""],
        ],
        dimensions: [
            ["A", "mm", 1600, 1600, 1600],
            ["B", "mm", 630, 730, 830],
            ["C", "mm", 800, 1000, 1200],
        ],
        icons: ["bm1pl", "bm2pl", "bm3pl", "bm4pl", "bm5pl"],
        iconLocations: [[312, 152], [300, 280], [414, 440], [370, 150], [440, 280]],
        description: "Oparty na tradycyjnym projekcie, niezawodny i łatwy w obsłudze kocioł przemysłowy. Charakteryzuje się również bardzo dużym wsadem paliwa, dużym wymiennikiem ciepła, długą żywotnością i niską ceną. Kocioł ma jedną z największych objętości wsadu i powierzchni wymiennika ciepła na rynku. Zapewnia to bardzo długi czas spalania, a kocioł doskonale nadaje się do spalania drewna i bardziej wilgotnych paliw.",
        dimensionsImage: "../assets/bioMaxDimensions.svg",
        certificatePdf: "../assets/EU atitikties deklaracijos BIO MAX.pdf",
        techSpecPdf: "../assets/bio_max_pl.pdf",
        connectionSchemePdf: "../assets/bioMaxPaleidimoSchema.pdf",
        instructionsPdf: "../assets/bio_max_instrukcija_pl.pdf",
    }),
];