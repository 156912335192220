import { Katilas } from "./Katilas";
import React from 'react';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import UsefulInfo from "../footer/usefulInfo";
import Location from "../footer/location";
import { useState } from "react";
import HeaderBigBlack from '../header/headerBigBlack';
import HeaderBlack from '../header/headerBlack';
import { Link } from "react-router-dom";
import { useLanguage } from './../LanguageContext';
import ImageMagnifier from "../ImageMagnifier";
import Dropdown from 'react-bootstrap/Dropdown';

function KatilasDetails(props){
    const { katilai } = props
    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;
    //TODO suziuret images kai atsius nes db nenoriu
    const ekoImage = language === 'lt' ? '../assets/eko_dizainas.svg' : '../assets/eco_design.svg';
    const params = useParams();
    var listKey = 11111
    const katilas = new Katilas(katilai[params.id-1])
    const linkDest = 
        (katilas.type === "Granulinio kuro" && "/katilai_granulinio") || 
        (katilas.type === "Kieto kuro" && "/katilai_kieto") || 
        (katilas.type === "Žemės ūkio atliekų" && "/katilai_zemes_ukio") || 
        (katilas.type === "Pramoninis" && "/katilai_pramoniniai")
    const [activeButton, setActiveButton] = useState(1)
    const [activeOrder, setActiveOrder] = useState(false)
    const [orderComplete, setOrderComplete] = useState(false)
    const [clickedFormField, setClickedFormField] = useState()
    const [activeIcon, setActiveIcon] = useState(0)
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [[x, y], setXY] = useState([0, 0]);
    const [powerSelected, setPowerSelected] = useState(false);
    const [disableBurner, setDisableBurner] = useState(false);
    const [selectionPrice, setSelectionPrice] = useState(katilas.price);
    const [displayImage, setDisplayImage] = useState(1);
    const requiredSelections = ["galia"];

    if(katilas.selections[0][0] === "Kuro talpa"){
        requiredSelections.push("kuroTalpa")
    }
    if(katilas.selections[1][0][0] === "Degiklis"){
        requiredSelections.push("degiklis")
    }

    function returnKatilasTypeLink(type){
        return (type === "Granulinio kuro" && "/katilai_granulinio") || 
        (type === "Kieto kuro" && "/katilai_kieto") || 
        (type === "Žemės ūkio atliekų" && "/katilai_zemes_ukio") || 
        (type === "Pramoninis" && "/katilai_pramoniniai")
    }

    // TODO make this change with different inputs
    const [formData, setFormData] = useState(
        {
            // galia: katilas.power[0],
            galia: "",
            kuroTalpa: "",
            degiklis: "",
            vardas: "",
            elPastas: "",
            kompanija: "",
            telNr: "",
            zinute: "",
            sutikimas: "",
        }
    )

    if(katilas.selections[1][0][0] === "Degiklis"){
        var iters = 0
        katilas.selections[1].forEach(element => {
            if(iters > 0){
                if(element[0] === formData.galia){
                    if(element.length === 2 && disableBurner === false){
                        setDisableBurner(true)
                    }else if(element.length === 3 && disableBurner === true){
                        setDisableBurner(false)
                    }
                }
            }
            iters += 1
        });
    }

    //using this to do nothing in certain places
    const emptyFunction = () => {

    }

    const changeImage = () => {
        if(displayImage === 1){
            setDisplayImage(2)
        }else{
            setDisplayImage(1)
        }
    }

    const [errors, setErrors] = useState(
        {
            vardas: '',
            elPastas: '',
            telNr: '',
            sutikimas: '',
            selects: '',
        }
    )

    const handleIconChange = (icon) => {
        if(icon !== 0){
            setActiveIcon(icon)
            if(katilas.iconLocations[icon-1][0] === 0 && katilas.iconLocations[icon-1][1] === 0){
                setShowMagnifier(false)
            }else{
                setShowMagnifier(true)
            }
            setXY([katilas.iconLocations[icon-1][0], katilas.iconLocations[icon-1][1]])
        }else{
            setActiveIcon(0)
            setShowMagnifier(false)
        }
        
    }

    const [mobileInformation, setmobileInformation] = React.useState(false);
    function mobileInformationToggle(){
        setmobileInformation(!mobileInformation);
    }

    const validate = (fieldsToValidate) => {
        var updatedErrors
        fieldsToValidate.forEach((fieldToValidate) => {
            const type = document.getElementsByName(fieldToValidate)[0].type
            const value = type === 'checkbox' ? document.getElementsByName(fieldToValidate)[0].checked : document.getElementsByName(fieldToValidate)[0].value
            // const regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
            const regName = /^([^0-9]*)$/;

            if(fieldToValidate === "vardas"){
                let change
                if(value.length < 2){
                    change = {
                        [fieldToValidate]: translation[92].text,
                    }
                }else if (!regName.test(value)){
                    change = {
                        [fieldToValidate]: translation[93].text,
                    }
                }else{
                    change = {
                        [fieldToValidate]: '',
                    }
                }
                updatedErrors = { ...updatedErrors, ...change}
                // setErrors((old) => {
                //     updatedErrors = { ...old, ...change}
                //     return updatedErrors
                // })
            }
    
            if(fieldToValidate === "elPastas"){
                let change
                var input = document.createElement('input');
                input.type = 'email';
                input.required = true;
                input.value = value;

                if(value.length < 3){
                    change = {
                        [fieldToValidate]: translation[94].text,
                    }
                }else if(!input.checkValidity()){
                    change = {
                        [fieldToValidate]: translation[95].text,
                    }
                }else{
                    change = {
                        [fieldToValidate]: '',
                    }
                }
                updatedErrors = { ...updatedErrors, ...change}
                // setErrors((old) => {
                //     updatedErrors = { ...old, ...change}
                //     return updatedErrors
                // })
            }
    
            if(fieldToValidate === "telNr"){
                let change
                const strippedString = value.replace(/\D/g,'');
                if(value.length < 6){
                    change = {
                        [fieldToValidate]: translation[96].text,
                    }
                }else if(strippedString.length < 5 || strippedString.length > 15){
                    change = {
                        [fieldToValidate]: translation[97].text,
                    }
                }else{
                    change = {
                        [fieldToValidate]: '',
                    }
                }
                updatedErrors = { ...updatedErrors, ...change}
                // setErrors((old) => {
                //     updatedErrors = { ...old, ...change}
                //     return updatedErrors
                // })
            }
    
            if(fieldToValidate === "sutikimas"){
                let change
                if(value !== true){
                    change = {
                        [fieldToValidate]: translation[98].text,
                    }
                }else{
                    change = {
                        [fieldToValidate]: '',
                    }
                }
                updatedErrors = { ...updatedErrors, ...change}
                // setErrors((old) => {
                //     updatedErrors = { ...old, ...change}
                //     return updatedErrors
                // })
            }
        })

        setErrors((old) => {
            let withAllUpdates = { ...old, ...updatedErrors}
            return withAllUpdates
        }, )

        return updatedErrors
    }

    useEffect(() => {
        const htmlElement = document.querySelector("html");
        htmlElement.style.scrollBehavior = 'auto';
        window.scrollTo(0, 0);
        htmlElement.style.scrollBehavior = 'smooth';
      }, [])
    useEffect(() =>{
        if(activeOrder){
            let ele = document.getElementById("orderScrollTo");   
            window.scrollTo(ele.offsetLeft,ele.offsetTop-80);
        }
    }, [activeOrder])

    const handleInputChange = (event) => {
        
        const { type, name, value, checked } = event.target
        //console.log(type, name, value, checked);
        let updatedValue = type === 'checkbox' ? checked : value
        var errChange, updatedErrors
        if(type === 'checkbox' && checked === true){
            errChange = {
                "sutikimas": '',
            }   
        }else if(checked === false){
            errChange = {
                "sutikimas": translation[98].text,
            }
        }
        if(type === 'checkbox'){
            setErrors((old) => {
                updatedErrors = { ...old, ...errChange}
                return updatedErrors
            })
        }

        if (type === "Number") {
            updatedValue = Number(updatedValue)
        }

        const change = {
            [name]: updatedValue,
        }

        let updatedFormData

        setFormData((old) => {
            updatedFormData = { ...old, ...change}
            //kai pasirenka galia ir katilas gali turet degikli - parenka pirma degikli
            if(updatedFormData.galia !== "" && powerSelected === false){
                setPowerSelected(true)

                if(katilas.selections[1][0][0] === "Degiklis"){
                    var iters = 0
                    katilas.selections[1].forEach(element => {
                        if(iters > 0){
                            if(element[0] === updatedFormData.galia){
                                updatedFormData.degiklis = element[1]
                                // console.log(updatedFormData.degiklis)
                            }
                        }
                        iters += 1
                    });
                }

                if(katilas.selections[0][0] === "Kuro talpa"){
                    updatedFormData.kuroTalpa = katilas.selections[0][1]
                }

            }

            //impossible errors
            if(updatedFormData.galia !== "" && powerSelected === false){
                setErrors((old) => {
                    let updatedErrors = { ...old, ...{"selects": ""}}
                    // console.log(updatedErrors)
                    return updatedErrors
                })
            }else if(powerSelected === true){
                setErrors((old) => {
                    let updatedErrors = { ...old, ...{"selects": ""}}
                    // console.log(updatedErrors)
                    return updatedErrors
                })
            }

            //jei atsinaujino galia - atnaujina degikli ir suskaiciuoja kaina
            if(updatedFormData.galia !== formData.galia){

                if(katilas.selections[1][0][0] === "Degiklis"){
                    iters = 0
                    katilas.selections[1].forEach(element => {
                        if(iters > 0){
                            if(element[0] === updatedFormData.galia){
                                updatedFormData.degiklis = element[1]
                            }
                        }
                        iters += 1
                    });
                }

                // console.log(updatedFormData.galia, updatedFormData.kuroTalpa, updatedFormData.degiklis)
                katilas.pricing.forEach(line => {
                    if(Number(updatedFormData.galia) === line[0]){
                        if(updatedFormData.kuroTalpa === "" && updatedFormData.degiklis === ""){
                            setSelectionPrice(line[1])
                        }else if(updatedFormData.degiklis !== ""){
                            katilas.pricing.forEach((line, index) => {
                                if(Number(updatedFormData.galia) === line[0]){
                                    setSelectionPrice(line[1] + (katilas.selections[1][index+1].indexOf(updatedFormData.degiklis) === 1 ? 0 : line[2]) + line[3][katilas.selections[0].indexOf(updatedFormData.kuroTalpa)-1])
                                }
                            });
                        }else{
                            katilas.pricing.forEach((line, index) => {
                                if(Number(updatedFormData.galia) === line[0]){
                                    setSelectionPrice(line[1] + line[3][katilas.selections[0].indexOf(updatedFormData.kuroTalpa)-1])
                                }
                            });
                        }
                        // console.log(line[1])
                    }
                });
            //jei galia ta pati bet pakeista kuro talpa - 
            }else if(updatedFormData.kuroTalpa !== formData.kuroTalpa){

                if(updatedFormData.degiklis !== ""){
                    katilas.pricing.forEach((line, index) => {
                        if(Number(updatedFormData.galia) === line[0]){
                            setSelectionPrice(line[1] + (katilas.selections[1][index+1].indexOf(updatedFormData.degiklis) === 1 ? 0 : line[2]) + line[3][katilas.selections[0].indexOf(updatedFormData.kuroTalpa)-1])
                        }
                    });
                }else{
                    katilas.pricing.forEach((line, index) => {
                        if(Number(updatedFormData.galia) === line[0]){
                            setSelectionPrice(line[1] + line[3][katilas.selections[0].indexOf(updatedFormData.kuroTalpa)-1])
                        }
                    });
                }

            }else if(updatedFormData.degiklis !== formData.degiklis){

                katilas.pricing.forEach((line, index) => {
                    if(Number(updatedFormData.galia) === line[0]){
                        setSelectionPrice(line[1] + (katilas.selections[1][index+1].indexOf(updatedFormData.degiklis) === 1 ? 0 : line[2]) + line[3][katilas.selections[0].indexOf(updatedFormData.kuroTalpa)-1])
                    }
                });

            }

            return updatedFormData
        })
    }

    const handleFormSubmit = () => {
        let msg = []
        let names = ["vardas", "elPastas", "telNr", "sutikimas"]
        for (const [, value] of Object.entries(formData)){
            msg.push(value)
        }
        const newErrors = validate(names)
        const isOk = newErrors.vardas.length === 0 && newErrors.elPastas.length === 0 && newErrors.telNr.length === 0 && newErrors.sutikimas.length === 0
        // let selectErrors = 0

        // requiredSelections.forEach(sel => {
        //     if(formData[sel] === ""){
        //         selectErrors += 1
        //     }
        // });
        // console.log(selectErrors)

        const selectOk = powerSelected === true
        // console.log(selectOk)

        if(!selectOk){
            setErrors((old) => {
                let updatedErrors = { ...old, ...{"selects": translation[105].text}}
                // console.log(updatedErrors)
                return updatedErrors
            })
        }

        // console.log(errors)

        if(isOk && selectOk){
            // console.log(msg)

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = mm + '/' + dd + '/' + yyyy;

            //TODO check if theres still bs here with state not updating (cringe)
            const objectToEmail = {
                date: today,
                vardas: formData.vardas,
                katilas: katilas.name,
                galia: formData.galia,
                talpa: formData.kuroTalpa,
                degiklis: formData.degiklis,
                elPastas: formData.elPastas,
                kompanija: formData.kompanija,
                telNr: formData.telNr,
                zinute: formData.zinute,
            }

            // console.log(objectToEmail)e

            // emailjs.send('default_service','template_h9wwjbb', objectToEmail, 'adrGEUlwnE4cpz5kM')
            //     .then((response) => {
            //     console.log('SUCCESS!', response.status, response.text);
            //     setOrderComplete(true);
            //     }, (err) => {
            //     console.log('FAILED...', err);
            //     });
            fetch(`${process.env.REACT_APP_SERVER_API_URL}/post/email`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(objectToEmail)
              })
                .then(res => res.json())
                .then(
                  (result) => {
                    setOrderComplete(true);
                    console.log(result);
                    const htmlElement = document.querySelector("html");
                    htmlElement.style.scrollBehavior = 'auto';
                    window.scrollTo(0, 0);
                    htmlElement.style.scrollBehavior = 'smooth';
                  },
                  // Note: it's important to handle errors here
                  // instead of a catch() block so that we don't swallow
                  // exceptions from actual bugs in components.
                  (error) => {
                    console.log('FAILED...', error);
                  }
                )
            
        }
    }

    const handleFieldClick = (event) => {
        const { name } = event.target
        if(clickedFormField == null){
            setClickedFormField(name)
            return
        }
        if(clickedFormField === name){
            return
        }
        const fieldToValidate = clickedFormField
        setClickedFormField(name)
        validate([fieldToValidate])
    }

    const uniqueItems1 = []
    uniqueItems1.push(katilas.tableData[3][0])
    uniqueItems1.push(katilas.tableData[3][1])

    katilas.tableData[3].map((a, key) => {
        if (key > 1 && uniqueItems1.indexOf(a) === -1) uniqueItems1.push(a)
        return null
    })

    const uniqueItems2 = []
    uniqueItems2.push(katilas.tableData[4][0])
    uniqueItems2.push(katilas.tableData[4][1])

    katilas.tableData[4].map((a, key) => {
        if (key > 1 && uniqueItems2.indexOf(a) === -1) uniqueItems2.push(a) 
        return null
    })

    return (
        <>
        
            <HeaderBigBlack activeCategory={katilas.type}/>
            <div className='smallAboutHeader'><HeaderBlack/></div>
        
        
        {!orderComplete ?<section className="DetailsSection">
            {/* <div className="NavAndEff">
                <nav className={["Nav", activeOrder === true ? " order_nav" : ""].join('')}>
                    
                    <p>
                        <span><Link to={"/"}>{translation[0].text}</Link></span>
                        <span className="nav_underline" style={{borderBottom: "1px solid #a7a7a7", display: "inline-block", margin: "0px 1px 0px 1px", position: "relative", top: "6px"}}/>
                        {
                            Array.isArray(katilas.type) ? 
                            katilas.type.map((item, index) =>{
                                if(katilas.type[index+1]) return <><span><Link to={returnKatilasTypeLink(item)}>{item} {translation[0].text.toLowerCase()} </Link></span><span>  /  </span></>
                                return <span><Link to={returnKatilasTypeLink(item)}>{item} {translation[0].text.toLowerCase()} </Link></span>
                            })
                            : <span><Link to={linkDest}>{katilas.type} {translation[0].text.toLowerCase()}</Link></span>
                        }
                        <span className="nav_underline" style={{borderBottom: "1px solid #a7a7a7", display: "inline-block", margin: "0px 1px 0px 1px", position: "relative", top: "6px"}}/>
                        <span><Link to={"/katilai/"+katilas.id}>{katilas.name}</Link></span>
                        {activeOrder === true ? 
                        <>
                            <span className="nav_underline" style={{borderBottom: "1px solid #a7a7a7", display: "inline-block", margin: "0px 1px 0px 1px", position: "relative", top: "6px"}}/>
                            <span><Link style={{color: "#e15303"}}>{translation[100].text}</Link></span>
                        </> : null}
                    </p>
                </nav>
                <nav className="Nav Mobile_order_nav">
                    <p>
                        {activeOrder === true ? 
                            <span><Link style={{color: "#e15303"}}>{translation[100].text}</Link></span> : null}
                    </p>
                </nav>
            </div> */}
            <div className="Wrapper" id="KatilasWID">
                <div className={activeOrder ? 'DetailsLeft OrderOpen' : 'DetailsLeft'}>
                    <div className="MainInfo">
                        <h5 className="feature">
                            {katilas.feature}
                        </h5>
                        <h3 className="katilasName">
                            {katilas.name}
                        </h3>
                        <p className="dataTop">{
                            Array.isArray(katilas.type) ? 
                            katilas.type.map((item, index) =>{
                                if(katilas.type[index+1]) return <><span key={item + "span"}>{item} </span><span>  /  </span></>
                                return <span key={item + "span"}>{item} </span>
                            })
                            : katilas.type
                        } {translation[12].text} <br/></p>
                    </div>
                    <div className="katilasImageDiv">
                        {/* {katilas.icons.map((_, index) => {
                            return  <div key={index} className={["Circle", activeIcon === index+1 ? "ActiveCircle" : null].join(' ')} style={{top: katilas.iconLocations[index][0]+"px", left: katilas.iconLocations[index][1]+"%"}} onMouseEnter={() => {setActiveIcon(index+1)}}>+</div>
                        })} */}
                        <button className="changeImage changeImageLeft" onClick={() => changeImage()}>
                            <div className="arrow left"></div>
                        </button>
                        <button className="changeImage changeImageRight" onClick={() => changeImage()}>
                            <div className="arrow right"></div>
                        </button>
                        <img className="katilasImage mobileImage" src={displayImage === 1 ? "../"+katilas.pjuvis : "../"+katilas.imageUrl.substring(0, 7)+"cropped_"+katilas.imageUrl.substring(7)} alt={katilas.name}/>
                        <ImageMagnifier src={displayImage === 1 ? "../"+katilas.pjuvis : "../"+katilas.imageUrl.substring(0, 7)+"cropped_"+katilas.imageUrl.substring(7)} width={"628px"} height={"500px"} className="katilasImage desktopImage" showMagnifier={showMagnifier} setShowMagnifier={setShowMagnifier} x={x} y={y} setXY={setXY} ></ImageMagnifier>
                    </div>
                    <p className="ImgNumber">{translation[50].text} {displayImage} {translation[107].text} {2}</p>
                    <div className={activeOrder === true ? "IconsDiv iconsDivDown" : "IconsDiv"} onMouseLeave={() => {handleIconChange(0)}}>
                        {
                        katilas.icons.map((icon, index) => {
                            return  <div className="IconBox" key={index} onMouseEnter={() => {handleIconChange(index+1)}}>
                                        <img className={["Icon", activeIcon === index+1 ? "filter-orange" : null].join(' ')} src={"../assets/katilo_ikonos/"+icon+".svg"} alt={index}/>
                                    </div>
                        })}
                    </div>
                </div>
                
                {activeOrder === false ? <div className="DetailsRight">
                    <table className="DetailsBigData">
                        <tbody>
                            <tr style={{position: "relative"}}>
                            {katilas.efficiencyClass === "A++" && 
                                <td className="BigData" style={{border: "none", "paddingLeft": "0px", minWidth: "206px"}}>
                                    <span className="EffClassBig" style={{color: "#096600"}}>
                                        <img src="../assets/Apliusplius.svg" alt="A++"></img>    
                                    </span>
                                    <span className="EffClassText">{translation[15].text}</span>
                                </td>
                            }
                            {katilas.efficiencyClass === "A+" && 
                                <td className="BigData" style={{border: "none", "paddingLeft": "0px", minWidth: "206px"}}>
                                    <span className="EffClassBig" style={{color: "#66A600"}}>
                                        <img src="../assets/Aplius.svg" alt="A+"></img>  
                                    </span>
                                    <span className="EffClassText">{translation[15].text}</span>
                                </td>
                            }
                            {katilas.efficiencyClass === "A" && 
                                <td className="BigData" style={{border: "none", "paddingLeft": "0px", minWidth: "206px"}}>
                                    <span className="EffClassBig" style={{color: "#66A600"}}>
                                        <img src="../assets/A.svg" alt="A"></img>  
                                    </span>
                                    <span className="EffClassText">{translation[15].text}</span>
                                </td>
                            }
                            <td className="BigData" style={{"paddingLeft": "15px", "paddingRight": "15px", minWidth:'auto'}}>
                                        <img style={{height:'50px'}} src={ekoImage} alt="eko"></img>  
                            </td>
                                {/*<td className="BigData"><span className="BigNumber">{katilas.heatExchangerWarranty}</span> <span className="BigText">metų</span> <span className="SmallText">garantija šilumokaičiui</span></td>
                                {katilas.certificate !== 0 ? <td className="BigData"><span className="BigNumber">{katilas.certificate}</span> <span className="BigText">klasė</span> <span className="SmallText">sertifikuota Lietuvoje</span></td> : ""}
                                {katilas.burnerWarranty !== 0 ? <td className="BigData"><span className="BigNumber">{katilas.burnerWarranty}</span> <span className="BigText">metų</span> <span className="SmallText">garantija degikliui</span></td> : ""}
                                */}
                                        {katilas.heatExchangerWarranty === 6 && katilas.efficiencyClass ? <td className="BigData"><img src={'../assets/6mgrantijasilum_' + language + '.svg'} alt='6msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.heatExchangerWarranty === 5 && katilas.efficiencyClass ? <td className="BigData"><img src={'../assets/5m_gar_silum_' + language + '.svg'} alt='5msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.heatExchangerWarranty === 4 && katilas.efficiencyClass ? <td className="BigData"><img src={'../assets/4m_gar_silum_' + language + '.svg'} alt='4msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.certificate === 5 && katilas.efficiencyClass ? <td className="BigData"><img src={'../assets/5klase_' + language + '.svg'} alt='5klase' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.burnerWarranty === 3 && katilas.efficiencyClass ? <td className="BigData"><img src={'../assets/3m_gar_degikliui_' + language + '.svg'} alt='3mgar' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.burnerWarranty === 2 && katilas.efficiencyClass ? <td className="BigData"><img src={'../assets/2m_gar_degikliui_' + language + '.svg'} alt='2mgar' style={{height: "50px", width: "105px"}}></img></td> : <></> }

                                        {/* doubled to get rid of left border */}
                                        {katilas.heatExchangerWarranty === 6 && !katilas.efficiencyClass ? <td className="BigData BigDataNoEff"><img src={'../assets/6mgrantijasilum_' + language + '.svg'} alt='6msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.heatExchangerWarranty === 5 && !katilas.efficiencyClass ? <td className="BigData BigDataNoEff"><img src={'../assets/5m_gar_silum_' + language + '.svg'} alt='5msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.heatExchangerWarranty === 4 && !katilas.efficiencyClass ? <td className="BigData BigDataNoEff"><img src={'../assets/4m_gar_silum_' + language + '.svg'} alt='4msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.certificate === 5 && !katilas.efficiencyClass ? <td className="BigData BigDataNoEff"><img src={'../assets/5klase_' + language + '.svg'} alt='5klase' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.burnerWarranty === 3 && !katilas.efficiencyClass ? <td className="BigData BigDataNoEff"><img src={'../assets/3m_gar_degikliui_' + language + '.svg'} alt='3mgar' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                        {katilas.burnerWarranty === 2 && !katilas.efficiencyClass ? <td className="BigData BigDataNoEff"><img src={'../assets/2m_gar_degikliui_' + language + '.svg'} alt='2mgar' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                            </tr>
                        </tbody>
                    </table>
                    <table className="KuroTipai">
                        <tbody>
                            <tr style={{flexDirection:'row'}}>
                                {katilas.efficiencyClass === "A++" && 
                                    <td className="BigDataMobile" style={{border: "none", "paddingLeft": "0px", minWidth: "186px"}}>
                                        <span className="EffClassBigMobile" style={{color: "#096600"}}>
                                            <img src="../assets/Apliusplius.svg" alt="A++"></img>          
                                        </span>
                                        <span className="EffClassTextMobile">{translation[15].text}</span>
                                    </td>
                                }
                                {katilas.efficiencyClass === "A+" && 
                                <td className="BigDataMobile" style={{border: "none", "paddingLeft": "0px", minWidth: "186px"}}>
                                    <span className="EffClassBigMobile" style={{color: "#66A600"}}>
                                        <img src="../assets/Aplius.svg" alt="A+"></img>      
                                    </span>
                                    <span className="EffClassTextMobile">{translation[15].text}</span>
                                </td>
                                }
                                {katilas.efficiencyClass === "A" && 
                                <td className="BigDataMobile" style={{border: "none", "paddingLeft": "0px", minWidth: "186px"}}>
                                    <span className="EffClassBigMobile" style={{color: "#66A600"}}>
                                        <img src="../assets/A.svg" alt="A"></img>  
                                    </span>
                                    <span className="EffClassTextMobile">{translation[15].text}</span>
                                </td>
                                }
                                <td className="BigDataMobile" style={{border: "none", "paddingLeft": "0px"}}>
                                <img style={{height:'50px'}} src={ekoImage} alt="eko"></img>
                                </td> 
                            </tr>
                            <hr/>
                            <tr>
                                <td style={{fontSize: "16px", fontWeight: "600", paddingRight: "30px"}}>{translation[13].text}</td>
                                
                                    {katilas.fuelTypes.map((type) => {
                                        if(type === katilas.fuelTypes[0]) {
                                            return <td key={type+"1"} style={{padding: "0px 10px 0px 10px"}}>{type}</td>
                                        }else{
                                            return <td key={type+"2"} style={{borderLeft: "1px solid black", padding: "0px 10px 0px 10px"}}>{type}</td>
                                        }
                                    })}
                                
                            </tr>
                            <hr/>
                            <tr >
                                <td className="MobileDataMore">
                                    {katilas.heatExchangerWarranty === 6 ? <img src={'../assets/6mgrantijasilum_' + language + '.svg'} alt='6msilum' style={{height: "50px", width: "105px"}}></img> : <></> }
                                    {katilas.heatExchangerWarranty === 5 ? <img src={'../assets/5m_gar_silum_' + language + '.svg'} alt='5msilum' style={{height: "50px", width: "105px"}}></img> : <></> }
                                    {katilas.heatExchangerWarranty === 4 ? <img src={'../assets/4m_gar_silum_' + language + '.svg'} alt='4msilum' style={{height: "50px", width: "105px"}}></img> : <></> }
                                    {katilas.certificate === 5 ? <><hr/><img src={'../assets/5klase_' + language + '.svg'} alt='5klase' style={{height: "50px", width: "105px"}}></img></> : <></> }
                                    {katilas.burnerWarranty === 3 ? <><hr/><img src={'../assets/3m_gar_degikliui_' + language + '.svg'} alt='3msilum' style={{height: "50px", width: "105px"}}></img></> : <></> }
                                    {katilas.burnerWarranty === 2 ? <><hr/><img src={'../assets/2m_gar_degikliui_' + language + '.svg'} alt='2msilum' style={{height: "50px", width: "105px"}}></img></> : <></> }
                                </td>
                            </tr>
                            <tr><td><hr/></td></tr>
                        </tbody>
                    </table>
                    <table className="tableWho">
                        <thead>
                            <tr>
                                <th className="DataTableHeader" colSpan={2} style={{borderRight: "1px solid gray", textAlign: "left"}}>{translation[16].text}</th>
                                {katilas.power.map((pow) => {
                                    return <th key={pow} className="DataTableHeader Powers" style={{textAlign: "start"}}>{pow} kW{katilas.name === "Pellet Uni" && pow == 36 ? "*" : null}
                                    </th>})}
                            </tr>
                        </thead>
                        <tbody>
                            {katilas.tableData.map((dataRow, key) => {
                                let tableDatas = []

                                for(let i = 2; i < dataRow.length; i += 1){
                                    if(key === 0){
                                        tableDatas.push(<td key={listKey} className="TableSpecs" style={{paddingTop: "8px"}}>{dataRow[i]}</td>)
                                        listKey += 1
                                    }else{
                                        tableDatas.push(<td key={listKey} className="TableSpecs">{dataRow[i]}</td>)
                                        listKey += 1
                                    }
                                }
                                
                                if(key === 0){
                                    return <tr key={listKey}>
                                        <td key={dataRow[0]+"3"} className="GrayTableData" style={{paddingTop: "8px", width: "300px"}}>{dataRow[0]}</td>
                                        <td key={dataRow[1]+"4"} className="GrayTableData" style={{borderRight: "1px solid gray", padding: "16px 30px 0px 24px"}}>{dataRow[1]}</td>
                                        {tableDatas}
                                    </tr>
                                }else{
                                    return <tr key={listKey}>
                                        <td className="GrayTableData">{dataRow[0]}</td>
                                        <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "0px 30px 0px 24px"}}>{dataRow[1]}</td>
                                        {tableDatas}
                                    </tr>
                                }
                            })}
                        </tbody>
                    </table>
                    <div className="BetweenTables"></div>
                    <table className="AdditionalDataTable">
                        <tbody>
                        {katilas.additionalData.map((line) => {
                            return <tr key={line[0]+"6"}>
                                <td key={line[0]+"1"} className="GrayTableData" style={{"width": "310px"}}>{line[0]}</td>
                                <td key={line[0]+"2"} className="GrayTableData" style={{"paddingLeft": "14px"}}>{line[1]}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    <div className="informationButtonMobile">
                        <button onClick={mobileInformationToggle}>{translation[6].text} {mobileInformation === true ?<span><i className="arrow up"></i></span> : <span><i className="arrow down"></i></span>}</button>
                    </div>
                    {mobileInformation === true ? 
                    <div className="informacijaMobile">
                            <div className="ExtraAprašymas">
                                <h3>{katilas.name}</h3>
                                <p>
                                    {katilas.description}
                                </p>
                            </div>
                            <hr/>
                            <div className="ExtraMatmenys">
                                <h3>{translation[23].text}</h3>
                                <img src={katilas.dimensionsImage} alt="Matmenys"></img>
                                {(Array.isArray(katilas.dimensions) && katilas.dimensions.length) ? <table style={{borderCollapse: "collapse"}}>
                                    <thead>
                                        <tr>
                                            <th className="DataTableHeader" colSpan={2} style={{borderRight: "1px solid gray", textAlign: "left"}}>{translation[26].text}</th>
                                            {katilas.power.map((pow) => {return <th key={pow} className="DataTableHeader" style={{paddingLeft: "14px"}}>{pow} kW{katilas.name === "Pellet Uni" && pow == 36 ? "*" : null}</th>})}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {katilas.dimensions.map((dataRow, key) => {
                                            let tableDatas = []

                                            for(let i = 2; i < dataRow.length; i += 1){
                                                if(key === 0){
                                                    listKey += 1
                                                    tableDatas.push(<td key={listKey} style={{paddingTop: "16px", paddingLeft: "14px"}}>{dataRow[i]}</td>)
                                                }else{
                                                    listKey += 1
                                                    tableDatas.push(<td key={listKey} style={{paddingLeft: "14px"}}>{dataRow[i]}</td>)
                                                }
                                            }
                                            
                                            if(key === 0){
                                                return <tr key={key}>
                                                    <td className="GrayTableData" style={{paddingTop: "16px"}}>{dataRow[0]}</td>
                                                    <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "16px 30px 0px 5px"}}>{dataRow[1]}</td>
                                                    {tableDatas}
                                                </tr>
                                            }else{
                                                return <tr key={key}>
                                                    <td className="GrayTableData">{dataRow[0]}</td>
                                                    <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "0px 30px 0px 5px"}}>{dataRow[1]}</td>
                                                    {tableDatas}
                                                </tr>
                                            }
                                        })}
                                    </tbody>
                                </table> : null}
                                {(Array.isArray(katilas.talpa) && katilas.talpa.length) ? <table className="talpaTableMobile" style={{borderCollapse: "collapse"}}>
                                    <thead>
                                        <tr>
                                            <th className="DataTableHeader" colSpan={2} style={{borderRight: "1px solid gray", textAlign: "left"}}>Talpa</th>
                                            {katilas.talpa.map((pow) => {return <th key={pow} className="DataTableHeader" style={{minWidth: "80px", paddingLeft: "14px"}}>{pow}</th>})}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {katilas.talpaData.map((dataRow, key) => {
                                            let tableDatas = []

                                            for(let i = 2; i < dataRow.length; i += 1){
                                                if(key === 0){
                                                    listKey += 1
                                                    tableDatas.push(<td key={listKey} style={{paddingTop: "8px", paddingLeft: "14px"}}>{dataRow[i]}</td>)
                                                }else{
                                                    listKey += 1
                                                    tableDatas.push(<td key={listKey} style={{paddingLeft: "14px"}}>{dataRow[i]}</td>)
                                                }
                                            }
                                            
                                            if(key === 0){
                                                return <tr key={key}>
                                                    <td className="GrayTableData" style={{paddingTop: "8px"}}>{dataRow[0]}</td>
                                                    <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "16px 30px 0px 5px"}}>{dataRow[1]}</td>
                                                    {tableDatas}
                                                </tr>
                                            }else{
                                                return <tr key={key}>
                                                    <td className="GrayTableData">{dataRow[0]}</td>
                                                    <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "0px 30px 0px 5px"}}>{dataRow[1]}</td>
                                                    {tableDatas}
                                                </tr>
                                            }
                                        })}
                                    </tbody>
                                </table> : ""}
                            </div>
                            {katilas.degiklis === "" ? 
                            <>
                            <hr/>
                             <div className="ExtraDegiklis">
                                <h3>{translation[24].text} {katilas.degiklis}</h3>
                                <p>{katilas.burnerDescription}</p>
                                <div className="DegiklisData">
                                    <table className="AdditionalDataTable">
                                        <tbody>
                                            {katilas.degiklisData.map((line) => {
                                                return <tr key={line}>
                                                    <td key={line[0]+"1"} className="GrayTableData">{line[0]}</td>
                                                    <td key={line[0]+"2"} className="GrayTableData" style={{paddingLeft: "8px"}}>{line[1]}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div></> : null}
                    </div> :null}
                    <div className="kataloguButtons">
                        {katilas.certificatePdf ?   <><a className="katalogasButton" href={katilas.certificatePdf} download><img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img>
                        {katilas.id === 1 ? translation[19].text : translation[119].text}</a>
                        <a className="katalogasButton" href={katilas.instructionsPdf} download><img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img>{translation[18].text}</a></> : null}
                        <a className="katalogasButton" href={'../assets/katalogas_' + language + '.pdf'} download><img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img>{translation[17].text}</a>
                        <a className="katalogasButton" id='techButton' href={katilas.techSpecPdf} download><img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img>{translation[20].text}</a>
                    </div>
                    {katilas.name === "Pellet Uni" ? <div className="pelletUniStarInfo">{translation[121].text}</div>: null}
                    {
                        activeOrder === false ? <div className="Price">
                            <div>
                                <p style={{position: "relative", top: 0}}>{translation[21].text} {translation[32].text.toLowerCase()}</p>
                                <h2 style={{position: "relative", fontSize: "50px", fontWeight: "1200", marginBottom: "40px"}}>{katilas.price} <span style={{fontSize: "26px", position: "relative", bottom: "20px"}}> {translation[122].text}</span></h2>
                                {/* <p style={{position: "relative", left: "136px", bottom: "66px", fontSize: "26px", width: "40px"}}>Eur</p> */}
                            </div>
                            <button className="DetailsButton" onClick={() => {
                                setActiveOrder(true)
                                // let ele = document.getElementById("#orderScrollTo");   
                                // window.scrollTo(ele.offsetLeft,ele.offsetTop);
                                //window.scrollTo(0, 360)
                            }}
                            style={{position: "relative", bottom: "34px"}}>{translation[33].text}</button>
                            <div className="link-div">
                                <Link to='/atstovai' className='OrangeLink'>{translation[34].text} &gt;</Link>
                            </div>
                        </div>
                        : null
                    }
                </div> : <div className="OrderRight" id="orderScrollTo">
                    <div style={{height: "170px"}}>
                        <table className="DetailsBigData">
                            <tbody>
                                <tr style={{position: "relative"}}>
                                {katilas.efficiencyClass === "A++" && 
                                    <td className="BigData" style={{border: "none", "paddingLeft": "0px", minWidth: "200px"}}>
                                        <span className="EffClassBig" style={{color: "#096600"}}>
                                            <img src="../assets/Apliusplius.svg" alt="A++"></img>      
                                        </span>
                                        <span className="EffClassText">{translation[15].text}</span>
                                    </td>
                                }
                                {katilas.efficiencyClass === "A+" && 
                                    <td className="BigData" style={{border: "none", "paddingLeft": "0px", minWidth: "200px"}}>
                                        <span className="EffClassBig" style={{color: "#66A600"}}>
                                            <img src="../assets/Aplius.svg" alt="A+"></img>      
                                        </span>
                                        <span className="EffClassText">{translation[15].text}</span>
                                    </td>
                                }
                                {katilas.efficiencyClass === "A" && 
                                <td className="BigData" style={{border: "none", "paddingLeft": "0px", minWidth: "206px"}}>
                                    <span className="EffClassBig" style={{color: "#66A600"}}>
                                        <img src="../assets/A.svg" alt="A"></img>  
                                    </span>
                                    <span className="EffClassText">{translation[15].text}</span>
                                </td>
                                }

                                    {/*<td className="BigData"><span className="BigNumber">{katilas.heatExchangerWarranty}</span> <span className="BigText">metų</span> <span className="SmallText">garantija šilumokaičiui</span></td>
                                    {katilas.certificate !== 0 ? <td className="BigData"><span className="BigNumber">{katilas.certificate}</span> <span className="BigText">klasė</span> <span className="SmallText">sertifikuota Lietuvoje</span></td> : ""}
                                    {katilas.burnerWarranty !== 0 ? <td className="BigData"><span className="BigNumber">{katilas.burnerWarranty}</span> <span className="BigText">metų</span> <span className="SmallText">garantija degikliui</span></td> : ""}
                                    */}
                                            {katilas.heatExchangerWarranty === 6 ? <td className="BigData"><img src={'../assets/6mgrantijasilum_' + language + '.svg'} alt='6msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                            {katilas.heatExchangerWarranty === 5 ? <td className="BigData"><img src={'../assets/5m_gar_silum_' + language + '.svg'} alt='5msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                            {katilas.heatExchangerWarranty === 4 ? <td className="BigData"><img src={'../assets/4m_gar_silum_' + language + '.svg'} alt='4msilum' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                            {katilas.certificate === 5 ? <td className="BigData"><img src={'../assets/5klase_' + language + '.svg'} alt='5klase' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                            {katilas.burnerWarranty === 3 ? <td className="BigData"><img src={'../assets/3m_gar_degikliui_' + language + '.svg'} alt='3mgar' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                            {katilas.burnerWarranty === 2 ? <td className="BigData"><img src={'../assets/2m_gar_degikliui_' + language + '.svg'} alt='2mgar' style={{height: "50px", width: "105px"}}></img></td> : <></> }
                                </tr>
                            </tbody>
                        </table>
                        <div className="MainInfo form900info">
                            <h5 className="feature">
                                {katilas.feature}
                            </h5>
                            <h3 className="katilasName">
                                {katilas.name}
                            </h3>
                            <p className="dataTop">{
                                Array.isArray(katilas.type) ? 
                                katilas.type.map(item =>{
                                return <span>{item} </span>
                                })
                                : katilas.type
                            } {translation[12].text} <br/></p>
                        </div>
                        <button className="CloseOrderButton" onClick={() => {setActiveOrder(false)}}>
                            <p>{translation[35].text}</p>
                            <img style={{"paddingLeft": "5px", "height": "54px", "width": "32px"}}alt="X" src="../assets/iksiukasB.png"></img>
                        </button>
                    </div>
                    <div className="OrangePDiv">
                        <img className="circleExclamationBigger" alt="!" src="../assets/circle-exclamation-solid.svg"></img>
                        <p className="FormOrangeP">{translation[41].text}.</p>
                    </div>
                    <form>
                        <div className="FormSelects" style={{display: "flex", flexDirection: "column"}}>
                            {/* <select value={formData.galia} onChange={handleInputChange} name="galia">
                                <option value="" disabled={true} hidden>{translation[102].text}...</option>
                                {katilas.power.map((pow, key) => {
                                    let powerValue;
                                    if(typeof pow === 'string') powerValue = pow.match(/\d+/)[0];
                                    else powerValue = pow;
                                    return <option value={powerValue} key={key}>{translation[42].text} {powerValue} kW</option>
                                })}
                            </select> */}
                            <div className="dropdown-div">
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" >
                                        <span>{!formData.galia ? translation[102].text : `${translation[42].text} ${formData.galia} kW`}</span>
                                        <i className="arrow down" ></i> 
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                    {katilas.power.map((pow, key) => {
                                        let powerValue;
                                        if(typeof pow === 'string') powerValue = pow.match(/\d+/)[0];
                                        else powerValue = pow;
                                        
                                        return <Dropdown.Item as="button" onClick={(e) => {
                                            e.preventDefault();
                                            handleInputChange({
                                                target:{
                                                    type: "select-one", 
                                                    name: "galia", 
                                                    value: String(powerValue), 
                                                    checked: undefined
                                                }
                                            })}
                                        } key={key}>{translation[42].text} {powerValue} kW</Dropdown.Item> 
                                    })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {katilas.selections[1][0][0] === "Degiklis" ? 
                                <>
                                    {/* <select value={formData.kuroTalpa} onChange={handleInputChange} name="kuroTalpa" disabled={!powerSelected}>
                                        
                                        {
                                            katilas.selections[0].map((a, key) => {
                                                if(key > 0){
                                                    return <option value={a} key={key}>{translation[106].text} {a} </option>
                                                }else return null
                                            })
                                        }
                                    </select> */}
                                    <div className="dropdown-div" >
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={!powerSelected}>
                                                <span>{!formData.kuroTalpa ? `${translation[106].text} ${katilas.selections[0][1]}` : `${translation[106].text} ${formData.kuroTalpa}`}</span>
                                                <i className="arrow down" ></i> 
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            {
                                                katilas.selections[0].map((a, key) => {
                                                    if(key > 0){
                                                        //return <option value={a} key={key}>{translation[106].text} {a} </option>
                                                        return <Dropdown.Item as="button" onClick={(e) => {
                                                            
                                                            e.preventDefault();
                                                            handleInputChange({
                                                                target:{
                                                                    type: "select-one", 
                                                                    name: "kuroTalpa", 
                                                                    value: String(a), 
                                                                    checked: undefined
                                                                }
                                                            })}
                                                        } key={key}>{translation[106].text} {a}</Dropdown.Item> 
                                                    }else return null
                                                })
                                            }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> 
                                    {/* <select value={formData.degiklis} onChange={handleInputChange} name="degiklis" disabled={!powerSelected || disableBurner}>
                                        <option value="" disabled={true} hidden>{translation[24].text}</option>
                                        {   
                                            katilas.selections[1].map((a, key) => {
                                                if(key > 0){
                                                    return a.map((b, key2) => {
                                                        if(key2 > 0){
                                                            // console.log(katilas.selections[1][key][0])
                                                            if(katilas.selections[1][key][0] === formData.galia){
                                                                // console.log(katilas.selections[1][key][0])
                                                                // console.log(formData.galia)
                                                                // console.log(b)
                                                                // console.log(katilas.selections[1][key].length)
                                                                return <option value={b} key={key2}>{translation[24].text} {b} </option>
                                                            }else return null
                                                        }else return null
                                                    })
                                                    // return <option value={a}>{katilas.selections[0][0]} {a} </option>
                                                }else return null
                                            })
                                        }
                                    </select>  */}
                                    <div className="dropdown-div" >
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={!powerSelected || disableBurner}>
                                                <span>{!formData.degiklis ? translation[24].text : `${translation[24].text} ${formData.degiklis}`}</span>
                                                <i className="arrow down" ></i> 
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            {   
                                                katilas.selections[1].map((a, key) => {
                                                    if(key > 0){
                                                        return a.map((b, key2) => {
                                                            if(key2 > 0){
                                                                // console.log(katilas.selections[1][key][0])
                                                                if(katilas.selections[1][key][0] === formData.galia){
                                                                    // console.log(katilas.selections[1][key][0])
                                                                    // console.log(formData.galia)
                                                                    // console.log(b)
                                                                    // console.log(katilas.selections[1][key].length)
                                                                    //return <option value={b} key={key2}>{translation[24].text} {b} </option>
                                                                    return <Dropdown.Item as="button" onClick={(e) => {
                                                                        //console.log("hello");
                                                                        e.preventDefault();
                                                                        handleInputChange({
                                                                            target:{
                                                                                type: "select-one", 
                                                                                name: "degiklis", 
                                                                                value: String(b), 
                                                                                checked: undefined
                                                                            }
                                                                        })}
                                                                    } key={key2}>{translation[24].text} {b}</Dropdown.Item>
                                                                }else return null
                                                            }else return null
                                                        })
                                                        // return <option value={a}>{katilas.selections[0][0]} {a} </option>
                                                    }else return null
                                                })
                                            }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                                : 
                                katilas.selections[0][0] === "Kuro talpa" ? 
                                <>
                                    {/* <select value={formData.kuroTalpa} onChange={handleInputChange} name="kuroTalpa" disabled={!powerSelected}>
                                        
                                        {
                                            katilas.selections[0].map((a, key) => {
                                                if(key > 0){
                                                    return <option value={a} key={key}>{translation[106].text} {a} </option>
                                                }else return null
                                            })
                                        }
                                    </select>  */}
                                    <div className="dropdown-div" >
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" disabled={!powerSelected}>
                                                <span>{!formData.kuroTalpa ? `${translation[106].text} ${katilas.selections[0][1]}` : `${translation[106].text} ${formData.kuroTalpa}`}</span>
                                                <i className="arrow down" ></i> 
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            {
                                                katilas.selections[0].map((a, key) => {
                                                    if(key > 0){
                                                        //return <option value={a} key={key}>{translation[106].text} {a} </option>
                                                        return <Dropdown.Item as="button" onClick={(e) => {
                                                            //sistemos apejimas seni
                                                            e.preventDefault();
                                                            handleInputChange({
                                                                target:{
                                                                    type: "select-one", 
                                                                    name: "kuroTalpa", 
                                                                    value: String(a), 
                                                                    checked: undefined
                                                                }
                                                            })}
                                                        } key={key}>{translation[106].text} {a}</Dropdown.Item> 
                                                    }else return null
                                                })
                                            }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                                : 
                                <></>}

                                    {errors.selects.length > 0 && (
                                        <div className="ValidationError">
                                        <p>{errors.selects}</p>
                                        </div>
                                    )}
                            
                            {/* <select value={formData.kuroTalpa} onChange={handleInputChange} name="kuroTalpa">
                                {
                                    uniqueItems1.map((a, key) => {
                                        if(key > 1){
                                             return <option value={a}>{uniqueItems1[0]} {a} {uniqueItems1[1]}</option>
                                        }else return null
                                    })               
                                }
                            </select>
                            <select value={formData.degiklis} onChange={handleInputChange} name="degiklis">
                                {
                                    uniqueItems2.map((a, key) => {
                                        if(key > 1){
                                            return <option value={a}>{translation[24].text} {a} {uniqueItems2[1]}</option>
                                        }else return null
                                    })
                                }
                            </select> */}
                        </div>
                        <div className="FormInputFields">
                            <div className="InputFieldRow">
                                <div className="InputFieldDiv">
                                    <input type="text" placeholder={translation[43].text} name="vardas" value={formData.vardas} onChange={e => {handleInputChange(e); errors.vardas !== '' ? validate(["vardas"]) : emptyFunction()}} onClick={handleFieldClick} onBlur={() => validate(["vardas"])}/>
                                    <div className="asterisk" style={{position: "relative", bottom: "32px", left: "466px", color: "#e15303"}}>*</div>
                                    {errors.vardas.length > 0 && (
                                        <div className="ValidationError">
                                        <p>{errors.vardas}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="InputFieldDiv">
                                    <input type="text" placeholder={translation[44].text} name="elPastas" value={formData.elPastas} onChange={e => {handleInputChange(e); errors.elPastas !== '' ? validate(["elPastas"]) : emptyFunction()}} onClick={handleFieldClick} onBlur={() => validate(["elPastas"])}/>
                                    <div className="asterisk" style={{position: "relative", bottom: "32px", left: "466px", color: "#e15303"}}>*</div>
                                    {errors.elPastas.length > 0 && (
                                        <div className="ValidationError">
                                        <p>{errors.elPastas}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="InputFieldRow">
                                <div className="InputFieldDiv">
                                    <input type="text" placeholder={translation[45].text} name="kompanija" value={formData.kompanija} onChange={handleInputChange} onClick={handleFieldClick}/>
                                </div>
                                <div className="InputFieldDiv">
                                    <input type="text" placeholder={translation[46].text} name="telNr" value={formData.telNr} onChange={e => {handleInputChange(e); errors.telNr !== '' ? validate(["telNr"]) : emptyFunction()}} onClick={handleFieldClick} onBlur={() => validate(["telNr"])}/>
                                    <div className="asterisk" style={{position: "relative", bottom: "32px", left: "466px", color: "#e15303"}}>*</div>
                                    {errors.telNr.length > 0 && (
                                        <div className="ValidationError">
                                        <p>{errors.telNr}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="TextAreaFieldDiv">
                                <textarea type="text" spellCheck="false" placeholder={translation[47].text} name="zinute" value={formData.zinute} onChange={handleInputChange} onClick={handleFieldClick}/>
                            </div>
                            <label className="checkboxLabel">
                                <input type="checkbox" className="sutikimasCheckbox" style={{marginRight: "10px"}} name="sutikimas" value={formData.sutikimas} onChange={handleInputChange} onClick={handleFieldClick}/> {translation[48].text} 
                                    <a href={language === 'lt' ? "../assets/Privatumo_politika.pdf" : "../assets/Privacy_Policy.pdf"} download><b className="policyBold">{translation[49].text}</b></a> 
                                <div className="asterisk" style={{position: "relative", bottom: "26px", left: "296px", color: "#e15303"}}>*</div>
                                {errors.sutikimas.length > 0 && (
                                    <div className="ValidationError">
                                    <p>{errors.sutikimas}</p>
                                    </div>
                                )}
                            </label>
                        </div>
                        
                        {
                        activeOrder === true ?
                            <div className="Price" style={{position: "relative", marginTop: "40px"}}>
                                <div>
                                    <p style={{position: "relative", top: 0}}>{translation[21].text}</p>
                                    <h2 style={{position: "relative", fontSize: "50px", fontWeight: "1200", marginBottom: "40px"}}>{selectionPrice} <span style={{fontSize: "26px", position: "relative", bottom: "20px"}}> Eur</span></h2>
                                    {/* <p style={{position: "relative", left: "156px", bottom: "66px", fontSize: "26px"}}>Eur</p> */}
                                </div>
                                <button className="DetailsButton" type="button" onClick={() => handleFormSubmit()} style={{position: "relative", bottom: "34px", marginRight: "6px"}}>{translation[33].text}</button>
                                <button className="DetailsButton" type="button" onClick={() => setActiveOrder(false)} style={{position: "relative", bottom: "34px", backgroundColor: "gray", boxShadow: "none"}}>{translation[40].text}</button>
                                <Link to='/atstovai' className='OrangeLink'>{translation[34].text} &gt;</Link>
                            </div>
                        : null
                        }

                    </form>
                </div>}
            </div>
            
            <div className={(Array.isArray(katilas.talpa) && katilas.talpa.length) 
                ? `DetailsBottom descriptionExtraPixels${activeButton} ${activeOrder} descriptionExtraPixelsTalpa${activeButton}` 
                : `DetailsBottom descriptionExtraPixels${activeButton} ${activeOrder}`}>
                <div className="ExtraButtons">
                    <div className="ButtonsDiv">
                        {activeButton === 1 ? <button className="DescriptionButtonActive">{translation[22].text}</button> : <button className="DescriptionButton" style={{borderLeft: "1px solid lightgray"}} onClick={() => setActiveButton(1)}>{translation[22].text}</button>}
                        {activeButton === 2 ? <button className="DescriptionButtonActive">{translation[23].text}</button> : <button className="DescriptionButton" onClick={() => setActiveButton(2)}>{translation[23].text}</button>}
                        {typeof katilas.degiklis === "string" ? (activeButton === 3 ? <button className="DescriptionButtonActive">{translation[24].text}</button> : <button className="DescriptionButton" onClick={() => setActiveButton(3)}>{translation[24].text}</button>) : null}
                        {activeButton === 4 ? <button className="DescriptionButtonActive"><img className="circleExclamation" alt="!" src="../assets/circle-exclamation-solid.svg"></img> {translation[25].text}</button> : <button className="DescriptionButton" onClick={() => setActiveButton(4)}><img className="circleExclamation" alt="!" src="../assets/circle-exclamation-solid.svg"></img> {translation[25].text}</button>}
                    </div>
                    <div className="ExtraButtonsUnderline"></div>
                </div>
                <div className="ExtraTabs">
                    <div className="ConditionalDivs">
                        {activeButton === 1 ? 
                            <div className="ExtraAprašymas">
                                <h3>{katilas.name}</h3>
                                <p>
                                    {katilas.description}
                                </p>
                            </div>
                        : null}
                        {activeButton === 2 ?
                            <div className="ExtraMatmenys">
                                <img src={katilas.dimensionsImage}alt="Matmenys"></img>
                                <div>
                                {(Array.isArray(katilas.dimensions) && katilas.dimensions.length) ? <table style={{borderCollapse: "collapse"}}>
                                    <thead>
                                        <tr>
                                            <th className="DataTableHeader" colSpan={2} style={{borderRight: "1px solid gray", textAlign: "left"}}>{translation[26].text}</th>
                                            {katilas.power.map((pow) => {return <th key={pow} className="DataTableHeader" style={{minWidth: "60px", paddingLeft: "14px"}}>{pow} kW{katilas.name === "Pellet Uni" && pow == 36 ? "*" : null}</th>})}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {katilas.dimensions.map((dataRow, key) => {
                                            let tableDatas = []

                                            for(let i = 2; i < dataRow.length; i += 1){
                                                if(key === 0){
                                                    listKey += 1
                                                    tableDatas.push(<td key={listKey} style={{paddingTop: "16px", paddingLeft: "20px"}}>{dataRow[i]}</td>)
                                                }else{
                                                    listKey += 1
                                                    tableDatas.push(<td key={listKey} style={{paddingLeft: "20px"}}>{dataRow[i]}</td>)
                                                }
                                            }
                                            
                                            if(key === 0){
                                                return <tr key={key}>
                                                    <td className="GrayTableData" style={{paddingTop: "16px"}}>{dataRow[0]}</td>
                                                    <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "16px 30px 0px 24px"}}>{dataRow[1]}</td>
                                                    {tableDatas}
                                                </tr>
                                            }else{
                                                return <tr key={key}>
                                                    <td className="GrayTableData">{dataRow[0]}</td>
                                                    <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "0px 30px 0px 24px"}}>{dataRow[1]}</td>
                                                    {tableDatas}
                                                </tr>
                                            }
                                        })}
                                    </tbody>
                                </table>  : "" }
                                {(Array.isArray(katilas.talpa) && katilas.talpa.length) ? <table className="talpaTable" style={{borderCollapse: "collapse", marginTop: "40px"}}>
                                    <thead>
                                        <tr>
                                            <th className="DataTableHeader" colSpan={2} style={{borderRight: "1px solid gray", textAlign: "left"}}>{translation[27].text}</th>
                                            {katilas.talpa.map((pow) => {return <th key={pow} className="DataTableHeader" style={{minWidth: "80px", paddingLeft: "14px"}}>{pow}</th>})}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {katilas.talpaData.map((dataRow, key) => {
                                            let tableDatas = []

                                            for(let i = 2; i < dataRow.length; i += 1){
                                                if(key === 0){
                                                    listKey += 1
                                                    tableDatas.push(<td key={listKey} style={{paddingTop: "8px", paddingLeft: "30px"}}>{dataRow[i]}</td>)
                                                }else{
                                                    listKey += 1
                                                    tableDatas.push(<td key={listKey} style={{paddingLeft: "30px"}}>{dataRow[i]}</td>)
                                                }
                                            }
                                            
                                            if(key === 0){
                                                return <tr key={key}>
                                                    <td className="GrayTableData" style={{paddingTop: "8px"}}>{dataRow[0]}</td>
                                                    <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "16px 30px 0px 24px"}}>{dataRow[1]}</td>
                                                    {tableDatas}
                                                </tr>
                                            }else{
                                                return <tr key={key}>
                                                    <td className="GrayTableData">{dataRow[0]}</td>
                                                    <td className="GrayTableData" style={{borderRight: "1px solid gray", padding: "0px 30px 0px 24px"}}>{dataRow[1]}</td>
                                                    {tableDatas}
                                                </tr>
                                            }
                                        })}
                                    </tbody>
                                </table> : "" }
                                </div>
                            </div>
                        : null}
                        {activeButton === 3 ?
                            <div className="ExtraDegiklis">
                                <h4>{translation[24].text} {katilas.degiklis}</h4>
                                    <p>{katilas.burnerDescription}</p>
                                <div className="DegiklisData">
                                    <img src={katilas.burnerImage} alt="Degiklis"></img>
                                    <table className="AdditionalDataTable">
                                        <tbody>
                                            {katilas.degiklisData.map((line) => {
                                                return <tr key={line}>
                                                    <td key={line[0]+"1"} className="GrayTableData">{line[0]}</td>
                                                    <td key={line[0]+"2"} className="GrayTableData" style={{paddingLeft: "80px"}}>{line[1]}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{marginTop: "10px", display:'flex', flexDirection:'row'}}>
                                    <a className="katalogasButton" href={katilas.burnerInstructionsPdf} download style={{maxWidth: "200px"}} ><img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img>{translation[155].text}</a>
                                    <a className="katalogasButton" href={katilas.certificatePdf} download style={{maxWidth: "240px"}}><img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img>
                                    {katilas.id === 1 ? translation[19].text : translation[119].text}</a>
                                    <a className="katalogasButton" href={katilas.controllerInstructionsPdf} download style={{maxWidth: "280px"}}><img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img>{translation[28].text} </a>
                                </div>
                            </div>
                        : null}
                        {activeButton === 4 ?
                            <div className="ExtraPajungimas">
                                <h4><img className="circleExclamationBigger" alt="!" src="../assets/circle-exclamation-solid.svg"></img> {translation[29].text}</h4>
                                <p>{katilas.connectionDescription}</p>
                                <div style={{display: "flex"}}>
                                    <a className="katalogasButton" href={katilas.connectionSchemePdf} download style={{maxWidth: "250px", marginRight: "4px"}}><img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img>{translation[30].text}</a>
                                    <div><Link to="/informacija"  style={{color: "#e15303"}}>{translation[6].text} {">"}</Link></div>
                                    <div><Link to="/specialistai" style={{color: "#e15303"}}>{translation[31].text} {">"}</Link></div>
                                </div>
                            </div>
                        : null}
                    </div>

                    {/* {
                        activeOrder === false ? <div className="Price">
                            <div>
                                <p style={{position: "relative", top: 0}}>{translation[21].text} {translation[32].text.toLowerCase()}</p>
                                <h2 style={{position: "relative", fontSize: "50px", fontWeight: "1200"}}>{katilas.price}</h2>
                                <p style={{position: "relative", left: "136px", bottom: "66px", fontSize: "26px", width: "40px"}}>Eur</p>
                            </div>
                            <button className="DetailsButton" onClick={() => {
                                setActiveOrder(true)
                                window.scrollTo(0, 360)
                            }}
                            style={{position: "relative", bottom: "34px"}}>{translation[33].text}</button>
                            <div className="link-div">
                                <Link to='/atstovai' className='OrangeLink'>{translation[34].text} &gt;</Link>
                            </div>
                        </div>
                        : null
                    } */}

                </div>
            </div>
        </section>
        :  
        <div className="orderCompleteSection">
            <div className="NavAndEff">
                <nav className="Nav">
                    {/* placeholder for links */}
                    <p>
                        <span><Link to={"/"}>{translation[0].text}</Link></span>
                        <span style={{minWidth: "30px", borderBottom: "1px solid #a7a7a7", display: "inline-block", margin: "0px 1px 0px 1px", position: "relative", top: "6px"}}/>
                        {
                            Array.isArray(katilas.type) ? 
                            katilas.type.map((item, index) =>{
                                if(katilas.type[index+1]) return <><span><Link to={returnKatilasTypeLink(item)}>{item} {translation[0].text.toLowerCase()} </Link></span><span>  /  </span></>
                                return <span><Link to={returnKatilasTypeLink(item)}>{item} {translation[0].text.toLowerCase()} </Link></span>
                            })
                            : <span><Link to={linkDest}>{katilas.type} {translation[0].text.toLowerCase()}</Link></span>
                        }
                        <span style={{minWidth: "30px", borderBottom: "1px solid #a7a7a7", display: "inline-block", margin: "0px 1px 0px 1px", position: "relative", top: "6px"}}/>
                        <span><Link to={"/katilai/"+katilas.id}>{katilas.name}</Link></span>
                    </p>
                </nav>
            </div>
            <button className="CloseOrderButton" onClick={() => {setActiveOrder(false); setOrderComplete(false)}}>
                <p>{translation[35].text}</p>
                <img style={{"paddingLeft": "5px", "height": "54px", "width": "32px"}}alt="X" src="../assets/iksiukasB.png"></img>
            </button>
            <div className="MainInfo">
                <h5 className="feature">
                    {katilas.feature}
                </h5>
                <h3 className="katilasName">
                    {katilas.name}
                </h3>
                <p className="dataTop">{
                    Array.isArray(katilas.type) ? 
                    katilas.type.map(item =>{
                    return <span>{item} </span>
                    })
                    : katilas.type
                } {translation[0].text.toLowerCase()} <br/></p>
            </div>
            <div className="orderCompleteTekstas">
                <p>{translation[0].text},</p>
                <p>{translation[37].text} <span style={{"color": "#e15303"}}>{translation[38].text.toLowerCase()}</span>, {translation[39].text}!</p>
            </div>
            <button className="DetailsButton" type="button" onClick={() =>{setActiveOrder(false); setOrderComplete(false)}} style={{position: "relative", bottom: "34px", backgroundColor: "rgb(218, 218, 218)", boxShadow: "none", color: "black"}}>{translation[35].text}</button>
        </div> }
        <footer>
                <div className="infoFooter"><UsefulInfo/></div>
                <div className="locationFooter"><Location/></div>
        </footer>
        </>
    );
}

// KatilasDetails.propTypes = {
//     katilai: PropTypes.arrayOf(Katilas).isRequired,
// };

export default KatilasDetails;