import React from 'react';
import '../footer.css';
import Zemelapis from "./zemelapis";
import texts_lt from '../translations/lt.json';
import texts_en from '../translations/en.json';
import { useLanguage } from '../LanguageContext';
import { Link } from "react-router-dom";
import { useState } from 'react';


function Location() {

  let [language, languageTexts, languageKatilai, toggleLanguage]= useLanguage();
  const translation = languageTexts;
  const [mouseMoved, setMouseMoved] = useState(false);
  const [clickCount, setClickCount] = useState(0);


  const handleMouseMove = () => {
    setMouseMoved(true);
    setClickCount(0);
  };

  // Function to handle mouse down event
  const handleMouseDown = () => {
    setMouseMoved(false);
    setClickCount(prevCount => prevCount + 1);
    setTimeout(() => {
      setClickCount(0);
    }, 650); // Reset click count after 300 milliseconds
  };

  // Function to handle mouse up event
  const handleMouseUp = () => {
    if (!mouseMoved && clickCount === 2) {
      // Open the link if it's a double click and mouse hasn't moved
      window.open('https://www.google.com/maps/place/Auk%C5%A1taitijos+katilai/@55.2213817,24.6961866,13.29z/data=!4m6!3m5!1s0x46e7a2b934e7047b:0xe081efa9559d3cb1!8m2!3d55.2326184!4d24.7501122!16s%2Fg%2F11b6j1nsgv', '_blank');
    }
  };

  const handleDoubleClick = () => {
    if (clickCount === 2) {
      console.log('Double click detected');
      setClickCount(0); // Reset click count
    }
  };

  return <> 
  <div className='row' id='bottomFooter'>
    <div className='column'>
      <div className='columnContent'>
        <div className='contactInfo'>
            <img className='akatilai_logo' src={'../assets/logo_black_' + language + '.svg'} alt='logo'></img>
            <h1></h1>
            <div className='contactText'>
                <p>Linų g. 85, LT-20174 Ukmergė</p>
                <p>info@akatilai.lt</p>
                <p>+370 683 33388</p>
            </div>
            <div className='contactText'>
                {/* <p>A/s LT52 7182 4000 1346 7291 </p>
                <p>Šiaulių bankas AB, {translation[89].text} 71824</p> */}
                <p>{translation[90].text}: 303081850 </p>
                <p>{translation[91].text}: LT100009242414</p>
            </div>
            <hr></hr>
            <div className='klientamsLinkDiv'>
              <Link to='/sheets'><img src='../assets/klientams.svg' className='klientamsIcon filer-orange' alt='clientIcon'></img>&nbsp;&nbsp;&nbsp;{translation[108].text}</Link>
            </div>
            
            <p className='copyright'>© {translation[123].text}, 2023 m.</p>
        </div>
      </div>
    </div>
    <div className='column'>
      <div onMouseDown={handleMouseDown}
    onMouseUp={handleMouseUp}
    onMouseMove={handleMouseMove}  className='realZemelapis'>
          <div className='greyGradient'></div>
          <Zemelapis/>
      </div>
    </div>
  </div>
  </>;
}

export default Location;
