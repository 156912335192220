import React from 'react';
import { LanguageProvider } from './LanguageContext';
import OurRouter from './ourRouter';
import ScrollToTop from './ScrollToTop';
import { useEffect } from 'react';

function App() {

  

  return (
    <LanguageProvider>
      <OurRouter>
        <ScrollToTop/>
      </OurRouter>
    </LanguageProvider>
  );
}

export default App;