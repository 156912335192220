import React from 'react';
import '../footer.css';
import { useLanguage } from '../LanguageContext';
import { Link, useNavigate } from 'react-router-dom'; 

function UsefulInfo() {

  let [language, languageTexts, languageKatilai, toggleLanguage]= useLanguage();
  const translation = languageTexts;
  const navigate = useNavigate();

  return <> 
  <p className='usefulInfo' >{translation[71].text}</p>
  <div className='invisibleMore' id='contact'></div>
  <div className='invisible' id='contacts'></div>
  <div className='row' >
    <div className='column'>
      <div className='columnContent'>
        <div className='combinedTitle'>
          <h2 className='title'>{translation[78].text}</h2>
          <h2 className='title'>{translation[79].text}</h2>
        </div>
        <div className='nonTitleInfoContent'>
          <p className='middleText' style={{paddingBottom: "10px"}}>{translation[80].text}</p>
          <div>
            <h3 className='phoneNumber'>+370 683 33388</h3>
            <h4 className='email' style={{paddingBottom: "10px"}}>info@akatilai.lt</h4>
            <h3 className='phoneNumber'>+370 623 33388</h3>
            <h4 className='email'>uzsakymai@akatilai.lt</h4>
          </div>
        </div>
      </div>
    </div>
    <hr className='footerHR'/>
    <div className='column'>
      <div className='columnContent' id='catalogueSection'>
        <div>
          <h2 className='title'>{translation[81].text}</h2>
          <h2 className='title'>{translation[82].text}</h2>
        </div>
        <div className='nonTitleInfoContent'>       
          <p className='middleText'>{translation[83].text}</p>
          <div>
            <a className='katalogasButton footerKatalogas' href={'../assets/katalogas_' + language + '.pdf'} download>{translation[17].text}<img className='fileIcon' alt='icon' src='../assets/file-pdf-solid.svg'></img></a>
          </div>
        </div> 
      </div>
    </div>
    <hr className='footerHR' id='catalogueSection'/>
    <div className='column'>
      <div className='columnContent'>
        <div>
          <h2 className='title'>{translation[84].text}</h2>
          <h2 className='title'>{translation[85].text}</h2>
        </div>
        <div className='nonTitleInfoContent paleidimoSpec'>
          <p className='middleText'>{translation[86].text}</p>
          <div className='twoLinks paleidimoSpec'>
            {/* <Link to='/atstovai' className='OrangeLink'>{translation[87].text} &gt;</Link>
            <Link to='/specialistai' className='OrangeLink'>{translation[88].text} &gt;</Link> */}
            <button className="DetailsButton paleidimoSpec" onClick={() => navigate("/atstovai")}>{translation[87].text} &gt;</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>;
}

export default UsefulInfo;