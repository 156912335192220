import React from 'react';
import '../footer.css';
import { useLanguage } from '../LanguageContext';
import { Link, useNavigate } from 'react-router-dom'; 

function PaleidimoFooter() {

    let [language, languageTexts, languageKatilai, toggleLanguage]= useLanguage();
    const translation = languageTexts;
    const navigate = useNavigate();

    return <> 
        <div className='row paleidimoRow' >
            <div className='paleidimas paleidimoFooterRow'>
                <div className='paleidimasIcon'><img src='../assets/paleidimas.svg' alt='paleidimas_logo'></img></div>
                <div className='paleidimasIconJuodas'><img src='../assets/paleidimas_juodas.svg'alt='paleidimas_logo_juodas'></img></div>
                <h1>{translation[88].text}</h1>
                <p>{translation[55].text}</p>
                <button className="DetailsButton" onClick={() => navigate("/specialistai")}>{translation[31].text} &gt;</button>
                {/* <Link to='/specialistai' className='WhiteLink' id='mobileBlack'>{translation[31].text} &gt;</Link> */}
            </div>
        </div>
    </>;
}

export default PaleidimoFooter;