import { useState, useRef } from "react";
import {Buffer} from 'buffer';

function DragDropFiles() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [okResponse, setOkResponse] = useState(null);

  const [files, setFiles] = useState(null);
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files)
  };
  
  const handleUpload = () => {

    //weird formatting but works
    const fileList = files ? [...files] : [];
    const data = new FormData();
    fileList.forEach((file, i) => {
      data.append(`file_${i}`, file, file.name);
    });

    //authentication
    let auth = name+":"+pass;
    let encoded = Buffer.from(auth).toString('base64');

    let statusCode;

    fetch(`${process.env.REACT_APP_SERVER_API_URL}/post/files`, {
        method: "POST",
        headers: {
          'Authorization': 'Basic '+ encoded
        },
        body: data
      }  
    )
    .then((res) => {
      statusCode = res.status;
      return res.json()})
    .then((data) => {
      if(data && statusCode === 200) {
        setOkResponse(data);
        setFiles(null);
      }
      else setError(data);
    })
    .catch((err) => console.error(err));
  };

  function login(event) {
    setError(null);
    if(event) event.preventDefault();
    let auth = name+":"+pass;
    let encoded = Buffer.from(auth).toString('base64');
    let statusCode;

    fetch(`${process.env.REACT_APP_SERVER_API_URL}/get/loginonly`,{
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Authorization': 'Basic '+ encoded
      }
    })
      .then(res => {
        statusCode = res.status;
        return res.json()})
      .then(
        (result) => {
          if(result && statusCode === 200) {
            setIsLoaded(true);
          }
          else setError(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //setIsLoaded(true);
          setError(error);
        }
      )
  }

  if (!isLoaded) {
    return <div className='reserveForma'>
      <form onSubmit={login}>
        <label>Įveskite vardą: </label>
        <input
            type="text" 
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        <label>Įveskite slaptažodį: </label>
        <input
            type="password" 
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
        <input type="submit" value="Prisijungti"/>
      </form>
      {error ? <div>Error: {error}</div> : null}
    </div>;
  } else {
    if (files) return (
      <div className="uploads">
          <ul>
              {Array.from(files).map((file, idx) => <li key={idx}>{file.name}</li> )}
          </ul>
          <div className="actions">
              <button onClick={() => setFiles(null)}>Atšaukti</button>
              <button onClick={handleUpload}>Įkelti</button>
          </div>
          {okResponse ? <div>
            <h3>Sėkmingai įkelti failai</h3>
            {okResponse.map(item =>{
              return <li key={item + "h5"}>{item}</li>
            })}
            </div> : null}
      </div>
    )

    return (
      <>
          <div 
              className="dropzone"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
          >
            <h1>Nutempkite ir įmeskite failus</h1>
            <h1>Arba</h1>
            <input 
              type="file"
              multiple
              onChange={(event) => setFiles(event.target.files)}
              hidden
              //accept="image/png, image/jpeg"
              ref={inputRef}
            />
            <button onClick={() => inputRef.current.click()}>Pasirinkite failus</button>
            {okResponse ? <div>
            <h3>Sėkmingai įkelti failai</h3>
            {okResponse.map(item =>{
              return <li key={item + "h5"}>{item}</li>
            })}
            </div> : null}
          </div>
      </>
    );
  }
};

export default DragDropFiles;