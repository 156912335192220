import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function ImageMagnifier({
    src,
    width,
    height,
    className,
    showMagnifier,
    setShowMagnifier,
    x,
    y,
    setXY,
    magnifierHeight = 150,
    magnifieWidth = 150,
    zoomLevel = 1.5
  }) {
    // const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    // const [showMagnifier, setShowMagnifier] = useState(false);

    useEffect(() => {
        setSize([628, 500]);
      }, []);

    return (
      <div
        style={{
          position: "relative",
          height: height,
          width: width
        }}
        className="desktopImage"
      >
        <img
          src={src}
          style={{ height: height, width: width }}
          className={className}
          onMouseEnter={(e) => {
            // update image size and turn-on magnifier
            const elem = e.currentTarget;
            const { width, height } = elem.getBoundingClientRect();
            setSize([width, height]);
            setShowMagnifier(true);
          }}
          onMouseMove={(e) => {
            // update cursor position
            const elem = e.currentTarget;
            const { top, left } = elem.getBoundingClientRect();
  
            // calculate cursor position on the image
            const x = e.pageX - left - window.pageXOffset;
            const y = e.pageY - top - window.pageYOffset;
            if(x > 100 && x < 520){
              setXY([x, y]);
            }else{
              if(x <= 100){
                setXY([100, y])
              }else{
                setXY([520, y])
              }
            }
            // setXY([x, y]);
          }}
          onMouseLeave={() => {
            // close magnifier
            setShowMagnifier(false);
          }}
          alt={"img"}
        />
  
        <div
          className="magnifier-div"
          style={{
            display: showMagnifier ? "" : "none",
            position: "absolute",
  
            // prevent magnifier blocks the mousemove event of img
            pointerEvents: "none",
            // set size of magnifier
            height: `${magnifierHeight}px`,
            width: `${magnifieWidth}px`,
            // move element center to cursor pos
            top: `${y - magnifierHeight / 2}px`,
            left: `${x - magnifieWidth / 2}px`,
            opacity: "1", // reduce opacity so you can verify position
            border: "none",
            backgroundColor: "white",
            backgroundImage: `url('${src}')`,
            backgroundRepeat: "no-repeat",
  
            //calculate zoomed image size
            backgroundSize: `${imgWidth * zoomLevel}px ${
              imgHeight * zoomLevel
            }px`,
  
            //calculate position of zoomed image.
            backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
            backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
          }}
        ></div>
      </div>
    );
  }
  
  export default ImageMagnifier