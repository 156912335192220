import React, {useState, forwardRef, useRef, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {Buffer} from 'buffer';
import HeaderBlack from '../header/headerBlack';
import HeaderBigBlackBeKatiluRusiu from '../header/headerBigBlackBeKatiluRusiu';
import CartWindow from './cartWindow';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLanguage } from './../LanguageContext';
import { lt, enUS, et, lv } from 'date-fns/locale';
registerLocale('lt', lt)
registerLocale('en', enUS)
registerLocale('ee', et)
registerLocale('lv', lv)

function SheetsMain() {
    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;
    const [error, setError] = useState(null);
    const [postError, setPostError] = useState(null);
    const [okResponse, setOkResponse] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [forminfo, setForminfo] = useState([]);

    const [orderHistory, setOrderHistory] = useState([]);
    const [orderHistoryError, setOrderHistoryError] = useState(null);
    const [isLoadedOrders, setIsLoadedOrders] = useState(false);

    const [name, setName] = useState("");
    const [pass, setPass] = useState("");

    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [katiluFilter, setKatiluFilter] = useState("");
    const [katiluGrupesFiltrui, setKailuGrupesFiltrui] = useState([]);
    
    const inputSearch = useRef();

    function getKatilai(event) {
      setError(null);
      setSearch("");

      if(event) event.preventDefault();
      let auth = name+":"+pass;
      let encoded = Buffer.from(auth).toString('base64');
      let statusCode;

      fetch(`${process.env.REACT_APP_SERVER_API_URL}/get/katilai`,{
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Authorization': 'Basic '+ encoded
        }
      })
        .then(res => {
          statusCode = res.status;
          return res.json()})
        .then(
          (result) => {
            if(result && statusCode === 200) {
              setIsLoaded(true);
              setIsLoadedOrders(false);
              setItems(result);
              //console.log(result, "result")
              let visosKatiluGrupes = [];
              let formstuff = {
                generalKatilaiUniques: result.generalKatilaiUniques.map(value => {
                      visosKatiluGrupes.push(value.name);
                      return{
                        ...value,
                        count: 0
                      }
                }),
                countsBuilding: result.countsBuilding.map(value => {
                  visosKatiluGrupes.push(value.name);
                  return{
                    ...value,
                    count: 0
                  }})
                };
              setKailuGrupesFiltrui([...new Set(visosKatiluGrupes)]);
              //console.log(formstuff, "form");
              setForminfo(formstuff);
              window.scrollTo(0, 0);
            }
            else setError(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }

    function attemptPost(){
      setError(null);
      setPostError(null);
      setOkResponse(null);
      let auth = name+":"+pass;
      let encoded = Buffer.from(auth).toString('base64');
      //console.log(forminfo);
      fetch(`${process.env.REACT_APP_SERVER_API_URL}/post/order`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic '+ encoded
        },
        body: JSON.stringify({
          name: name,
          data: forminfo
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(result);
            if(result === "ok") setOkResponse(translation[128].text);
            else setPostError(result.toString());
            getKatilai();
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setPostError(error.toString());
            getKatilai();
          }
        )
    };

    function getOrders(){
      // setError(null);
      // setPostError(null);
      setOkResponse(null);
      setSearch("");
      // setOrderHistoryError(null);
      let dateForDatePicker = new Date();
      dateForDatePicker.setDate(dateForDatePicker.getDate() - 30);
      setStartDate(dateForDatePicker);
      setEndDate(new Date());

      let auth = name+":"+pass;
      let encoded = Buffer.from(auth).toString('base64');
      let statusCode;

      fetch(`${process.env.REACT_APP_SERVER_API_URL}/get/orders/${name}`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic '+ encoded
        }
        })
        .then(res => {
          statusCode = res.status;
          return res.json()})
        .then(
          (result) => {
            if(result && statusCode === 200) {
              //console.log(result);
              setOrderHistory(result);
              setIsLoadedOrders(true);
              window.scrollTo(0, 0);
            }
            else setOrderHistoryError(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
            setOrderHistoryError(error.toString());
          }
        )
    };
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <div className="datePickerCustomButton" onClick={onClick} ref={ref}>
        <span className='fakeSpan'></span>
        <span>{value.split("/").map(str => str + " ")}</span>
        <i className="arrow down" ></i> 
      </div>
    ));
    //ne today bet startDate
    function isToday(date) {
      const today = startDate;
    
      if (
        today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate()
      ) {
        return true;
      }
    
      return false;
    }
    const renderDayContents = (day, date) => {
      return (<>
               {isToday(date) ? <span style={{color:"#e15303"}}>{day}</span> : <span >{day}</span>}
             </>);
    };

    function changeValue(value, index, max){
      setForminfo((oldFormInfo) =>{
        let testobject = {...oldFormInfo};
        value = Number(value);
        if(value > max) testobject.generalKatilaiUniques[index].count = max;
        else if(value < 0) testobject.generalKatilaiUniques[index].count = 0;
        else testobject.generalKatilaiUniques[index].count = value;
        return testobject;
      });
    }
    function changeValueUnfinished(value, index, max){
      setForminfo((oldFormInfo)=>{
        let testobject = {...oldFormInfo};
        value = Number(value);
        if(value > max) testobject.countsBuilding[index].count = max;
        else if(value < 0) testobject.countsBuilding[index].count = 0;
        else testobject.countsBuilding[index].count = value;
        return testobject;
      });
    }

      
    if (isLoaded)  {
      const katiluGrupesArray = katiluGrupesFiltrui.map(katiluGrupe => 
        {if(katiluFilter.toLowerCase() === katiluGrupe.toLowerCase() )
          return <Dropdown.Item 
            as="button" 
            onClick={() => setKatiluFilter(katiluGrupe)} 
            className="active-link" 
            key={katiluGrupe + 'button'}>
              {katiluGrupe}
          </Dropdown.Item> 
          else return <Dropdown.Item 
            as="button" 
            onClick={() => setKatiluFilter(katiluGrupe)} 
            key={katiluGrupe + 'button'}>
              {katiluGrupe}
          </Dropdown.Item>
        }
      );

      let katiluGrupesFiltered;
      if(katiluFilter) katiluGrupesFiltered = items.generalKatilaiUniques.filter(katilas => katilas.name.toLowerCase() === katiluFilter.toLowerCase());
      else katiluGrupesFiltered = items.generalKatilaiUniques;
      const results = katiluGrupesFiltered.map((katilas, index) =>{
            return <tr className='katilaiSandelyjeTableBody' key={katilas.name + "tr" + index + "made"}>
              <td key={katilas.name + "h1" + index + "made"}>{katilas.name}</td>
              <td key={katilas.name + "galia" + index + "made"}>{katilas.power ? katilas.power+" kW": null}</td>
              <td key={katilas.name + "kurotalpa" + index + "made"}>{katilas.fuelCapacity ? katilas.fuelCapacity+" l": null}</td>
              <td key={katilas.name + "degikliogalia" + index + "made"}>{katilas.degiklioPower ? katilas.degiklioPower+" kW": null}</td>
              <td className='uzsakomasKiekisTd'>
                <button 
                  className='uzsakymasUp'
                  onClick={() => {changeValue(forminfo.generalKatilaiUniques[index].count+1, index, katilas.count)}}
                  >
                  <i className='arrow up'></i>
                </button>
                <button 
                  className='uzsakymasDown'
                  onClick={() => {changeValue(forminfo.generalKatilaiUniques[index].count-1, index, katilas.count)}}
                  >
                  <i className='arrow down'></i>
                </button>
                <input
                    style={forminfo.generalKatilaiUniques[index].count > 0 ? {color:"#000000"}:{color:"#666666"}}  
                    id={`inputNumberColor${index}made`} 
                    key={katilas.name + "input" + index + "made"} 
                    type="number" 
                    min={0} 
                    max={katilas.count}
                    value={forminfo.generalKatilaiUniques[index].count} 
                    onChange={e => {changeValue(e.target.value, index, katilas.count)}}>
                </input>
              </td>
              <td key={katilas.name + "delete" + index  + "made"}>
                <button className='orderDeleteButton' onClick={() => changeValue(0, index, katilas.count)}><img src='../assets/trash-alt-solid.svg' alt='trashIcon'></img></button>
              </td>
            </tr>
      });
      let katiluGrupesFilteredUnfinished;
      if(katiluFilter) katiluGrupesFilteredUnfinished = items.countsBuilding.filter(katilas => katilas.name.toLowerCase() === katiluFilter.toLowerCase());
      else katiluGrupesFilteredUnfinished = items.countsBuilding;
      const resultsUnfinished = katiluGrupesFilteredUnfinished.map((katilas, index) =>{
        return <tr className='katilaiSandelyjeTableBody' key={katilas.name + "tr" + index}>
              <td key={katilas.name + "h1" + index}>{katilas.name}</td>
              <td key={katilas.name + "galia" + index}>{katilas.power ? katilas.power+" kW": null}</td>
              <td 
                key={katilas.name + "kurotalpa" + index} 
                style={katilas.fuelCapacity ? {color:"#000000"}:{color:"#a7a7a7"}} >
                  {katilas.fuelCapacity ? katilas.fuelCapacity+" l": translation[149].text}
                </td>
              <td 
                key={katilas.name + "degikliogalia" + index} 
                style={katilas.degiklioPower > 0 ? {color:"#000000"}:{color:"#a7a7a7"}} >
                  {katilas.degiklioPower ? katilas.degiklioPower+" kW": translation[149].text}
              </td>
              <td key={katilas.name + "pagaminimoData" + index} className='orangeText'>{katilas.buildingPeriod}</td>
              <td key={katilas.name + "gaminamasKiekis" + index} className='orangeText'>{katilas.count ? katilas.count+" "+ translation[154].text +".": null}</td>
              <td className='uzsakomasKiekisTd'>
                <button 
                  className='uzsakymasUp'
                  onClick={() => {changeValueUnfinished(forminfo.countsBuilding[index].count+1, index, katilas.count)}}
                  >
                  <i className='arrow up'></i>
                </button>
                <button 
                  className='uzsakymasDown'
                  onClick={() => {changeValueUnfinished(forminfo.countsBuilding[index].count-1, index, katilas.count)}}
                  >
                  <i className='arrow down'></i>
                </button>
                <input
                    style={forminfo.countsBuilding[index].count > 0 ? {color:"#000000"}:{color:"#666666"}}  
                    id={`inputNumberColor${index}`} 
                    key={katilas.name + "input" + index} 
                    type="number" 
                    min={0} 
                    max={katilas.count}
                    value={forminfo.countsBuilding[index].count} 
                    onChange={e => {changeValueUnfinished(e.target.value, index, katilas.count)}}>
                </input>
              </td>
              <td key={katilas.name + "delete" + index}>
                <button className='orderDeleteButton' onClick={() => changeValueUnfinished(0, index, katilas.count)}><img src='../assets/trash-alt-solid.svg' alt='trashIcon'></img></button>
              </td>
            </tr>
      });
      let orderHistoryVisual;
      if(isLoadedOrders){
        const orderHistoryCombined = [...orderHistory];
        orderHistoryCombined.sort((a, b) => 
          new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime()
        );
        let orderHistoryFiltered = [];
        orderHistoryCombined.forEach(order =>{
          const diff = new Date(order.orderDate).getTime() - new Date(startDate).getTime();
          const diff2 = new Date(endDate).getTime() - new Date(order.orderDate).getTime();
          if(diff > 0 && diff2 > 0) orderHistoryFiltered.push(order);
        })
        if(search) orderHistoryFiltered = orderHistoryFiltered.filter(order => order.name.toLowerCase().includes(search.toLowerCase()));
        orderHistoryVisual = orderHistoryFiltered.map((katilas, index) =>{
          // return <div className='unfinishedKatilas' key={order.name + 'div' + index}>
          //     <h3 key={order.orderDate + 'h3' + index} style={{width:"150px"}}>{order.orderDate}</h3>
          //     <h3 key={order.name + 'h3' + index} style={{width:"250px"}}>{order.name}</h3>
          //     <h3 key={order.count + 'h3' + index} style={{width:"100px"}}>Kiekis: {order.count}</h3>
          //     {order.buildingPeriod ? <h3 key={order.buildingPeriod + 'h3' + index}>Gaminamas - {order.buildingPeriod}</h3> : null}
          //   </div>
          return <tr className='katilaiSandelyjeTableBody' key={katilas.name + "tr" + index + "history"}>
          <td key={katilas.orderDate + "orderDate" + index + "history"}>{katilas.orderDate}</td>
          <td key={katilas.name + "h1" + index + "history"}>{katilas.name}</td>
          <td key={katilas.name + "galia" + index + "history"}>{katilas.power ? katilas.power+" kW": null}</td>
          <td key={katilas.name + "kurotalpa" + index + "history"} >{katilas.fuelCapacity ? katilas.fuelCapacity+" l": null}</td>
          <td key={katilas.name + "degikliogalia" + index + "history"}>{katilas.degiklioPower ? katilas.degiklioPower+" kW": null}</td>
          <td key={katilas.name + "uzsakytasKiekis" + index + "history"}>{katilas.count ? katilas.count+" "+ translation[154].text +".": null}</td>
          <td key={katilas.name + "pagaminimoData" + index + "history"} className='orangeText extraBorders'>{katilas.buildingPeriod ? katilas.buildingPeriod : " - "}</td>
          <td key={katilas.name + "completed" + index + "history"} className='orderAtliktasTd'>{katilas.completed ? "+": null}</td>
        </tr>
        })
      }

      return <>
        <HeaderBigBlackBeKatiluRusiu/>
        <div className='smallAboutHeader'><HeaderBlack/></div>
        <div className='reserveKatilai'>
          <div className='klientamsGray' ><img src='../assets/klientams.svg' className='klientamsIconGray' alt='clientIcon'></img>&nbsp;&nbsp;&nbsp;{translation[129].text}</div>
          <div className='sveikiUzsakovas'>
            <div>
              <h1>{translation[130].text},</h1>
              <h3>{name}</h3>
            </div>
            {isLoadedOrders ? null : <div className='uzsakymuIstorijaLink'><button onClick={() => getOrders()}>{translation[131].text}<span>&gt;</span></button></div>}
          </div>
          {orderHistoryError ? <div>Error: {orderHistoryError}</div> : null}
          <hr></hr>
          {isLoadedOrders ? 
          <div >
            <h1 className='uzsakymuIstorijaTitle'>{translation[132].text}</h1>
            <div className='uzsakymuIstorijaSearchDiv'>
              <input
                  className='inputTextSearch'
                  type="text" 
                  //value={search}
                  //onChange={(e) => setSearch(e.target.value)}
                  placeholder={translation[133].text}
                  ref={inputSearch}
                />
              <button className='uzsakymuIstorijaButton' onClick={() => {
                setSearch(inputSearch.current.value)
              }}>{translation[146].text}</button>
            </div>
            <div className='dateOrderPickers'>
              <div className='datePickerWithTitle'>
                <span>{translation[147].text}</span>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  locale={language}
                  dateFormat="yyyy/MM/dd"
                  maxDate={new Date()}
                  customInput={<ExampleCustomInput />}
                  renderDayContents={renderDayContents}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => {
                    const locateString = `${language}-${language.toUpperCase()}`;
                    const str = date.toLocaleString(locateString, { month: 'long' });
                    const dateString = str.charAt(0).toUpperCase() + str.slice(1);
                    return <div className='datePickerHeaderCustom'>
                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        <i className="arrow left" ></i> 
                      </button>
                      <span>{dateString}</span>
                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        <i className="arrow right" ></i> 
                      </button>
                    </div>
                  }}
                />
              </div>
              <div className='datePickerWithTitle'>
                <span>{translation[148].text}</span>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  locale={language}
                  dateFormat="yyyy/MM/dd"
                  maxDate={new Date()}
                  customInput={<ExampleCustomInput />}
                  renderDayContents={renderDayContents}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => {
                    const locateString = `${language}-${language.toUpperCase()}`;
                    const str = date.toLocaleString(locateString, { month: 'long' });
                    const dateString = str.charAt(0).toUpperCase() + str.slice(1);
                    return <div className='datePickerHeaderCustom'>
                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        <i className="arrow left" ></i> 
                      </button>
                      <span>{dateString}</span>
                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        <i className="arrow right" ></i> 
                      </button>
                    </div>
                  }}
                />
              </div>
            </div>
            <table className='katilaiSandelyjeTable'>
              <thead>
                <tr className='katilaiSandelyjeTableHead'>
                  <th>{translation[134].text}</th>
                  <th>{translation[26].text}</th>
                  <th>{translation[42].text}</th>
                  <th>{translation[106].text}</th>
                  <th>{translation[125].text}</th>
                  <th>{translation[135].text}</th>
                  <th className='orangeText extraBorders'>{translation[136].text}</th>
                  <th className='orderAtliktasTh'>{translation[127].text}</th>
                </tr>
              </thead>
              <tbody>{orderHistoryVisual}</tbody>
            </table>
            <div className='uzdarytiOrderHistory'>
              <button className='uzsakymuIstorijaButton' onClick={() => getKatilai()}>{translation[35].text}</button>
            </div>
          </div>
          : <>
            <div className="dropdown-div">
              <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" >
                      <span>{katiluFilter ? katiluFilter : translation[145].text}</span>
                      <i className="arrow down" ></i> 
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                  { !katiluFilter
                  ? <Dropdown.Item as="button" onClick={() => setKatiluFilter("")} className="active-link">{translation[138].text}</Dropdown.Item> 
                  : <Dropdown.Item as="button" onClick={() => setKatiluFilter("")}>{translation[138].text}</Dropdown.Item>}
                      {katiluGrupesArray}
                  </Dropdown.Menu>
              </Dropdown>
            </div>
            
              
              <div>
                <h2 className='katilaiOrderGroupTitle'>{translation[153].text}</h2>
                <table className='katilaiSandelyjeTable'>
                  <thead>
                    <tr className='katilaiSandelyjeTableHead'>
                      <th>{translation[26].text}</th>
                      <th>{translation[42].text}</th>
                      <th>{translation[106].text}</th>
                      <th>{translation[125].text}</th>
                      <th>{translation[127].text}</th>
                    </tr>
                  </thead>
                  <tbody>{results}</tbody>
                </table>
                <h2 className='katilaiOrderGroupTitle secondKatilaiOrderGroupTitle'>{translation[139].text}</h2>
                <table className='katilaiSandelyjeTable'>
                  <thead>
                    <tr className='katilaiSandelyjeTableHead'>
                      <th>{translation[26].text}</th>
                      <th>{translation[42].text}</th>
                      <th>{translation[106].text}</th>
                      <th>{translation[125].text}</th>
                      <th className='orangeText'>{translation[126].text}</th>
                      <th className='orangeText'>{translation[140].text}</th>
                      <th>{translation[127].text}</th>
                    </tr>
                  </thead>
                  <tbody>{resultsUnfinished}</tbody>
                </table>
              </div>
              <div className='orderInfoAndButton'>
                <CartWindow 
                cartItems={forminfo} 
                setCartItems={(data => setForminfo(data))}
                changeValue = {changeValue}
                changeValueUnfinished = {changeValueUnfinished}
                items = {items}/>
                <div style={{marginTop:"50px"}}>
                  <button className='DetailsButton' onClick={() => attemptPost()}>{translation[33].text}</button>
                  <button className='uzsakymuIstorijaButton' onClick={() => getOrders()}>{translation[132].text}</button>
                </div>
                
              </div>
            
          </>
          }
          {error ? <div>Error: {error}</div> : null}
          {postError ? <div>Error: {postError}</div> : null}
          {okResponse ? <div>{okResponse}</div> : null} 
          <hr></hr>
          <div className='orderNumberEmail' id='contacts'>
            <div className='phoneNumber'>+370 683 33388</div>
            <div className='email'>info@akatilai.lt</div>
          </div>
        </div>
      </>
    }
    return <>
        <HeaderBigBlackBeKatiluRusiu/>
        <div className='smallAboutHeader'><HeaderBlack/></div>
        <div className='reserveForma'>
          <div className='klientamsGray' ><img src='../assets/klientams.svg' className='klientamsIconGray' alt='clientIcon'></img>&nbsp;&nbsp;&nbsp;{translation[129].text}</div>
          <h1>{translation[152].text}</h1>
          <form onSubmit={getKatilai}>
            {error ? <div className='loginError'><img src='../assets/circle-exclamation-solid.svg' alt='!'></img> {translation[141].text}</div> : null}
            <input
                className='inputTextLogin'
                type="text" 
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={translation[142].text}
              />
            
            <input
                className='inputTextPass'
                type="password" 
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                placeholder={translation[143].text}
              />
            <input className='prisijungtiButton' type="submit" value={translation[144].text}/>
          </form>
          <hr></hr>
          <div className='orderNumberEmail' id='contacts'>
            <div className='phoneNumber'>+370 683 33388</div>
            <div className='email'>info@akatilai.lt</div>
          </div>
        </div>
     </>;
}

export default SheetsMain;