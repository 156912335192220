export class Specialist {
    constructor(initializer) {
        this.id = undefined;
        this.name = '';
        this.contacts = [];
        this.locations = [];
        this.regions = [];

        if(!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.name) this.name = initializer.name;
        if (initializer.contacts) this.contacts = initializer.contacts;
        if (initializer.locations) this.locations = initializer.locations;
        if (initializer.regions) this.regions = initializer.regions;
    }

    isNew(){
        return this.id === undefined;
    }

}