import React from 'react';


function Advert() {

  return <>
    <div className='advert'>
        <h1>INFORMUOJAME</h1>
        <p>Informuojame, kad paskelbta neefektyvių katilų keitimo programa Lietuvos energetikos agentūros svetainėje (LEA, ena.lt). Paraiškų teikimas galimas Vilniaus regione.</p>
        <p>Planuojamas sekantis visos Lietuvos katilų keitimo etapas bus skelbiamas 2025 m. sausio 2 d.
</p>
    </div>
  </>;
}

export default Advert;