import React, {useContext, useState} from 'react'
import texts_lt from './translations/lt.json';
import texts_en from './translations/en_with_missing.json';
import texts_lv from './translations/lv.json';
import texts_ee from './translations/ee.json';
import texts_pl from './translations/pl.json';
import { MOCK_KATILAI } from "./katilai/MockKatilai";
import { MOCK_KATILAI_EN_2 } from './katilai/MockKatilaiEn2';
import { MOCK_KATILAI_LV } from './katilai/MockKatilaiLv';
import { MOCK_KATILAI_EE } from './katilai/MockKatilaiEE';
import { MOCK_KATILAI_PL } from './katilai/MockKatilaiPl';

const LanguageContext = React.createContext()

export function useLanguage(){
    return useContext(LanguageContext);
}

export function LanguageProvider({children}){
    const [languageKatilai, setLanguageKatilai] = useState(MOCK_KATILAI);
    const [languageTexts, setLanguageTexts] = useState(texts_lt.translation);
    const [language, setLanguage] = useState("lt");

    function toggleLanguage(newLanguage){
        switch (newLanguage) {
            case "lt":
                setLanguageKatilai(MOCK_KATILAI);
                setLanguageTexts(texts_lt.translation);
                setLanguage("lt");
                break;
            case "en":
                setLanguageKatilai(MOCK_KATILAI_EN_2);
                setLanguageTexts(texts_en.translation);
                setLanguage("en");
                break;
            case "lv":
                setLanguageKatilai(MOCK_KATILAI_LV);
                setLanguageTexts(texts_lv.translation);
                setLanguage("lv");
                break;
            case "ee":
                setLanguageKatilai(MOCK_KATILAI_EE);
                setLanguageTexts(texts_ee.translation);
                setLanguage("ee");
                break;
            case "pl":
                setLanguageKatilai(MOCK_KATILAI_PL);
                setLanguageTexts(texts_pl.translation);
                setLanguage("pl");
                break;
            default:
                setLanguageKatilai(MOCK_KATILAI);
                setLanguageTexts(texts_lt.translation);
                setLanguage("lt");
                break;
        }
    }

    return(
        <LanguageContext.Provider value={[language, languageTexts, languageKatilai, toggleLanguage]}>

                {children}

        </LanguageContext.Provider>
    )
}