import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../header.css';
import { useLanguage } from '../LanguageContext';

function HeaderBigBlackBeKatiluRusiu(props) {
  const {activeTab = ""} = props
  let [language, languageTexts, languageKatilai, toggleLanguage]= useLanguage();
  const translation = languageTexts;
  const changeLanguage = (event, changeTo) => {
    event.preventDefault();
    toggleLanguage(changeTo);
    //if (language !== changeTo) toggleLanguage();
  }
  useEffect(() => {
    const htmlElement = document.querySelector("html");
    htmlElement.style.scrollBehavior = 'auto';
    window.scrollTo(0, 0);
    htmlElement.style.scrollBehavior = 'smooth';
  }, [])

  return <>
    <div className='headerRemakeV2'>
      <div className='headerLogoImageLink' id='bigAboutHeader'>
        <Link to='/'><img src={'../assets/logo_black_' + language + '.svg'} alt='logo' className='headerLogoImageSmall'></img></Link> 
      </div>
      
      <div className='black_tree_links_only' id='bigAboutHeader'>
        <div className='goToRight'>
          <Link to='/informacija' className={activeTab === "Informacija" ? "headerActive" : null}>{translation[6].text}</Link>
          <span style={{paddingRight:"30px", paddingLeft:0}}></span>
          <Link to='/apiemus' className={activeTab === "Apie mus" ? "headerActive" : null}>{translation[7].text}</Link>
          <span style={{paddingRight:"30px", paddingLeft:0}}></span>
          <a href='#contacts'>{translation[8].text}</a>
          <span style={{paddingRight:"30px", paddingLeft:0}}></span>
          <Link to='/atstovai'>{translation[72].text}</Link>
          <span style={{paddingRight:"30px", paddingLeft:0}}></span>
          {/* <Link to='/sheets'>{translation[108].text}</Link> */}
          <div className='language_header' >
              <Link to="/" className={language === "lt" ? "active-link" : null} onClick={event => changeLanguage(event, "lt")}>Lt</Link> 
              <Link to="/" className={language === "en" ? "active-link" : null} onClick={event => changeLanguage(event, "en")}>En</Link>
              <Link to="/" className={language === "lv" ? "active-link" : null} onClick={event => changeLanguage(event, "lv")}>Lv</Link> 
              <Link to="/" className={language === "ee" ? "active-link" : null} onClick={event => changeLanguage(event, "ee")}>Ee</Link>
              <Link to="/" className={language === "pl" ? "active-link" : null} onClick={event => changeLanguage(event, "pl")}>Pl</Link>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default HeaderBigBlackBeKatiluRusiu;

        